import styled from 'styled-components';

export const EditedTaxFieldInputContainer = styled.div<any>`
    width: 8rem;
    display: inline-block;
    vertical-align: bottom;

    & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-adornedStart.MuiInputBase-adornedEnd:before {
        content: '';
        display: ${({ $isEdited }) => ($isEdited ? 'block' : 'none')};
        height: 2.65rem;
        width: 0.35rem;
        background: #ebb000;
        position: absolute;
        left: 0.05rem;
    }
`;
