// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// istanbul ignore file
// NOTE: This file has been ignored because it seems impossible to "cover" addRemainingMiddleware and addRemainingReducers in this
//   file - I think this is a bug in how the code coverage works because those functions are covered by configureStoreCommon tests.

// externals
import { Middleware } from 'redux';

// utils
import { configureStore as configureStoreProd } from './configureStore.prod';
import { configureStore as configureStoreDev } from './configureStore.dev';
import { addRemainingMiddleware, addRemainingReducers } from './configureStoreCommon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreloadedState {}

let exportValue: (preloadedState: IPreloadedState, initialMiddleware: Middleware<any, any, any>[], reducers: any) => any;

if (process.env.BUILD_MODE === 'production') {
    exportValue = configureStoreProd;
} else {
    exportValue = configureStoreDev;
}

export { exportValue as configureStore, addRemainingMiddleware, addRemainingReducers };
