import React from 'react';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { formatMileageCharge } from '../../utils/formatUtils';
import { EXCESS_MILEAGE_CHARGE } from '../../constants';
import { TextInput } from '@interstate/components/TextInput';
import { useSelector } from 'react-redux';
import { offerReduxActionCreators, offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { usePaymentCall } from '../../utils/usePaymentCall';

const ExcessMileageChargeField = () => {
    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);
    const { handleBlur, handleChange, value } = usePaymentCall(
        offerSelectors.getMileageChargeOverrideWithFallback,
        offerReduxActionCreators.updateMileageChargeOverride,
        {
            formatValueToString: formatMileageCharge
        }
    );
    return (
        <TextInput
            label={EXCESS_MILEAGE_CHARGE}
            name={kebabCase(EXCESS_MILEAGE_CHARGE)}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!isUserProgramQuotes}
            value={value}
            data-testid={kebabCase(EXCESS_MILEAGE_CHARGE)}
        />
    );
};

export default ExcessMileageChargeField;
