export type ReducerRegistryReducer = any;

export type ReducerRegistryReducers = { [name: string]: ReducerRegistryReducer };
export type ReducerRegistryChangeListener = { (reducers: ReducerRegistryReducers): void };

export class ReducerRegistry {
    private _emitChange: ReducerRegistryChangeListener | null;
    private _reducers: ReducerRegistryReducers;
    constructor() {
        this._emitChange = null;
        this._reducers = {};
    }

    reset() {
        this._reducers = {};
        this._emitChange = null;
    }

    getReducers() {
        return { ...this._reducers };
    }

    private registerWithoutEmit(name: string, reducer: ReducerRegistryReducer) {
        this._reducers = { ...this._reducers, [name]: reducer };
    }

    private notifyListeners() {
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }

    register(name: string, reducer: ReducerRegistryReducer) {
        this.registerWithoutEmit(name, reducer);
        this.notifyListeners();
    }

    registerMultiple(reducers: { [name: string]: ReducerRegistryReducer }) {
        if (reducers) {
            let itemsProcessed = 0;
            Object.keys(reducers).forEach((name: string) => {
                itemsProcessed++;
                this.registerWithoutEmit(name, reducers[name]);
            });
            if (itemsProcessed > 0) {
                this.notifyListeners();
            }
        }
    }

    setChangeListener(listener: ReducerRegistryChangeListener) {
        this._emitChange = listener;
    }
}

export const reducerRegistry = new ReducerRegistry();
