import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import { ModalDialog } from '@interstate/components/ModalDialog';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export type ChangeVehicleConfirmModalProps = {
    show: boolean;
    onCancel: () => void;
    onProceed: () => void;
};

export const ChangeVehicleConfirmModal: React.FC<ChangeVehicleConfirmModalProps> = ({ show, onCancel, onProceed }) => {
    const isLargeScreenSize = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    return (
        <ModalDialog
            size={isLargeScreenSize ? 'large' : 'small'}
            htmlId="change-vehicle-modal"
            data-testid="change-vehicle-modal"
            show={show}
            header={
                <Typography variant="h3" color="base.color.blue.700">
                    Confirmation
                </Typography>
            }
            footer={
                <>
                    <Button
                        buttonStyle="tertiary"
                        htmlId="cancel-btn"
                        data-testid="change-vehicle-cancel-btn"
                        onClick={() => onCancel()}
                    >
                        Cancel
                    </Button>

                    <Button htmlId="change-vehicle-modal-btn" data-testid="change-vehicle-proceed-btn" onClick={() => onProceed()}>
                        Proceed
                    </Button>
                </>
            }
            onHide={() => onCancel()}
        >
            Entered values for finance type, term, down payment, trade-in vehicle, and value will persist. You will need to reselect
            items like vehicle protection products, incentives and accessories.
        </ModalDialog>
    );
};
