import { VEHICLE_CHANGE_SUCCESS } from '@makemydeal/dr-activities-change-vehicle';
import { actions as offerActions } from '@makemydeal/dr-offer-redux';

export enum RATE_REQUEST_STATUSES {
    PENDING = 'Pending',
    FINISHED = 'Finished'
}

// Each retry factor is a multiplier to calculate delay time between retries
// Ex: 1st retry will be 1 * 1000ms, 2nd retry will be 1 * 1000ms,
// 3rd retry will be 2 * 1000ms, 4th retry will be 3 * 1000ms, etc.
export const FETCH_RATES_POLLING_DELAY_FACTORS = [1, 1, 2, 3, 3, 5, 5, 5, 10, 10, 15, 20];

export const MAX_FETCH_RATES_POLLING_COUNT = FETCH_RATES_POLLING_DELAY_FACTORS.length;

export const RERATE_PRODUCTS_ACTION_LIST: ReadonlyArray<string> = [
    offerActions.UPDATED_OFFER_TYPE,
    offerActions.UPDATED_DOWN_PAYMENT_IMMEDIATE,
    offerActions.UPDATED_OFFER_PRICE_IMMEDIATE,
    offerActions.UPDATED_RETAIL_PRICE_OVERRIDE,
    offerActions.UPDATED_SELL_RATE_OVERRIDE,
    offerActions.SELECTED_TERM,
    offerActions.UPDATED_ADJUSTED_RESIDUAL_DOLLAR_OVERRIDE,
    offerActions.UPDATED_ACQFEE_OVERRIDE,
    offerActions.UPDATED_SECURITY_DEPOSIT_OVERRIDE,
    offerActions.UPDATED_ACQFEE_UPFRONT_OVERRIDE,
    offerActions.UPDATED_MANUAL_INCENTIVES,
    offerActions.SELECTED_ANNUAL_MILES,
    VEHICLE_CHANGE_SUCCESS,
    'TEMP/INCENTIVES_PAYMENT_CALL'
];
