import styled from 'styled-components';

export const DefaultMessage = styled.p`
    margin-bottom: 24px;
`;

export const SavingMessage = styled.p`
    margin-bottom: 12px;
`;

export const SavedMessage = styled.p`
    margin-bottom: 68px;
`;

export const TextAreaContainer = styled.div`
    margin-bottom: 16px;
    padding: 32px;

    .form-group {
        margin-bottom: 0;
    }
`;

export const LoadingIndicatorContainer = styled.div`
    padding: 12px 0 32px 0;
`;

export const AlertContainer = styled.div`
    padding: 16px 32px 16px 32px;

    #share-to-showroom-dialog-error {
        margin-bottom: 0;
    }
`;
