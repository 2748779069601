import { PaymentProduct, StateTree } from '@makemydeal/dr-dash-types';
import { AccessoryElement, LEASE } from '@makemydeal/dr-platform-types';
import { calculateAccessoriesTotalAmount } from '../utils/manualAccessoriesUtils';
import { getCurrentOfferType } from './offerRedux';

export const getAccessories = (state: StateTree) => {
    return state.accessories;
};

export const getFormStatus = (state: StateTree) => {
    return getAccessories(state)?.isFormOpen || false;
};

export const getUniqueScrollRequestId = (state: StateTree): string | boolean => {
    return getAccessories(state)?.uniqueScrollRequestId || false;
};

export const getAccessoriesList = (state: StateTree) => {
    return getAccessories(state)?.elements || [];
};

export const hasManualAccessories = (state: StateTree) => {
    const accessories = getAccessoriesList(state);

    return accessories.some((accessory) => accessory.isManualAccessory);
};

export const getAccessoriesListUnfiltered = (state: StateTree) => {
    return getAccessories(state)?.accessoryObjList || [];
};

export const getAccessoriesTotalAmount = (state: StateTree) => {
    const accessories = getAccessoriesList(state);
    return calculateAccessoriesTotalAmount(accessories) || 0;
};

export const getAccessoriesPaymentProducts = (state: StateTree): PaymentProduct[] => {
    const isLeaseDeal = getCurrentOfferType(state) === LEASE;

    return getAccessoriesList(state).map((accessories) => ({
        categoryCode: 'ACC',
        price: accessories.amount ?? 0,
        ...(Number.isFinite(accessories.cost) && { cost: accessories.cost }),
        ...(accessories.profit && { backEndIndicator: accessories.profit === 'backEndProfit' }),
        ...(isLeaseDeal && {
            residualType: 'Dollar',
            residualValue: accessories.residual,
            priceCapitalizedIndicator: !accessories.upFront
        })
    }));
};

export const getCapAccessoriesList = (state: StateTree): AccessoryElement[] => {
    return getAccessoriesList(state).filter((accessory) => !accessory.upFront);
};

export const getCapAccessoriesTotal = (state: StateTree): number => {
    return getCapAccessoriesList(state).reduce((total, accessory) => {
        const amount = accessory.amount ?? 0;

        return total + amount;
    }, 0);
};

export const getHasCatalogAccessories = (state: StateTree): string | boolean => {
    return getAccessories(state)?.hasCatalogAccessories ?? false;
};

export const getUpfrontAccessoriesTotal = (state: StateTree): number => {
    return getUpfrontAccessoriesList(state).reduce((total, accessory) => {
        const amount = accessory.amount ?? 0;

        return total + amount;
    }, 0);
};

export const getUpfrontAccessoriesList = (state: StateTree): AccessoryElement[] => {
    return getAccessoriesList(state).filter((accessory) => accessory.upFront);
};

export const getSelectedCatalogAccessories = (state: StateTree): AccessoryElement[] => {
    return getAccessoriesList(state).filter((accessory) => !accessory.isManualAccessory);
};

export const getSelectedManualAccessories = (state: StateTree): AccessoryElement[] => {
    return getAccessoriesList(state).filter((accessory) => accessory.isManualAccessory);
};

export const getTotalMvAccessoriesSelectionPrice = (state: StateTree): number => {
    const products = getAccessoriesList(state);

    return products.reduce((totalAccessoriesSelectionPrice, product) => {
        totalAccessoriesSelectionPrice += product.amount || 0;

        return totalAccessoriesSelectionPrice;
    }, 0);
};
