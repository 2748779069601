import { Typography } from '@interstate/components/Typography';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { ExpandableArea, ExpandableAreaProvider, ExpandableAreaSection, FlexRow } from '@makemydeal/dr-dash-components';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { MobileView, TabularView } from './RebatesLayoutViews';
import { CustomGrid, StyledHr } from './RebatesView.style';

/**
 * This is intentionally a duplicate/redesign of the original RebatesView in desking. This one
 * consumes Interstate components and includes a collapse/hide w/ different features. Eventually, I could see
 * This being moved to dash components and repurposed for both; but for now, we'll just implement this version here.
 */
export const RebatesView = () => {
    const largerThanSM = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });

    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives);
    const dealerCashTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForDealerCash);
    const currentIncentivesTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);

    if (!incentives?.length) return <span data-testid="manual-rebates-no-data">No rebates added.</span>;
    return (
        <ExpandableAreaProvider>
            <ExpandableArea>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    Incentives
                </Typography>
                {!largerThanSM && <MobileView data={incentives} />}
                {largerThanSM && <TabularView data={incentives} />}
            </ExpandableArea>
            <StyledHr />
            <FlexRow justifyContent="space-between" alignItems="center">
                <ExpandableAreaSection />
                <CustomGrid>
                    <Typography variant="body-md" sx={{ textAlign: 'right' }} data-testid="dealer-cash-total-label">
                        Dealer&nbsp;Cash&nbsp;Total:
                    </Typography>
                    <Typography
                        variant="body-md"
                        sx={{ textAlign: 'right', fontWeight: 600 }}
                        data-testid="dealer-cash-total-value"
                    >
                        {formatDollarsAndCents(dealerCashTotal)}
                    </Typography>
                    <Typography variant="body-md" sx={{ textAlign: 'right' }} data-testid="rebate-total-label">
                        Rebate&nbsp;Total:
                    </Typography>
                    <Typography variant="body-md" sx={{ textAlign: 'right', fontWeight: 600 }} data-testid="rebate-total-value">
                        {formatDollarsAndCents(currentIncentivesTotal)}
                    </Typography>
                </CustomGrid>
            </FlexRow>
        </ExpandableAreaProvider>
    );
};
