//externals
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { DatePicker, DatePickerEventValue } from '@interstate/components/DatePicker';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

// components
import LabelWithToolTipIcon from '../../form/LabelWithToolTipIcon';

// utils
import { getDayDiffIgnoringTime } from '@makemydeal/dr-common-utils';
import { extractDateValueFromDatepickerEvent, normalizeDatePickerValue } from '../../../utils';

// constants
import { INVALID_DATE_ERROR, REQUIRED_ATTRIBUTE_MESSAGE } from '../dealAttributesForm/constants/errorMessages';

// types
import { DealAttributeItemProps, VppDealAttributeDateInput } from '../../../types';

const DealAttributeDatepicker = ({ dealAttributeInput, onChangeValue }: DealAttributeItemProps) => {
    const {
        description,
        name,
        required: isRequired,
        title,
        value,
        minDate,
        maxDate,
        errorMessage
    } = dealAttributeInput as VppDealAttributeDateInput;
    const initialDate: Date | null = dealAttributeInput.value ? new Date(dealAttributeInput.value) : null;
    const hasIsRequiredMessage = isRequired && !value;
    const datePickerContainerRef = useRef<HTMLDivElement>(null);
    const checkIfDateIsOutOfRange = useCallback(
        (date: Date | null) => {
            const isBeforeMinDate = minDate && date ? getDayDiffIgnoringTime(date, minDate) > 0 : false;
            const isAfterMaxDate = maxDate && date ? getDayDiffIgnoringTime(date, maxDate) < 0 : false;

            return {
                isBeforeMinDate,
                isAfterMaxDate
            };
        },
        [minDate, maxDate]
    );
    const { isBeforeMinDate, isAfterMaxDate } = checkIfDateIsOutOfRange(initialDate);
    const [customErrorMessage, setCustomErrorMessage] = useState(
        isBeforeMinDate || isAfterMaxDate ? errorMessage : INVALID_DATE_ERROR
    );

    const getIsInputDirty = useCallback(() => {
        const datePickerContainer = datePickerContainerRef.current as HTMLDivElement;
        const dateInput = datePickerContainer.querySelector('input');
        // istanbul ignore next - to cover the case if interstate implementation stops containing input element.
        const inputValue = dateInput?.value;

        return !!inputValue && /\d/.test(inputValue);
    }, []);

    const onChange: InterstateOnChangeCallback<DatePickerEventValue> = (event: InterstateOnChangeEvent<DatePickerEventValue>) => {
        // Input is cleared asynchronously.
        // This can lead to reading and outdated value if used without setTimeout.
        setTimeout(() => {
            const { stringValue, selectedDate } = extractDateValueFromDatepickerEvent(event);

            const { isBeforeMinDate, isAfterMaxDate } = checkIfDateIsOutOfRange(selectedDate);
            const hasOutOfRangeError = isBeforeMinDate || isAfterMaxDate;

            const isCompleteDateInvalid = !!selectedDate && isNaN(selectedDate.getTime());
            const isInputInvalid = selectedDate ? isCompleteDateInvalid : getIsInputDirty();

            const hasError = hasOutOfRangeError || isInputInvalid;

            setCustomErrorMessage(hasOutOfRangeError ? errorMessage : INVALID_DATE_ERROR);
            onChangeValue(normalizeDatePickerValue(stringValue), hasError);
        }, 0);
    };

    const requiredMessage = hasIsRequiredMessage ? <span className="input-info">{REQUIRED_ATTRIBUTE_MESSAGE}</span> : null;

    return (
        <div data-testid={`${name}-deal-attribute-datepicker-id`}>
            <LabelWithToolTipIcon name={name} title={title} toolTipContent={description} />
            <div ref={datePickerContainerRef}>
                <DatePicker
                    label={title}
                    displayLabel={false}
                    onChange={onChange}
                    value={initialDate as Date} // DatePickerProps expects value to be of Date type, but works with null as well
                    locale="en-US"
                    errorMessage={customErrorMessage}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </div>
            {requiredMessage}
        </div>
    );
};

export default DealAttributeDatepicker;
