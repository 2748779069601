// libraries
import { IDealerFee } from '@makemydeal/dr-platform-shared';
import { ProtectionItem } from '@makemydeal/dr-activities-menu';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import {
    IPencilOfferInputsDealerFee,
    RegionBreakdown,
    TaxLabelMappings,
    LineItemCategory,
    AmountCategory
} from '@makemydeal/dr-platform-types';

// components
import { LineItem } from '../../types/LineItemTypes';

// interfaces/types
import { DraftDealProduct } from '@makemydeal/dr-dash-types';
import { DealerFee } from 'libs/accelerate/dash-store/src/selectors/compositeSelectors';

type CappedDealerFeeBreakdown = {
    cappedFees: IDealerFee[];
    cappedFeeTotal: number;
};

export const convertToDollarCentsLineItem = (label: string, amount?: AmountCategory): LineItemCategory => ({
    label,
    value: formatDollarsAndCents(amount)
});

export const convertDealerFeeToLineItem = ({ dealerFeeName, dealerFeeAmount }: DealerFee): LineItemCategory =>
    convertToDollarCentsLineItem(dealerFeeName, dealerFeeAmount);

// TODO: type the inputs when we show selected menu items
export const convertMenuToLineItem = ({ cardHeading, totalPrice, productName }: ProtectionItem): LineItemCategory => {
    const displayName = cardHeading ?? productName;
    return convertToDollarCentsLineItem(displayName, totalPrice);
};

export const convertVppProductToLineItem = ({
    productName,
    productPrice,
    productCategoryCode
}: DraftDealProduct): LineItemCategory => {
    const label = productName || productCategoryCode || 'Product';
    const amount = productPrice;

    return convertToDollarCentsLineItem(label, amount);
};

export const convertAccessoryToLineItem = ({ name, amount }: any): LineItemCategory => convertToDollarCentsLineItem(name, amount);

export const convertGovernmentFeeToLineItem = ({ name, amount }: any): LineItemCategory =>
    convertToDollarCentsLineItem(name, amount);

/**
 * Converts objects with string-amount mappings to LineItem[] for use in the deal summary
 * 0 value mappings are not included in the resulting array
 */
export const taxElementsToLineItems = (taxElements?: RegionBreakdown): LineItem[] => {
    if (!taxElements) {
        return [];
    }
    const lineItems: LineItem[] = [];
    Object.keys(taxElements).forEach((key: string) => {
        if (taxElements[key]) {
            lineItems.push(convertToDollarCentsLineItem(TaxLabelMappings[key as keyof typeof TaxLabelMappings], taxElements[key]));
        }
    });
    return lineItems;
};

const initialBreakdown = () => ({ cappedFees: [], cappedFeeTotal: 0 });

export const getCappedFeeBreakdown = (dealerFees: IDealerFee[]): CappedDealerFeeBreakdown =>
    dealerFees.reduce((acc: CappedDealerFeeBreakdown, dealerFee: IDealerFee): CappedDealerFeeBreakdown => {
        if (dealerFee.dealerFeeCapped && dealerFee.dealerFeeAmount > 0) {
            acc.cappedFees.push(dealerFee);
            acc.cappedFeeTotal += dealerFee.dealerFeeAmount;
        }
        return acc;
    }, initialBreakdown());
