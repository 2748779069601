import { Alert } from '@interstate-104/components/Alert';
import { AuxButton } from '@interstate-104/components/Button';
import { deskingSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';

export const DeskingAlerts = () => {
    const dispatch = useDispatch();
    const alerts = useSelector(deskingSelectors.getDeskingAlerts);
    if (alerts.length === 0) return null;

    return (
        <>
            {alerts.map((alert, alertIndex) => (
                <Alert
                    type={alert.type}
                    key={`alert-${alertIndex}`}
                    role=""
                    data-testid={`desking-alert-${alertIndex}`}
                    title={alert.title}
                    // temporary fix for warning alert
                    sx={alert.type === 'caution' ? { '&.MuiAlert-outlinedWarning': { borderColor: '#ff9800' } } : undefined}
                >
                    {alert.message}
                    {alert.ctaAction != null && (
                        <AuxButton
                            data-testid="cta-button"
                            onClick={() => {
                                dispatch(alert.ctaAction);
                            }}
                        >
                            {alert.cta}
                        </AuxButton>
                    )}
                </Alert>
            ))}
        </>
    );
};
