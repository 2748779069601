import {
    dealXgDetailsSelectors,
    docUploadSelectors,
    offerActionCreators,
    offerInfoSelectors,
    shopperNotificationsSelectors
} from '@makemydeal/dr-dash-store';
import { SendDialogTypes, StateTree } from '@makemydeal/dr-dash-types';
import { ACTIVITY_NAME_DOC_UPLOAD } from '@makemydeal/dr-dpm-client';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerUI } from '../../customerUI/useCustomerUI';
import SendToCustomerDialogInterstate from '../../sendToCustomerDialog/SendToCustomerDialog.interstate';
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'docUpload';

export const DocUploadCard = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: StateTree) => state);
    const isDocUploadCompleted = useSelector(docUploadSelectors.isDocUploadCompleted);
    const cardValue = useSelector(docUploadSelectors.getDashboardStatusText);
    const notifications = shopperNotificationsSelectors.getShopperNotificationsByActivity(state, 'docUpload');
    const docUploadUrl = useSelector(offerInfoSelectors.getDocUploadURL);
    const [showDocUploadModal, setShowDocUploadModal] = useState(false);
    const docUploadDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_DOC_UPLOAD);
    const crmIntegrationToggle = useSelector(dealerSelectors.getCrmIntegrationToggle);
    const { showCustomerCard } = useCustomerUI();

    // istanbul ignore next # this is invoked WAY down the pipeline and out of scope here. We just want to delay getting hte URL until the url loads asynchronously
    const urlSelector = (state: any) => {
        const url = offerInfoSelectors.getDocUploadURL(state);
        return `\n${url}`;
    };

    const handleShowDocUploadModal = () => {
        dispatch(offerActionCreators.saveOffer());
        if (crmIntegrationToggle) {
            showCustomerCard(true, { tab: 'inbox', messageContentSelector: urlSelector, waitForOfferSave: true });
        } else {
            setShowDocUploadModal(true);
        }
    };

    const handleSendToCustomerHide = () => {
        setShowDocUploadModal(false);
    };

    const linkText = notifications.length ? 'Resend Link' : 'Send Link';

    return (
        <div>
            <DealActivityCard
                dpmState={docUploadDpmState}
                name={name}
                cardValue={cardValue}
                completed={isDocUploadCompleted}
                action={handleShowDocUploadModal}
                linkText={linkText}
                data-testid="docUpload-link"
            />

            <SendToCustomerDialogInterstate
                shareUrl={docUploadUrl}
                dialogHeader="Send Document Upload Request to Customer"
                dialogPrompt="Send the customer a direct link to upload documents"
                data-testid="send-to-customer-dialog"
                show={showDocUploadModal}
                onHide={handleSendToCustomerHide}
                dialogType={SendDialogTypes.DocUpload}
            />
        </div>
    );
};
