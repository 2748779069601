import styled from 'styled-components';

export const FooterContainer = styled.div`
    padding: 1rem !important; // lame, but we have to; conflicts with Mui overrides
`;

export const SpanGray = styled.div`
    color: gray;
`;

export const GridAutoCol = styled.span`
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: space-between;
    grid-auto-columns: min-content;
`;

export const FooterRightSpan = styled.span`
    align-content: end;
    display: flex;
    justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
    // copying style from MUI Card header
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
`;

export const FlexLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    input {
        padding: 0; // MUI sucks
        margin: 0;
    }
`;
