// There's a circular ref involving a test file that needs to be resolved before this rule can be enabled:
// See https://ghe.coxautoinc.com/DigitalRetailing/dr-dash/issues/2615 for description of this issue.
/* eslint-disable @nx/enforce-module-boundaries */

// externals
import { Reducer } from 'redux';

// libraries
import { Actions } from '@makemydeal/dr-activities-menu';
import type { AccessoriesState, ProtectionListSuccessPayload } from '@makemydeal/dr-dash-types';
import { buildUniqueScrollRequestId } from '@makemydeal/dr-dash-ui-utils';

// consts/enums
import { accessoriesActionTypes } from '../actionTypes';
import { INIT_ACCESSORIES, INIT_MENU_ACCESSORIES, UPDATED_OFFER_MENU_PRODUCTS } from '../actionTypes/accessoriesActionTypes';
import { PAYMENT_FAILURE } from '../actionTypes/deskingActionTypes';

// interfaces/types
import type { AccessoriesOpenFormAction } from '../actions/accessoriesActionCreators';

// utils
import { AccessoriesAdapter } from '../utils/manualAccessoriesAdapter';

export type AccessoriesReducer = Reducer<AccessoriesState>;

export const initialState: AccessoriesState = {
    accessoryObjList: [],
    elements: [],
    isFormOpen: false,
    uniqueScrollRequestId: false,
    lastValidElements: []
};

export const getAccessoriesFromMenuInit = (products: ProtectionListSuccessPayload) => {
    const accessoryPrefix = 'ACCX';

    const accessories = products?.protectionObjSentList?.filter((item) => item.menuItemId?.startsWith(accessoryPrefix));
    const accessoryFormatted = AccessoriesAdapter.toSourceFormat(accessories);

    return accessoryFormatted;
};

export const reducer: AccessoriesReducer = (state: AccessoriesState = initialState, action: any): AccessoriesState => {
    switch (action.type) {
        case INIT_ACCESSORIES: {
            const accessories = action.payload.accessories;
            if (accessories) {
                const mappedformattedAccessories = AccessoriesAdapter.accessoriesToSourceFormat(action.payload.accessories);
                return {
                    ...state,
                    ...mappedformattedAccessories
                } as AccessoriesState;
            } else {
                return state;
            }
        }
        case INIT_MENU_ACCESSORIES: {
            const accessories = getAccessoriesFromMenuInit(action.payload);
            return {
                ...state,
                ...accessories
            } as AccessoriesState;
        }
        case accessoriesActionTypes.ACCESSORIES_OPEN_FORM: {
            const actionTyped = action as AccessoriesOpenFormAction;
            return {
                ...state,
                isFormOpen: true,
                uniqueScrollRequestId: actionTyped.payload?.scrollIntoView ? buildUniqueScrollRequestId() : false
            };
        }
        case accessoriesActionTypes.FOCUS_ACCESSORIES_TABLE: {
            return {
                ...state,
                isFormOpen: false,
                uniqueScrollRequestId: buildUniqueScrollRequestId()
            };
        }
        case Actions.PROTECTION_LIST_CLOSE_MODAL:
        case accessoriesActionTypes.ACCESSORIES_CLOSE_FORM: {
            return {
                ...state,
                isFormOpen: false
            };
        }
        case accessoriesActionTypes.ADD_ACCESSORIES_SELECTIONS: {
            return {
                ...state,
                accessoryObjList: action.payload.accessories
            };
        }
        case accessoriesActionTypes.ADDED_MANUAL_ACCESSORIES: {
            return {
                ...state,
                elements: action.payload.manualAccessories.accessories,
                lastValidElements: state.elements || []
            };
        }
        case PAYMENT_FAILURE: {
            if (action.meta?.actionForPayment === UPDATED_OFFER_MENU_PRODUCTS) {
                return {
                    ...state,
                    elements: state.lastValidElements || []
                };
            } else {
                return state;
            }
        }
        case accessoriesActionTypes.CHECK_ACCESSORIES_CATALOG_EXISTS_SUCCESS: {
            return {
                ...state,
                hasCatalogAccessories: action.payload.data?.exists
            };
        }

        default: {
            return state;
        }
    }
};
