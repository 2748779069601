import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { OfferType } from '@makemydeal/dr-platform-types';
import { Typography } from '@interstate/components/Typography';
import { rem } from 'polished';

export interface IOfferCardDetailsProps {
    offerType: OfferType;
    monthlyPayment?: number;
    netCashDown?: number;
    tradeAllowance?: number;
    offerPrice?: number;
    months?: number;
    apr?: number;
    sellRate?: number;
}

function renderPayment(offerType?: OfferType, monthlyPayment?: number, offerPrice?: number) {
    if (offerType === 'finance' || offerType === 'lease') {
        return (
            <Typography variant={'body-md'}>
                {`${formatDollarsAndCents(monthlyPayment)}`}
                <span>/mo</span>
            </Typography>
        );
    }
    if (offerType === 'cash') {
        return <Typography variant={'body-md'}>{`${formatDollarsAndCents(offerPrice)}`}</Typography>;
    }

    return null;
}

function renderTerms(offerType?: OfferType, months?: number, apr?: number, sellRate?: number) {
    if (offerType === 'finance') {
        return <Typography variant={'body-md'}>{`${months} mos at ${apr}% APR`}</Typography>;
    }
    if (offerType === 'lease') {
        return <Typography variant={'body-md'}>{`${months} mos at ${sellRate}% APR`}</Typography>;
    }

    return null;
}

export const OfferCardDetailsInterstate: React.FC<IOfferCardDetailsProps> = ({
    offerType,
    monthlyPayment,
    netCashDown,
    tradeAllowance,
    offerPrice,
    months,
    apr,
    sellRate
}: IOfferCardDetailsProps) => {
    const formattedOfferType = offerType.charAt(0).toUpperCase() + offerType.slice(1);

    return (
        <div>
            {formattedOfferType ? (
                <Typography variant={'h5'} sx={{ padding: `${rem(8)} 0` }}>{`${formattedOfferType}`}</Typography>
            ) : (
                <div />
            )}
            {renderPayment(offerType, monthlyPayment, offerPrice)}
            {renderTerms(offerType, months, apr, sellRate)}
            <div>
                {offerType !== 'cash' && (
                    <Typography variant={'body-md'}>{`${formatDollarsAndCents(netCashDown ?? 0)} Cash Down`}</Typography>
                )}
                {tradeAllowance && tradeAllowance > 0 ? (
                    <Typography variant={'body-md'}>{`${formatDollarsAndCents(tradeAllowance)} Trade Allowance`}</Typography>
                ) : null}
            </div>
        </div>
    );
};

export default OfferCardDetailsInterstate;
