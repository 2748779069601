// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Alert } from '@interstate-104/components/Alert';
import { Action } from '@interstate-104/components/Action';
import { FINANCE } from '@makemydeal/dr-platform-types';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { creditDecisionSelectors, navigationActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import CashDownField from './common/CashDownField.interstate';
import FinanceTermField from './common/FinanceTermField.interstate';
import RetailPriceField from './common/RetailPriceField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';
import SellRateField from './common/SellRateField.interstate';
import BuyRateField from './common/BuyRateField.interstate';
import PaymentFrequencyField from './common/PaymentFrequencyField.interstate';
import CreditDecisionStatusField from './common/CreditDecisionStatusField.interstate';
import CreditDecisionEnabled from './common/CreditDecisionEnabled.interstate';
import CreditDecisionLenderField from './common/CreditDecisionLenderNameField.interstate';
import DaysToFirstPaymentField from './common/DaysToFirstPaymentField';
import FirstPaymentDayField from './common/FirstPaymentDayField';

// styles
import { TermsTab } from './common/Terms.style';
import { FinanceHeader } from './Finance.styles';

export type FinanceProps = {
    enableCreditDecisionAlert?: boolean;
};

const Finance = ({ enableCreditDecisionAlert = false }: FinanceProps) => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderDecisions = useSelector((state: StateTree) => {
        return creditDecisionSelectors.getLenderCreditDecisionsByOfferType(state, currentOfferType);
    });
    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const dispatch = useDispatch();
    const creditDecisionAvailable = lenderDecisions.length > 0;
    const navigateToCreditBureau = () => dispatch(navigationActionCreators.navigateToCreditBureau());
    const navigateToCreditDecision = () => dispatch(navigationActionCreators.navigateToCreditDecision());

    if (currentOfferType !== FINANCE) return null;

    return (
        <TermsTab data-testid="finance-terms">
            <FinanceHeader>
                <Typography data-testid="finance-title" variant="h5">
                    Payment And Lender Information
                </Typography>
                <Action
                    data-testid="finance-terms-edit-link"
                    sx={{
                        fontSize: '14px'
                    }}
                    onClick={enableCreditBureauAndDecisionPBCs ? navigateToCreditDecision : navigateToCreditBureau}
                >
                    View/Edit
                </Action>
            </FinanceHeader>
            <Grid xs={12}>
                {enableCreditDecisionAlert && creditDecisionAvailable && (
                    <Alert id="infoAlert" role="infoAlert" type="info" data-testid="terms-lender-decision-alert">
                        Lender decisions are ready for this offer.
                    </Alert>
                )}
            </Grid>
            <Grid xs={12}>
                <CreditDecisionEnabled />
            </Grid>
            <Grid spacing={2} container xs rowGap={2} data-testid="main-finance-content" flexWrap="wrap">
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <RetailPriceField />
                </Grid>
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <SellingPriceField />
                </Grid>
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <CashDownField />
                </Grid>
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <CreditDecisionLenderField />
                </Grid>

                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <CreditDecisionStatusField />
                </Grid>
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <FinanceTermField />
                </Grid>
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <PaymentFrequencyField />
                </Grid>
                <Grid xs={6} sm={6} lg={3} alignSelf="flex-end">
                    <SellRateField />
                </Grid>

                <Grid xs={6} sm={6} lg={3}>
                    <BuyRateField />
                </Grid>
                <Grid xs={6} sm={6} lg={3}>
                    <DaysToFirstPaymentField />
                </Grid>
                <Grid xs={6} sm={6} lg={3}>
                    <FirstPaymentDayField />
                </Grid>
            </Grid>
        </TermsTab>
    );
};

export default Finance;
