// externals
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { ACTIVITY_NAME_VEHICLE_PROTECTION } from '@makemydeal/dr-dpm-client';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { StateTree } from '@makemydeal/dr-dash-types';
import {
    dealXgDetailsSelectors,
    vehicleProtectionSelectors,
    vehicleProtectionActionCreators,
    calculationDetailsSelectors
} from '@makemydeal/dr-dash-store';
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'vppActivityVehicleProtection';

const VppActivityVehicleProtectionCard = () => {
    const dispatch = useDispatch();

    const selectedProducts = useSelector(vehicleProtectionSelectors.getVppSelectedProducts);
    const { totalProductPrice } = useSelector(vehicleProtectionSelectors.getVppSummary);
    const isFieEngine = useSelector(calculationDetailsSelectors.isFieCalculationEngineService);

    const completed = selectedProducts.length > 0;
    const cardValue = formatDollarsAndCents(totalProductPrice);
    const vehicleProtectionDpmState = useSelector((state: StateTree) =>
        dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_VEHICLE_PROTECTION)
    );

    if (!isFieEngine) {
        return null;
    }

    const redirectToFullVppPage = () => {
        dispatch(vehicleProtectionActionCreators.redirectToVehicleProtectionPage());
    };

    return (
        <DealActivityCard
            dpmState={vehicleProtectionDpmState}
            name={name}
            cardValue={cardValue}
            completed={completed}
            showCardAnchor={completed}
            action={redirectToFullVppPage}
        />
    );
};

export default VppActivityVehicleProtectionCard;
