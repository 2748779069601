// externals
import React from 'react';

// libraries
import { Activity } from '@makemydeal/dr-activities-menu';

// interfaces/types
import type { ConfigMenu } from '@makemydeal/dr-activities-menu';

export interface IActivityStateProps {
    isVppEnabledInPrimaryFlowActivated: boolean;
    config: ConfigMenu;
}

export class MenuProductsStandAloneUI extends React.Component<IActivityStateProps> {
    render() {
        if (this.props.isVppEnabledInPrimaryFlowActivated) {
            return <Activity isStandAlone={true} config={this.props.config} />;
        }
        return null;
    }
}
