import { FC } from 'react';
import { Route } from 'react-router-dom';

import { getAllRoutes } from '../utils/routes';

import { ProgramsAndIncentives } from './ProgramsAndIncentives';

import { StyledProgramActivity } from './ProgramActivity.style';

export type ProgramsActivityProps = {
    baseRoute: string;
};

export const Root: FC<ProgramsActivityProps> = ({ baseRoute }) => {
    const { root } = getAllRoutes(baseRoute);

    return (
        <StyledProgramActivity data-testid="activities-programs-container" className="activities-programs container">
            <Route path={root} component={ProgramsAndIncentives} />
        </StyledProgramActivity>
    );
};

export default Root;
