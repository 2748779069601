// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts
import { ADJUSTED_CAP_COST } from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const AdjCapCost = ({ deal }: DealState) => {
    const state = useDealRootState(deal);
    const adjustedCapCost = offerReduxSelectors.getAdjustedCapCost(state);
    const formatted = formatDollarsAndCents(adjustedCapCost);

    return <DealSummaryItem label={ADJUSTED_CAP_COST} value={formatted} />;
};

export default AdjCapCost;
