// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { IConfigObj, buildFromConfig } from '@makemydeal/dr-common-utils';
import { SurchargeOverrides } from '@makemydeal/dr-shared-types';
import { DraftDealProduct, StateTree } from '@makemydeal/dr-dash-types';
import { FSA, IPaymentMiddlewareMeta, FSAWithMeta } from '@makemydeal/dr-platform-shared';
import { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import { AccelerateDispatch, dealerSelectors } from '@makemydeal/dr-shared-store';

// consts/enums
import {
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_REQUESTED,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_RATES_FAILURE,
    FETCH_RATES_REQUESTED,
    FETCH_RATES_SUCCESS,
    PRODUCTS_REQUEST_OVERRIDDEN,
    PRODUCT_SELECTED,
    PRODUCT_UNSELECTED,
    PRODUCT_UPDATED,
    PRODUCT_CHANGES_PAYMENT_CALL,
    ALL_PRODUCTS_SELECTED,
    ALL_PRODUCTS_UNSELECTED,
    PRODUCT_MONTHLY_PAYMENT_UPDATED,
    ALL_PRODUCTS_MONTHLY_PAYMENT_RESET,
    FETCH_RATES_RETRY,
    FETCH_PRODUCTS_RETRY,
    VEHICLE_PROTECTION_COMPLETED,
    VEHICLE_PROTECTION_IN_PROGRESS
} from '../actionTypes/vehicleProtectionActionTypes';
import { FETCH_RATES_POLLING_DELAY_FACTORS } from '../types/vehicleProtectionTypes';
import { configSelectors, connectionSelectors, vehicleProtectionSelectors } from '../selectors';
import { DASH_START_VEHICLE_PROTECTION } from '../actionTypes/navigationActionTypes';

type PaymentSkipMeta = { skipPayment?: boolean };
export type VppProductSelectAction = FSAWithMeta<string, { productCode: string }, PaymentSkipMeta>;
export type VppProductUnselectedAction = FSAWithMeta<string, { productCode: string }, PaymentSkipMeta>;

export type VppProductUpdatedAction = FSAWithMeta<string, DraftDealProduct, PaymentSkipMeta>;
export type VppProductRequestOverridenAction = FSA<string, SurchargeOverrides>;
export type PaymentUpdateAction = FSAWithMeta<typeof PRODUCT_CHANGES_PAYMENT_CALL, any, IPaymentMiddlewareMeta>;

export const completeVehicleProtection = () => {
    return {
        type: VEHICLE_PROTECTION_COMPLETED
    };
};

export const startVehicleProtection = () => {
    return {
        type: VEHICLE_PROTECTION_IN_PROGRESS
    };
};
export const selectVppProduct = (productCode: string, meta?: PaymentSkipMeta): VppProductSelectAction => {
    return {
        type: PRODUCT_SELECTED,
        payload: {
            productCode
        },
        meta
    };
};

export const redirectToVehicleProtectionPage = () => {
    return { type: DASH_START_VEHICLE_PROTECTION };
};

export const unselectVppProduct = (productCode: string, meta?: PaymentSkipMeta): VppProductUnselectedAction => {
    return {
        type: PRODUCT_UNSELECTED,
        payload: {
            productCode
        },
        meta
    };
};

export const selectAllVppProducts = (): VppProductSelectAction => {
    return { type: ALL_PRODUCTS_SELECTED };
};

export const unselectAllVppProducts = (meta?: PaymentSkipMeta): VppProductUnselectedAction => {
    return { type: ALL_PRODUCTS_UNSELECTED, meta };
};

export const updateVppProduct = (product: DraftDealProduct, meta?: PaymentSkipMeta): VppProductUpdatedAction => {
    return {
        type: PRODUCT_UPDATED,
        payload: product,
        meta
    };
};

export const updateVppSurchargesAttributes = (surcharges: SurchargeOverrides): VppProductRequestOverridenAction => {
    return {
        type: PRODUCTS_REQUEST_OVERRIDDEN,
        payload: surcharges
    };
};

let fetchProductsAbortController: AbortController;
export const fetchVehicleProtectionProducts = (state: StateTree): RSAACallAction => {
    const bffEndpointConfig = configSelectors.getServicesBff(state);
    const connectionId = connectionSelectors.getConnectionId(state);
    const dealerId = dealerSelectors.getDealerId(state);
    const surchargeOverrides = vehicleProtectionSelectors.getVppSurchargesOverrides(state);

    const payload = {
        dealerId,
        connectionId,
        surchargeOverrides
    };

    if (fetchProductsAbortController) {
        fetchProductsAbortController.abort();
    }
    fetchProductsAbortController = new AbortController();

    const vppBffUrl = buildFromConfig(bffEndpointConfig);
    const endpoint = `${vppBffUrl}/fetchVppProducts`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        options: {
            signal: fetchProductsAbortController.signal
        },
        types: [FETCH_PRODUCTS_REQUESTED, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

let fetchRatesAbortController: AbortController;
export const fetchVehicleProtectionRates = (rateToken: string, bffEndpointConfig: IConfigObj, meta?: any): RSAACallAction => {
    const vppBffUrl = buildFromConfig(bffEndpointConfig);
    let endpoint = `${vppBffUrl}/fetchVppRates/${rateToken}`;

    if (meta?.isPlanSelectionForVppEnabled) {
        endpoint += '?limitToOnePlan=false';
    }

    if (fetchRatesAbortController) {
        fetchRatesAbortController.abort();
    }
    fetchRatesAbortController = new AbortController();

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        options: {
            signal: fetchRatesAbortController.signal
        },
        types: [FETCH_RATES_REQUESTED, { type: FETCH_RATES_SUCCESS, meta: { rateToken, ...meta } }, FETCH_RATES_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const repeatFetchVehicleProtectionRates = (action: any, bffEndpointConfig: IConfigObj) => {
    const { rateToken, retryNumber = 1, showRecommendedOnVppRates, isPlanSelectionForVppEnabled } = action.meta;
    const delay = FETCH_RATES_POLLING_DELAY_FACTORS[retryNumber - 1] * 1000;

    return (dispatch: AccelerateDispatch<any, any>) => {
        setTimeout(() => {
            dispatch(
                fetchVehicleProtectionRates(rateToken, bffEndpointConfig, {
                    showRecommendedOnVppRates,
                    isPlanSelectionForVppEnabled,
                    retryNumber: retryNumber + 1
                })
            );
        }, delay);
    };
};

export const retryFetchVehicleProtectionRates = () => (dispatch: AccelerateDispatch<any, any>, getState: any) => {
    const state = getState();
    const rateToken = vehicleProtectionSelectors.getVppRateToken(state);
    const isRatingRetried = vehicleProtectionSelectors.isVppRatingRetried(state);

    const action = {
        type: isRatingRetried ? FETCH_PRODUCTS_RETRY : FETCH_RATES_RETRY,
        ...(!isRatingRetried && { payload: { rateToken } })
    };

    return dispatch(action);
};

export const updatePayment = (includeTaxes?: boolean, productCodes?: string[]): PaymentUpdateAction => {
    return {
        type: PRODUCT_CHANGES_PAYMENT_CALL,
        payload: {
            includeTaxes: includeTaxes === undefined ? true : includeTaxes
        },
        meta: {
            middleware: {
                payment: true,
                productCodes
            }
        }
    };
};

export const updateVppProductMonthlyPayment = (productCode: string, productMonthlyPayment: number) => {
    return {
        type: PRODUCT_MONTHLY_PAYMENT_UPDATED,
        payload: {
            productCode,
            productMonthlyPayment
        }
    };
};

export const resetVppProductsMonthlyPayment = () => {
    return {
        type: ALL_PRODUCTS_MONTHLY_PAYMENT_RESET
    };
};
