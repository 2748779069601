// externals
import { useSelector } from 'react-redux';

// libraries
import * as dashStore from '@makemydeal/dr-dash-store';
import * as sharedStore from '@makemydeal/dr-shared-store';

// components
import CreditBureauWithScore from './CreditBureauWithScore';

const CreditBureauInterstate = () => {
    const dealRefId: string = useSelector(dashStore.offerReduxSelectors.getDealRefId);
    const dealRefIdFS: string = useSelector(dashStore.offerReduxSelectors.getDealRefIdFS);
    const fsDealRefId: string = useSelector(dashStore.offerReduxSelectors.getFsDealRefId);
    const dealIdExists = fsDealRefId.length > 0 || dealRefIdFS.length > 0 || dealRefId.length > 0;

    const fsDealRefIdEnabled: boolean = useSelector(sharedStore.featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const creditBureauEnabled: boolean = useSelector(sharedStore.featureToggleSelectors.isCreditBureauEnabled);

    const showCreditBureau = dealIdExists && fsDealRefIdEnabled && creditBureauEnabled;

    return showCreditBureau ? <CreditBureauWithScore /> : null;
};

export default CreditBureauInterstate;
