// externals
import { useSelector } from 'react-redux';

// components
import { OfferHistoryCard as OfferHistoryCardInterstate } from '../offerHistoryCard/OfferHistoryCard.interstate';
import OfferCardInterstate from '../offerCard/OfferCard.interstate';
import SliderCarousel from '../common/SliderCarousel/SliderCarousel';

// libraries
import { Grid } from '@interstate/components/Grid';
import { LoadingIndicator } from '@interstate/components/LoadingIndicator';

// styles
import { InterstateOfferCardsContainer, LoadingIndicatorContainer } from './DealComparison.style';

// selectors
import { dealHistorySelectors } from '@makemydeal/dr-dash-store';

export const DealComparisonInterstate = () => {
    const isLoading = useSelector(dealHistorySelectors.getDealHistoryIsLoading);
    const dealerOffer = useSelector(dealHistorySelectors.getLatestDealerOffer);
    const shopperOffer = useSelector(dealHistorySelectors.getLatestShopperOffer);

    const showOfferHistory = () => {
        return (
            <Grid
                container
                data-testid="deal-comparison-container"
                alignItems="stretch"
                flexWrap="wrap"
                margin={0}
                width="100%"
                rowGap={2}
            >
                <InterstateOfferCardsContainer container columnGap={2} alignItems="flex-start" flexWrap="nowrap">
                    <SliderCarousel id="deal-comparison-offer-cards-carousel">
                        <OfferCardInterstate deal={dealerOffer} isMostRecentDealerOffer={true} />
                        <OfferCardInterstate deal={shopperOffer} isMostRecentShopperOffer={true} />
                    </SliderCarousel>
                </InterstateOfferCardsContainer>
                <OfferHistoryCardInterstate />
            </Grid>
        );
    };

    if (!isLoading && !dealerOffer && !shopperOffer) {
        return null;
    }
    const content = isLoading ? (
        <LoadingIndicatorContainer data-testid="dr-dash-deal-comparison-loading-indicator">
            <LoadingIndicator data-testid="offer-history-loading-interstate" size="default" />
        </LoadingIndicatorContainer>
    ) : (
        <>{showOfferHistory()}</>
    );
    return (
        <Grid container flexDirection="column" className="dr-dash-deal-comparison">
            {content}
        </Grid>
    );
};
