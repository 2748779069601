// externals
import { connect } from 'react-redux';
import React from 'react';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { compositeSelectors, widgetSettingsSelectors } from '@makemydeal/dr-dash-store';

// components
import { IActivityStateProps, MenuProductsStandAloneUI } from './MenuStandAloneUI';

// config
import { getConfigMenuFromState } from '../config';

export const mapStateToProps = (state: StateTree): IActivityStateProps => {
    return {
        config: {
            ...getConfigMenuFromState(state),
            userContext: {
                isDealer: widgetSettingsSelectors.isDealerUser(state),
                isDesking: compositeSelectors.isDeskingUser(state)
            }
        },
        isVppEnabledInPrimaryFlowActivated: true
    };
};

export const MenuProductsStandAlone = connect(mapStateToProps, {})(MenuProductsStandAloneUI);

export function renderActivityAsStandAlone() {
    return <MenuProductsStandAlone />;
}
