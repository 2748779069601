// consts/enums
import { NAME } from '../common/taxesAndFeesConsts';
import { SimpleTableHeadColumn } from './TableHead';
import { FINANCE_MIN_WIDTH, FINANCE_NAME_MIN_WIDTH, LEASE_MIN_WIDTH, LEASE_NAME_MIN_WIDTH } from './FeesCard.style';

export const AMOUNT = 'Amount';
export const CODE = 'Code';
export const UPFRONT = 'Upfront';

export const FINANCE_FEETABLECOLUMNS: SimpleTableHeadColumn[] = [
    { heading: CODE, options: { minWidth: FINANCE_MIN_WIDTH } },
    { heading: NAME, options: { minWidth: FINANCE_NAME_MIN_WIDTH, textAlign: 'center', justifyContent: 'center' } },
    { heading: AMOUNT, options: { minWidth: FINANCE_MIN_WIDTH, textAlign: 'right', justifyContent: 'flex-end' } }
];
export const LEASE_FEETABLECOLUMNS: SimpleTableHeadColumn[] = [
    { heading: CODE, options: { minWidth: LEASE_MIN_WIDTH } },
    { heading: NAME, options: { minWidth: LEASE_NAME_MIN_WIDTH, textAlign: 'center', justifyContent: 'center' } },
    { heading: UPFRONT, options: { minWidth: LEASE_MIN_WIDTH, textAlign: 'center', justifyContent: 'center' } },
    { heading: AMOUNT, options: { minWidth: LEASE_MIN_WIDTH, textAlign: 'right', justifyContent: 'flex-end' } }
];

export const ELIMINATED_FEE_AMOUNT = 0;
export const DELETED_BY_ACTION_FEE_AMOUNT = '0';

export const CAP_INDICATOR = 'capIndicator';

export const GOV_DEALER_TITLE = 'Government and Dealer Fees';
export const LENDER_DEALER_GOV_TITLE = 'Lender, Government and Dealer Fees';
