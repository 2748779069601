// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import type { StateTree } from '@makemydeal/dr-dash-types';

// consts/enums
import {
    CONSUMER_INSIGHTS_FETCH_REQUEST,
    CONSUMER_INSIGHTS_FETCH_SUCCESS,
    CONSUMER_INSIGHTS_FETCH_FAILURE
} from '../actionTypes/consumerInsightsActionTypes';

// utils
import { apiConfigHelper, BffApiUrlResult, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { getGlobalCustomerId } from '../selectors/dealXgDetailsSelectors';

export const fetchConsumerInsights = (state: StateTree): RSAACallAction => {
    const locHref = locHrefUtil.getLocHref();
    const globalCustomerId = getGlobalCustomerId(state);
    const bffApiUrlResult: BffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '');
    const endpoint = `${bffApiUrlResult.url}/consumerData/${globalCustomerId}/insights`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [CONSUMER_INSIGHTS_FETCH_REQUEST, CONSUMER_INSIGHTS_FETCH_SUCCESS, CONSUMER_INSIGHTS_FETCH_FAILURE]
    };
    return {
        [RSAA]: request
    };
};
