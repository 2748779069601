import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { dealXgDetailsSelectors, DMS_PLUS, offerInfoSelectors, pushToDmsSelectors } from '@makemydeal/dr-dash-store';
import { formatAsMMDDYYYY, formatAsTime } from '@makemydeal/dr-common-utils';
import {
    CONFIRM_BUTTON_TEXT,
    CONTINUE_BUTTON_TEXT,
    PUSH_TO_DMS_BUTTON_TEXT,
    RE_PUSH_TO_DMS_BUTTON_TEXT,
    RESEND_TO_DMS_BUTTON_TEXT
} from './pushToDmsConstants';

export const useLastPushToDmsUpdateText = (hasBeenPushedTime: string | Date) => {
    const isDMSEnabled = useSelector(dealerSelectors.getDMSIntegrationToggle) !== 'OFF';
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const enableEnhancedPushToDmsPlusMV = useSelector(featureToggleSelectors.enableEnhancedPushToDmsPlusMV);
    const offerSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);
    const lastPushToDmsUpdate = useSelector(dealXgDetailsSelectors.getLastPushToDmsUpdate);

    if (!isDMSEnabled) return '';

    if (enableEnhancedPushToDmsPlusMV && dmsIntegrationToggle === DMS_PLUS) {
        if (!lastPushToDmsUpdate) return '';
        return `Deal sent to DMS on ${formatAsMMDDYYYY(lastPushToDmsUpdate as Date)} at ${formatAsTime(
            lastPushToDmsUpdate as Date
        )}`;
    }

    if (offerSaved && returnedSuccessfulCdmsResponse) {
        return `Deal sent to DT DMS on ${formatAsMMDDYYYY(hasBeenPushedTime as Date)} at ${formatAsTime(
            hasBeenPushedTime as Date
        )}. `;
    }

    return '';
};

export const usePushToDmsButtonText = () => {
    const enableEnhancedPushToDmsPlusMV = useSelector(featureToggleSelectors.enableEnhancedPushToDmsPlusMV);
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const lastPushToDmsUpdate = useSelector(dealXgDetailsSelectors.getLastPushToDmsUpdate);
    const offerSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);

    if (enableEnhancedPushToDmsPlusMV && dmsIntegrationToggle === DMS_PLUS)
        return lastPushToDmsUpdate ? RE_PUSH_TO_DMS_BUTTON_TEXT : PUSH_TO_DMS_BUTTON_TEXT;
    return offerSaved && returnedSuccessfulCdmsResponse ? RESEND_TO_DMS_BUTTON_TEXT : PUSH_TO_DMS_BUTTON_TEXT;
};

export const usePushToDmsConfirmButtonText = () => {
    const enableEnhancedPushToDmsPlusMV = useSelector(featureToggleSelectors.enableEnhancedPushToDmsPlusMV);
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const lastPushToDmsUpdate = useSelector(dealXgDetailsSelectors.getLastPushToDmsUpdate);

    if (enableEnhancedPushToDmsPlusMV && dmsIntegrationToggle === DMS_PLUS && lastPushToDmsUpdate) return CONTINUE_BUTTON_TEXT;
    return CONFIRM_BUTTON_TEXT;
};
