import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const LenderNameContainer = styled.div`
    margin: 8px 0;

    @media (min-width: ${BreakPoint.SMALL}) {
        margin: 8px 0 4px 0;
    }
`;
