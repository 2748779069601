import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { NumericInput } from '@interstate/components/NumericInput';

import { manualIncentivesActionCreators, manualIncentivesSelectors } from '@makemydeal/dr-dash-store';
import { ManualIncentiveEditEntry } from '@makemydeal/dr-platform-types';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

export const AmountField: FC<{ index: number }> = ({ index }) => {
    const fieldName = 'amount';
    const dispatch = useDispatch();
    const showErrors = useSelector(manualIncentivesSelectors.getManualIncentivesShowValidation);
    const isSmallOrLarger = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const isEmpty = useSelector(manualIncentivesSelectors.isManualIncentiveFieldEmptyAtIndex(index, fieldName));

    const deleted = useSelector(
        manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, 'deleted')
    ) as ManualIncentiveEditEntry['deleted'];

    const fieldValue = useSelector(manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, fieldName)) ?? '0';
    const [text, setText] = useState(formatDollarsAndCents(fieldValue.toString()));

    const handleChange = (value: string) => {
        setText(() => value);
    };

    const handleBlur = (value: string) => {
        setText(() => formatDollarsAndCents(value));
    };

    useEffect(() => {
        const id = setTimeout(() => {
            dispatch(manualIncentivesActionCreators.updateShowValidation(false));
            dispatch(manualIncentivesActionCreators.updateManualIncentiveField(index, fieldName, text));
        }, 200);
        return () => {
            clearTimeout(id);
        };
    }, [text]);

    return (
        <NumericInput
            displayLabel={!isSmallOrLarger}
            htmlId={`${fieldName}-${index}`}
            data-testid={`${fieldName}-${index}`}
            label={'Amount'}
            className={`text-input-${fieldName}`}
            name={`${fieldName}-${index}`}
            inputPrefix={'$'}
            autoInsertCommas
            allowDecimal
            decimalMaxLength={2}
            decimalMinLength={2}
            placeholder="0.00"
            onChange={(e) => handleChange(e.target.value)}
            onBlur={(e) => handleBlur(e.target.value)}
            value={text}
            error={showErrors && isEmpty && !deleted ? 'Required' : undefined}
            disabled={deleted}
        />
    );
};
