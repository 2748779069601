import React, { useEffect, useState } from 'react';

import { SelectInput as InterstateSelectInput, SelectInputProps } from '@interstate-104/components/SelectInput';

/**
 * The Interstate beta has a defect on SelectInput which will cause the required error to persist
 * after you add the value.
 * Since updating Interstate might still take some time, we came up with this workaround.
 * It exposes the same props as the normal SelectInput so it can be easily removed in the future.
 * It works by setting the prop hasError to false when we get a value, then immediately setting it to undefined
 * Setting to undefined allows the workaround to work more than once.
 * This component also respects the external hasError prop in case we need to do some other custom validation.
 */
export const SelectInput = React.forwardRef<unknown, SelectInputProps>(({ value, hasError, ...props }, ref) => {
    const [actualHasError, setActualHasError] = useState<boolean | undefined>(undefined);

    // Keep the value from prop and internal state in sync
    useEffect(() => {
        if (hasError) {
            setActualHasError(hasError);
        }
    }, [hasError]);

    // If we get a value and have no external hasError, then let's give a help to SelectInput
    // and let it know it can remove the required error.
    useEffect(() => {
        if (value && !hasError) {
            setActualHasError(false);
        }
    }, [value, hasError]);

    // Any time the actualHasError becomes false we set it to undefined using a useEffect.
    // false and undefined will work the same on SelectInput, but allows us to set it to false again in the future
    // triggering the internal useEffect of SelectInput allowing us to clean any eventual "required error state"
    // which does not clean itself on this version of interstate.
    useEffect(() => {
        // NOTE: it is important to explicitly check if it's equal to false
        // as we do not want to run the state change when it's equal to undefined.
        if (actualHasError === false) {
            setActualHasError(undefined);
        }
    }, [actualHasError]);

    return <InterstateSelectInput {...props} value={value} hasError={actualHasError} ref={ref} />;
});
