// externals
import { FC } from 'react';

// libraries
import { TextInput } from '@interstate-104/components/TextInput';

// types
import { CellFinanceDetailsProps } from './types';

// consts/enums
import { InterstateOnChangeCallback } from '@interstate-104/components/InterstateEvents';
import { NA } from '../common/taxesAndFeesConsts';
import { useFeesFormContext } from './useFeesFormContext';

// utils
import { encodeForDataTestId, handleAcceptOnlyNumbers } from '../../utils/components';

export const FinanceAmountCell: FC<CellFinanceDetailsProps> = ({ detail, index }) => {
    const { handleInputChange } = useFeesFormContext();

    const handleFeeAmountChange: InterstateOnChangeCallback<string> = (e) => {
        const value = e.target.value;
        const hasMoreThanTwoPoints = value.split('.').length > 2;

        if (hasMoreThanTwoPoints) {
            return;
        }

        return handleInputChange({ category: detail.category, field: 'feeAmount', index, value });
    };

    return (
        <TextInput
            name="feeAmount"
            label="Amount"
            required
            className="edit-fee--amount-cell"
            data-testid={`edit-fee--amount-cell-${encodeForDataTestId(detail.name)}`}
            value={detail.amount == null ? NA : detail.amount}
            disabled={detail.isDeleted}
            onKeyDown={handleAcceptOnlyNumbers}
            onChange={handleFeeAmountChange}
        />
    );
};
