import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@interstate-104/components/Grid';

import { CASH } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import { TermsTab } from './common/Terms.style';
import RetailPriceField from './common/RetailPriceField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';

const Cash = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    if (currentOfferType !== CASH) return null;
    return (
        <TermsTab data-testid="cash-terms">
            <Grid container spacing={2}>
                <Grid xs={6}>
                    <RetailPriceField />
                </Grid>
                <Grid xs={6}>
                    <SellingPriceField />
                </Grid>
            </Grid>
        </TermsTab>
    );
};

export default Cash;
