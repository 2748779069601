import { useState } from 'react';

import { Action } from '@interstate-104/components/Action';
import { Typography } from '@interstate-104/components/Typography';
import { FlexGridCol, FlexRow } from '@makemydeal/dr-dash-components';

import {
    LIENHOLDER_FORM_SHOW_LESS,
    LIENHOLDER_FORM_SHOW_MORE,
    LIENHOLDER_FORM_SUMMARY_ADDRESS,
    LienholderSummaryControlIds
} from './constants';
import { SummaryData } from './utils';
import { SummaryDataBox } from './SummaryDataBox';
import {
    LIEN_HOLDER_ADDRESS_BANK,
    LIEN_HOLDER_ADDRESS_CITY,
    LIEN_HOLDER_ADDRESS_STATE,
    LIEN_HOLDER_ADDRESS_ZIP
} from '../../../constants';

export interface LienholderSummaryProps {
    minVisibleRows?: number;
    data: SummaryData;
}

export const LienholderSummary = ({ data: lienholderInfo, minVisibleRows = 3 }: LienholderSummaryProps) => {
    const [showMore, setShowMore] = useState(true);

    const renderData = [
        ...Object.entries({ ...lienholderInfo, [LIENHOLDER_FORM_SUMMARY_ADDRESS]: undefined })
            .filter(([, val]) => !!val)
            .map(([label, value]) => (
                <SummaryDataBox key={label} label={label}>
                    {value}
                </SummaryDataBox>
            ))
    ];

    const address = lienholderInfo[LIENHOLDER_FORM_SUMMARY_ADDRESS];

    if (address) {
        const renderAddressBank = address[LIEN_HOLDER_ADDRESS_BANK] ? (
            <>
                {address[LIEN_HOLDER_ADDRESS_BANK]}
                <br />
            </>
        ) : null;

        let renderCityStateZip = address[LIEN_HOLDER_ADDRESS_CITY] || '';

        if (address[LIEN_HOLDER_ADDRESS_STATE]) {
            renderCityStateZip += renderCityStateZip.length ? ' ' : '';
            renderCityStateZip += address[LIEN_HOLDER_ADDRESS_STATE];
        }

        if (address[LIEN_HOLDER_ADDRESS_ZIP]) {
            renderCityStateZip += renderCityStateZip.length ? ', ' : '';
            renderCityStateZip += address[LIEN_HOLDER_ADDRESS_ZIP];
        }

        if (renderAddressBank || renderCityStateZip) {
            renderData.push(
                <SummaryDataBox key={LIENHOLDER_FORM_SUMMARY_ADDRESS} label={LIENHOLDER_FORM_SUMMARY_ADDRESS}>
                    {renderAddressBank}
                    {renderCityStateZip}
                </SummaryDataBox>
            );
        }
    }

    const isShorten = renderData.length > minVisibleRows;

    return (
        <>
            <FlexGridCol cols={2} smCols={3} alignItems="flex-start" data-testid={LienholderSummaryControlIds.Summary}>
                {isShorten ? (showMore ? renderData.slice(0, minVisibleRows) : renderData) : renderData}
            </FlexGridCol>
            {isShorten && (
                <FlexRow>
                    <Action
                        onClick={() => {
                            setShowMore(!showMore);
                        }}
                        data-testid={LienholderSummaryControlIds.ActionButtonShowMoreOrLess}
                    >
                        <Typography variant="anchor-block-sm" color="base.color.blue.700">
                            {showMore ? LIENHOLDER_FORM_SHOW_MORE : LIENHOLDER_FORM_SHOW_LESS}
                        </Typography>
                    </Action>
                </FlexRow>
            )}
        </>
    );
};

export default LienholderSummary;
