// externals
import React from 'react';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { SimpleTable, TableCell } from '@interstate-104/components/SimpleTable';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// components
import TableHead from './TableHead';
import TableBody from './TableBody';

// styles
import { StyledSpan, StyledTableCell } from './TaxCard.style';

// consts/enums
import { DATA_DENSITY, NA } from '../../common/taxesAndFeesConsts';
import { STATE_TAX_NAME, TAX_ITEM_KEYS, TAXTABLECOLUMNS } from '../constants';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { InterstateOnChangeEvent } from '@interstate-104/components/InterstateEvents';
import { TextInputEventValue } from '@interstate-104/components/TextInput';
import TaxItemField from './TaxItemField';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';

type Detail = {
    name: string;
    rate: number | undefined;
    amount: number | undefined;
};

const TaxItemSummarySimple: React.FC<{
    taxItem: paymentServicesTypes.TaxItem;
    handleChange: (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
}> = ({ taxItem, handleChange }) => {
    const { taxRate, taxAmount, isManualTax } = taxItem;
    const keyRoot = 'simple-cell';
    const styledRoot = 'simple-table-cell';
    const isOverrideTaxRateForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxRateForPaymentEnabled);

    const simpleDetails = [
        { name: STATE_TAX_NAME, rate: taxRate, amount: taxAmount, key: TAX_ITEM_KEYS.STATE_TAX_RATE, isEditedRate: isManualTax }
    ];

    const hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const isRateEditable = isOverrideTaxRateForPaymentEnabled && taxItem.class === 'primary' && taxItem.taxBasisAmount !== 0;

    const renderRow = (detail: Detail, index: number) => (
        <React.Fragment key={`${keyRoot}-${index}-${detail.name}-${detail.amount}`}>
            <TableCell>
                <StyledTableCell textAlign="left" data-testid={`${styledRoot}-${detail.name}`}>
                    {hasManualTotalTax ? (
                        <StyledSpan paddingLeft={1}>
                            <DefaultFallback />
                        </StyledSpan>
                    ) : (
                        `${detail.name}`
                    )}
                </StyledTableCell>
            </TableCell>
            <TableCell key={`${keyRoot}-${index}-${taxItem.taxName}-rate}`}>
                {isRateEditable ? (
                    <StyledTableCell data-testid={`${styledRoot}-${detail.rate}`} textAlign="center">
                        {hasManualTotalTax ? <DefaultFallback /> : <TaxItemField data={detail} handleBlur={handleChange} />}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="center" data-testid={`${styledRoot}-${detail.rate}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax && (detail.rate == null ? NA : `${detail.rate}%`)}
                    </StyledTableCell>
                )}
            </TableCell>
            <TableCell key={`${keyRoot}-${index}-${taxItem.taxName}-amount`}>
                <StyledTableCell textAlign="right" data-testid={`${styledRoot}-${detail.amount}`}>
                    {hasManualTotalTax && <DefaultFallback />}
                    {!hasManualTotalTax && (detail.amount == null ? NA : formatDollarsAndCents(detail.amount))}
                </StyledTableCell>
            </TableCell>
        </React.Fragment>
    );

    return (
        <SimpleTable data-testid="simple-table-data-testid" dataDensity={DATA_DENSITY} background="white">
            <TableHead columns={TAXTABLECOLUMNS} />
            <TableBody data={simpleDetails} renderRow={renderRow} />
        </SimpleTable>
    );
};

export default TaxItemSummarySimple;
