import { Box } from '@interstate/components/Box';
import { Alert } from '@interstate/components/Alert';
import { ManualLegendMessage } from './ManuakLegendMessage';
import TotalSummary from '../../common/TotalSummary';

interface IProps {
    hasManualLegend: boolean;
    totalTax: number;
}

export const DisabledOverrideTax = ({ hasManualLegend, totalTax }: IProps) => {
    return (
        <Box margin="1rem 0">
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Alert id="total-tax-override-unavailable" type="info">
                    Monthly Use Taxes and/or Cap Cost Reduction Taxes are required and therefore total taxes cannot be overwritten.
                </Alert>
            </Box>
            <Box marginTop="1rem" display="end">
                {hasManualLegend && <ManualLegendMessage />}
                <TotalSummary totalType="Tax" total={totalTax} paddingRight={2.8} />
            </Box>
        </Box>
    );
};
