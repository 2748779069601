import { useSelector } from 'react-redux';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors, offerAdditionalSelectors } from '@makemydeal/dr-dash-store';

import { TOTAL_AMOUNT_DUE } from '../../constants';
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';
import DealSummaryItem from '../common/DealSummaryItem';

export type TotalAmountDueProps = DealState & {
    showLargeValue?: boolean;
    hasDelta?: boolean;
};

const TotalAmountDue = ({ deal, showLargeValue, hasDelta = false }: TotalAmountDueProps) => {
    const state = useDealRootState(deal);
    const isUsePSTotalAmountDueForCashDealsEnabled = useSelector(featureToggleSelectors.isUsePSTotalAmountDueForCashDealsEnabled);
    // old logic - calculation - needs to be replaced by getCashTotalAmountDueAfterCredits
    const cashOfferRoundedUp = offerAdditionalSelectors.getMvCashOfferRoundedUp(state, true);
    // new logic - read PS field directly
    const totalAmountDueAfterCredits = offerReduxSelectors.getCashTotalAmountDueAfterCredits(state);

    let cashTotalAmountDue = cashOfferRoundedUp;
    if (isUsePSTotalAmountDueForCashDealsEnabled) {
        cashTotalAmountDue = totalAmountDueAfterCredits;
    }

    return (
        <DealSummaryItem
            label={TOTAL_AMOUNT_DUE}
            value={formatDollarsAndCents(cashTotalAmountDue)}
            showLargeValue={showLargeValue}
            hasDelta={hasDelta}
        />
    );
};

export default TotalAmountDue;
