import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';

// constants
import { ENGINE } from '../../../constants';

// components
import { TextInput } from '@interstate-104/components/TextInput';
import { Typography } from '@interstate-104/components/Typography';

// styling
import { DetailContainer } from '../trade.style';

interface EngineFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const EngineField: React.FC<EngineFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const engine = useSelector(tradeInSelectors.getEngine);

    const onChange = (event: any) => {
        dispatch(tradeInActionCreators.updateTradeInEngine(event.target.value));
    };

    return (
        <>
            {isManualFormOpen ? (
                <TextInput
                    label={ENGINE}
                    data-testid={kebabCase(ENGINE)}
                    name={kebabCase(ENGINE)}
                    onChange={onChange}
                    value={engine}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {ENGINE}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-engine-value-label">
                                {engine}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};
