import styled from 'styled-components';
import { Box } from '@interstate/components/Box';

export const VehicleInfoItemContainer = styled(Box)`
    border-radius: 4px;
    background: var(--surface-subdued, ${({ theme }) => theme.tokens.BaseColorGray50});
    padding: 16px;
    gap: 4px;
    align-self: stretch;
    margin-bottom: 32px;
    .rowItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        gap: 4px;
        .label {
            padding-bottom: 6px;
            padding-left: 0;
        }
        .vehicleInfoValue {
            text-align: right;
        }
    }
`;
export const LinkContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: fit-content;
    span {
        white-space: nowrap; // Prevent Action label break the line
    }
`;

export const FooterContainer = styled(Box)`
    display: flex;
    width: 100%;
    border-top: 1px solid rgb(149, 149, 149);
    background-color: rgba(244, 244, 244, 0.96);
    padding: 8px 16px;
    button {
        width: 100%;
    }
`;

export const DrawerContent = styled(Box)`
    min-height: 101%;
    body {
        background: unset;
    }
`;

export const VehicleImageContent = styled(Box)`
    border-radius: 6px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
    overflow: hidden;
    object-fit: cover;
`;
