// externals
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import { routerMiddleware } from 'connected-react-router';

// utils
import { historyInstance } from '@makemydeal/dr-shared-store';

// middleware
import { bootstrapMiddleware } from '../middleware/bootstrapMiddleware';
import { offerMiddleware, googleAnalyticsEventMiddleware, socketMiddleware, deskingMiddleware } from '@makemydeal/dr-dash-store';

export const getInitialMiddleware = () => [
    thunk,
    apiMiddleware,
    bootstrapMiddleware,
    routerMiddleware(historyInstance),
    googleAnalyticsEventMiddleware,
    offerMiddleware.middleware,
    socketMiddleware.middleware,
    deskingMiddleware
];
