// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// consts
import {
    PUSH_TO_DMS,
    PUSH_TO_DMS_REQUEST,
    PUSH_TO_DMS_SUCCESS,
    PUSH_TO_DMS_FAILURE,
    COPY_DMS_STOCK_NUMBER,
    PUSH_TO_DMS_PLUS,
    PUSH_TO_DMS_ERROR,
    PUSH_TO_DMS_UPDATE_TIMESTAMP,
    PUSH_TO_DMS_WS_EVENT
} from '../actionTypes/pushToDmsActionTypes';

// utils
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// selectors
import { getPushToDmsDealXgId, getPushToDmsDealXgVersion } from '../selectors/pushToDmsSelectors';

export const pushToDms = () => {
    return {
        type: PUSH_TO_DMS
    };
};

export const pushToDmsPlus = () => {
    return {
        type: PUSH_TO_DMS_PLUS
    };
};

export const copyDmsStockNumber = (dmsStockNumber: string) => {
    return {
        type: COPY_DMS_STOCK_NUMBER,
        payload: {
            dmsStockNumber
        }
    };
};

export const dmsError = (statusCode: number, errorDetails?: any) => {
    return {
        type: PUSH_TO_DMS_ERROR,
        payload: {
            errorDetails,
            statusCode
        }
    };
};

export const pushToDmsRSAA = (appPrefix: string, state: StateTree) => {
    // Pushing to CDMS should use dealer track override Id as partner-dealer-id
    // if the dealer has DT override enabled in Accelerate's Admin
    const dealerTrackOverrideEnabled = dealerSelectors.getLeadRoutingOverrideSettingsEnabled(state);
    const dealertrackOverrideId = `${dealerSelectors.getLeadOverrideDealerId(state)}`;

    const dealerId =
        dealerTrackOverrideEnabled && dealertrackOverrideId ? dealertrackOverrideId : dealerSelectors.getDealerId(state).toString();
    const dealXgId = getPushToDmsDealXgId(state);
    const dealXgVersion = getPushToDmsDealXgVersion(state);
    const isEnableOptionalPbcUi = featureToggleSelectors.enableOptionalPbcUi(state);

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, 'cdms');
    const endpoint = bffApiUrlResult.url + (isEnableOptionalPbcUi ? `?mode=storefront` : '');

    const body = JSON.stringify({
        dealerId,
        dealXgId,
        dealXgVersion
    });

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body,
            types: [PUSH_TO_DMS_REQUEST, PUSH_TO_DMS_SUCCESS, PUSH_TO_DMS_FAILURE]
        }
    };
};
