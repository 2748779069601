// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// externals
import { Middleware, applyMiddleware, createStore, compose } from 'redux';
import { connectRouter } from 'connected-react-router';

// utils
import historyInstance from './historyInstance';
import { combineReducersDynamically } from './configureStoreUtils';
import { reducerRegistry } from './reducerRegistry';
import { getWindowObj } from '../utils/windowUtil';
// import { getModule } from '../utils/moduleUtil';
import * as configureStoreCommon from './configureStoreCommon';

// selectors
// import * as featureTogglSelectors from '../selectors/featureToggleSelectors';
// import { dealerSelectors } from '@makemydeal/dr-shared-store';

// TODO: DASH - fix this
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreloadedState {}

// { router: connectRouter(history), ...reducers }

export function configureStore(
    preloadedState: IPreloadedState,
    initialMiddleware: Middleware<any, any, any>[],
    initialReducers: any
) {
    reducerRegistry.register('router', connectRouter(historyInstance));
    reducerRegistry.registerMultiple(initialReducers);
    const allReducers = combineReducersDynamically(preloadedState, reducerRegistry.getReducers());

    const windowObj = getWindowObj();

    const reduxDevTools = typeof windowObj === 'object' && (windowObj as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    const composeEnhancers = reduxDevTools
        ? (windowObj as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Dash', trace: true, traceLimit: 100 })
        : compose;
    const store = createStore(allReducers, preloadedState, composeEnhancers(applyMiddleware(...initialMiddleware)));
    configureStoreCommon.setStore(store);
    reducerRegistry.setChangeListener((reducers) => {
        store.replaceReducer(combineReducersDynamically(preloadedState, reducers));
    });

    // TODO: Re-enable this later
    // const nodeModule = getModule(module);
    // if (nodeModule.hot) {
    //     // Enable Webpack hot module replacement for reducers
    //     nodeModule.hot.accept('../reducers/allReducers', () => {
    //         const state = store.getState();
    //         const featureToggles = featureToggleSelectors.getFeatureToggles(state);
    //         const dealer = dealerSelectors.getDealerState(state);

    //         // TODO: DASH - improve this
    //         // eslint-disable-next-line @typescript-eslint/no-var-requires
    //         const allReducersUtil = require('../reducers/allReducers');
    //         store.replaceReducer(
    //             allReducersUtil.getAllReducers({
    //                 featureToggles,
    //                 dealer
    //             })
    //         );
    //     });
    // }

    return store;
}
