import { customerUIActionCreators, customerUISelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CommonConsumer } from '@makemydeal/dr-platform-types';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerDetails } from './CustomerDetails';
import { CustomerSearch } from './CustomerSearch';
import {
    CustomerCardMessageContentSelector,
    CustomerCardShowOptions,
    CustomerSearchCallback,
    CustomerUIContext
} from './CustomerUIContext';

export type CustomerUIProviderProps = PropsWithChildren;

/**
 * Customer card provider manages slide out state for the customer details panel. We're using context here so the toggle function
 * is accessible to any part of the application (like the header)
 */
export const CustomerUIProvider: FC<CustomerUIProviderProps> = ({ children }) => {
    // NOTE: grouping state so they change in parallel downstream; i.e. show + option == 1 state change propagation
    const dispatch = useDispatch();
    const buyerCommonConsumerId = useSelector(offerReduxSelectors.getShopperCommonConsumerId);
    const dealershipId = useSelector(offerReduxSelectors.getCommonOrgId);

    // redux managed properties
    const customerCardShowing = useSelector(customerUISelectors.getCustomerCardShowing);
    const customerSearchShowing = useSelector(customerUISelectors.getCustomerSearchShowing);
    const customerCardOptions = useSelector(customerUISelectors.getCustomerCardOptions);

    // variable callback references that cannot be stored in state.
    const refCustomerSelectedCallback = useRef<CustomerSearchCallback | undefined>();
    const refCustomerChangedCallback = useRef<CustomerSearchCallback | undefined>();
    const refMessageContentSelector = useRef<CustomerCardMessageContentSelector | undefined>();

    // local delegates to manage the callbacks
    const localCustomerSelectedCallback = useCallback(
        (customer: CommonConsumer) => {
            if (refCustomerSelectedCallback.current) {
                refCustomerSelectedCallback.current(customer);
            }
        },
        [refCustomerSelectedCallback]
    );
    const localCustomerChangedCallback = useCallback(
        (customer: CommonConsumer) => {
            if (refCustomerChangedCallback.current) {
                refCustomerChangedCallback.current(customer);
            }
        },
        [refCustomerChangedCallback]
    );

    const localCustomerCardMessageContentSelector = useCallback(
        (state: any) => {
            if (refMessageContentSelector.current) {
                return refMessageContentSelector.current(state);
            }
            return '';
        },
        [refMessageContentSelector]
    );

    const showSearch = (show: boolean, callback?: CustomerSearchCallback) => {
        refCustomerSelectedCallback.current = callback;
        dispatch(customerUIActionCreators.showCustomerSearch(show));
    };

    const showCustomerCard = (show: boolean, options: CustomerCardShowOptions = {}) => {
        const { messageContentSelector, customerEditedCallback, ...stateOptions } = options;
        stateOptions.commonConsumerId = stateOptions.commonConsumerId ?? buyerCommonConsumerId;

        refMessageContentSelector.current = options.messageContentSelector;
        refCustomerChangedCallback.current = customerEditedCallback;
        dispatch(customerUIActionCreators.showCustomerCard(show, show ? stateOptions : undefined));
    };

    // internal toggle functions; these are used from within the customer Search/Details components to self-close
    const internalSetShowCustomerCard = (show: boolean) => {
        dispatch(customerUIActionCreators.showCustomerCard(show));
    };
    const internalSetShowCustomerSearch = (show: boolean) => {
        dispatch(customerUIActionCreators.showCustomerSearch(show));
    };

    return (
        <CustomerUIContext.Provider value={{ showCustomerCard, showSearch }}>
            {children}
            <CustomerDetails
                show={customerCardShowing}
                setShow={internalSetShowCustomerCard}
                commonConsumerId={customerCardOptions.commonConsumerId ?? ''}
                onCustomerChanged={localCustomerChangedCallback}
                messageContentSelector={localCustomerCardMessageContentSelector}
                tab={customerCardOptions.tab ?? 'information'}
                waitForOfferSave={customerCardOptions.waitForOfferSave}
                enableReadOnlyEditFields={customerCardOptions.readOnlyFields}
            />
            <CustomerSearch
                show={customerSearchShowing}
                setShow={internalSetShowCustomerSearch}
                commonOrgId={dealershipId} // use "CoOrgTestId1" for local testing
                onCustomerSelected={localCustomerSelectedCallback}
            />
        </CustomerUIContext.Provider>
    );
};
