/**
 * This file provides the dash app with the action creators needed by the menu activity
 */

// libraries
import { MenuLifecycleState } from '@makemydeal/dr-activities-menu';
import { HostActions, IUpdateMenuProducts } from '@makemydeal/dr-activities-menu';

// actions
import { buildAnalyticsAction } from '../../actions/analytics';

// consts/enums
import { ACCESSORY_SELECTIONS_SUBMIT_SUCCESS, ANALYTICS_ADD_TO_QUEUE, REVIEW_DEAL_UPDATE_MENU_PRODUCTS } from './constants';
import {
    DASH_MENU_LIFECYCLE_INIT_FINISH,
    DASH_MENU_LIFECYCLE_INIT_START,
    DASH_MENU_LIFECYCLE_UNKNOWN
} from '../../actionTypes/vehicleProtectionActionTypes';
export const hostActions: HostActions = {
    analyticsAction: buildAnalyticsAction,
    addToAnalyticsQueue: (payload) => {
        return {
            type: ANALYTICS_ADD_TO_QUEUE,
            payload
        };
    },
    sendProgressNow: () => null,
    submitDealSummary: () => null,
    submitOffer: () => null,
    updateMenuProducts: (menuProducts: IUpdateMenuProducts) => {
        return {
            type: REVIEW_DEAL_UPDATE_MENU_PRODUCTS,
            payload: menuProducts
        };
    },
    handleSubmitAftermarketSelectionsSuccess: (menuSelectionsId: string, menuItemId: string, isInterested: boolean) => {
        return {
            type: ACCESSORY_SELECTIONS_SUBMIT_SUCCESS,
            payload: {
                menuSelectionsId
            },
            meta: {
                menuItemId,
                isInterested
            }
        };
    },
    updateMenuLifecycleState: (menuLifecycleState: MenuLifecycleState) => {
        switch (menuLifecycleState) {
            case MenuLifecycleState.InitStarted:
                return { type: DASH_MENU_LIFECYCLE_INIT_START };
            case MenuLifecycleState.InitCompleted:
                return { type: DASH_MENU_LIFECYCLE_INIT_FINISH };
            default:
                return { type: DASH_MENU_LIFECYCLE_UNKNOWN, payload: menuLifecycleState };
        }
    }
};
