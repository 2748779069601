import { Box } from '@interstate-104/components/Box';
import { Typography } from '@interstate-104/components/Typography';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

type TotalSummary = {
    totalType: string;
    total: number;
    paddingRight: number;
};

const TotalSummary: React.FC<TotalSummary> = ({ totalType, total, paddingRight }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="end">
            <Typography variant="h5" color="base.color.black" sx={{ paddingRight }}>
                {`Total ${totalType}: ${formatDollarsAndCents(total)}`}
            </Typography>
        </Box>
    );
};
export default TotalSummary;
