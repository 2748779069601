// libraries
import {
    CdmsResponseData,
    CdmsResponseMessageData,
    PushToDmsError,
    PushToDmsState,
    PushToDmsStatus,
    StateTree
} from '@makemydeal/dr-dash-types';

export const getPushToDmsState = (state: StateTree) => {
    return state.pushToDms || ({} as PushToDmsState);
};

export const getPushToDmsError = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    return dmsState.error || ({} as PushToDmsError);
};

export const getCdmsRequestFailure = (state: StateTree) => {
    const dmsErrorState = getPushToDmsError(state);
    return dmsErrorState.statusCode === 500 || false;
};

export const getCdmsResponseData = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    return dmsState.cdmsResponseData || ({} as CdmsResponseData);
};

export const getCdmsResponseMessageData = (state: StateTree) => {
    const cdmsResponseData = getCdmsResponseData(state);
    return cdmsResponseData.message || ({} as CdmsResponseMessageData);
};

export const getReturnedSuccessfulCdmsResponse = (state: StateTree) => {
    const cdmsResponseData = getCdmsResponseData(state);
    const cdmsResponseStatus = cdmsResponseData.status;
    return cdmsResponseStatus === PushToDmsStatus.Ok || false;
};

export const getCdmsResponseStatusCode = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    const cdmsResponseStatusCode = dmsState.responseStatusCode;
    return cdmsResponseStatusCode || 500;
};

export const getCdmsResponseErrorMessage = (state: StateTree) => {
    const cdmsResponseMessageData = getCdmsResponseMessageData(state);
    const errorDetails = cdmsResponseMessageData.message;
    return errorDetails || '';
};

export const getDmsDealId = (state: StateTree) => {
    const cdmsResponseMessageData = getCdmsResponseMessageData(state);
    return cdmsResponseMessageData.dmsDealId || '';
};

export const getPushToDmsDealXgId = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    return dmsState.dealXgId || '';
};

export const getPushToDmsDealXgVersion = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    return dmsState.dealXgVersion || '';
};

export const getTransferToDMS = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    return dmsState.transferToDMS ?? '';
};

export const getPushToDmsWsEvent = (state: StateTree) => {
    const dmsState = getPushToDmsState(state);
    return dmsState.pushToDmsWsEvent || {};
};
