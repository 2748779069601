import React from 'react';
import { useSelector } from 'react-redux';

import { CASH, LEASE, FINANCE } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate-104/components/Typography';

import Cash from './CashDealSummary.interstate';
import Finance from './FinanceDealSummary.interstate';
import Lease from './LeaseDealSummary.interstate';
import { DealSummaryContainer, HeaderRowWrapper } from './DealSummary.interstate.style';
import { PrintOfferLink } from '../printOffer/PrintOfferLink.interstate';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const DealSummary = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isPdfPrintMvEnabled = useSelector(featureToggleSelectors.isPdfPrintMvEnabled);

    const getComponent = () => {
        switch (currentOfferType) {
            case CASH:
                return <Cash />;
            case FINANCE:
                return <Finance />;
            case LEASE:
                return <Lease />;
        }
    };

    return (
        <>
            <HeaderRowWrapper>
                <Typography variant="h3">Deal Details</Typography>
                {isPdfPrintMvEnabled && <PrintOfferLink />}
            </HeaderRowWrapper>
            <DealSummaryContainer data-testid="deal-summary-container" xs={3.9} width="100%">
                {getComponent()}
            </DealSummaryContainer>
        </>
    );
};

export default DealSummary;
