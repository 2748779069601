// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { bootstrapSharedActionCreators } from '@makemydeal/dr-shared-store';
import {
    dealHistorySelectors,
    navigationHooks,
    offerSelectors,
    quickCompareActionCreators,
    dealHistoryActionTypes
} from '@makemydeal/dr-dash-store';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// constants
import { RouteValues } from '@makemydeal/dr-dash-store';

// components;
import { MakeCurrentModalInterstate, MakeCurrentButtonView, MAX_APP_CONTAINER_WIDTH } from '@makemydeal/dr-dash-components';

// styling
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { DealHistoryFooterContainer, DealHistoryFooterInterstateButtonContainer } from '../DealHistory.interstate.style';

const DealHistoryFooterInterstate = () => {
    const dispatch = useDispatch();
    const workingOffer = useSelector(offerSelectors.getWorkingOffer);
    const selectedDeal = useSelector(dealHistorySelectors.getSelectedDealBySelectedColumn);
    const onCloseButtonClick = navigationHooks.useNavigate(RouteValues.DASHBOARD);

    const dispatchMakeCurrentOffer = () => {
        if (selectedDeal) {
            const dealXgVersion = selectedDeal.offer.dealXgVersion;
            dispatch({ type: dealHistoryActionTypes.FETCH_DEAL_HISTORY_REQUEST });
            dispatch(
                bootstrapSharedActionCreators.fetchBootstrapData(MANAGER_VIEW_APP_PREFIX, {
                    withCurrentDealVersion: true,
                    dealXgVersion
                }) as any
            );
        }
        onCloseButtonClick();
    };

    return (
        <DealHistoryFooterContainer>
            <DealHistoryFooterInterstateButtonContainer>
                <Grid
                    container
                    justifyContent="flex-end"
                    gap={1}
                    paddingX={4}
                    paddingY={2}
                    bgcolor="#FFF"
                    borderTop="1px solid #B2B2B2"
                >
                    <Button
                        size="medium"
                        htmlId="btnDealHistoryFooter"
                        data-testid="btn-dealHistory-footer"
                        buttonStyle="tertiary"
                        onClick={onCloseButtonClick}
                        className="btn-dealHistory-footer btn-link"
                    >
                        Cancel
                    </Button>
                    {selectedDeal && (
                        <MakeCurrentModalInterstate
                            onMakeCurrentOffer={dispatchMakeCurrentOffer}
                            makeCurrentButtonView={MakeCurrentButtonView.DealHistory}
                        />
                    )}
                </Grid>
            </DealHistoryFooterInterstateButtonContainer>
        </DealHistoryFooterContainer>
    );
};

export default DealHistoryFooterInterstate;
