import { FlexRow } from '@makemydeal/dr-dash-components';

import { Button } from '@interstate-104/components/Button';
import {
    LIENHOLDER_FORM_ACTION_BUTTON_APPLY,
    LIENHOLDER_FORM_ACTION_BUTTON_CANCEL,
    LienholderFormActionGroupControlIds
} from './constants';

export interface LienholderActionGroupProps {
    disableApplyButton: boolean;
    handleApply: () => void;
    handleCancel: () => void;
}

const LienholderActionGroup = ({ disableApplyButton, handleApply, handleCancel }: LienholderActionGroupProps) => {
    return (
        <FlexRow justifyContent="flex-end" data-testid={LienholderFormActionGroupControlIds.ActionButtonsGroup}>
            <Button
                id={LienholderFormActionGroupControlIds.ActionButtonCancel}
                data-testid={LienholderFormActionGroupControlIds.ActionButtonCancel}
                buttonStyle="secondary"
                onClick={handleCancel}
            >
                {LIENHOLDER_FORM_ACTION_BUTTON_CANCEL}
            </Button>
            <Button
                id={LienholderFormActionGroupControlIds.ActionButtonApply}
                data-testid={LienholderFormActionGroupControlIds.ActionButtonApply}
                buttonStyle="primary"
                onClick={handleApply}
                sx={{ marginLeft: '16px' }}
                disabled={disableApplyButton}
            >
                {LIENHOLDER_FORM_ACTION_BUTTON_APPLY}
            </Button>
        </FlexRow>
    );
};

export default LienholderActionGroup;
