import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader } from '@interstate-104/components/Card';
import { navigationActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CASH } from '@makemydeal/dr-platform-types';
import { Action } from '@interstate-104/components/Action';
import { Typography } from '@interstate-104/components/Typography';
import { manualIncentivesSelectors } from '@makemydeal/dr-dash-store';

import { PROGRAMS_AND_INCENTIVES } from '../../constants';
import { RebatesView } from './RebatesView.interstate';
import { RebatesEdit } from './RebatesEdit.interstate';
import { useScrollIntoView } from '../../utils/useScrollIntoView';
import { LenderNameContainer } from './ManualRebatesCard.interstate.styles';

export const ManualRebatesCard = () => {
    const [mode, setMode] = useState<'view' | 'edit'>('view');
    const isManualFormOpen = useSelector(manualIncentivesSelectors.isManualFormOpen);
    const manualIncentivesRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isManualFormOpen) setMode('edit');
        else setMode('view');
    }, [isManualFormOpen]);

    useScrollIntoView(manualIncentivesRef, isManualFormOpen);
    const dispatch = useDispatch();
    const htmlId = 'manual-rebates-card';

    const creditDecisionEnabled = useSelector(offerReduxSelectors.getIsUserProgramQuotes);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderLegalName = useSelector(offerReduxSelectors.getLenderLegalName);
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives);
    const actionLabel = !incentives.length ? 'Add' : 'Edit';

    const header = useMemo(
        () => (
            <CardHeader
                title={PROGRAMS_AND_INCENTIVES}
                action={
                    <>
                        {!creditDecisionEnabled && (
                            <Action
                                data-testid="programs-link"
                                sx={{ padding: '6px', border: '0' }}
                                onClick={() => dispatch(navigationActionCreators.navigateToPrograms())}
                            >
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    View Programs
                                </Typography>
                            </Action>
                        )}
                        {mode !== 'edit' && (
                            <Action data-testid={`${htmlId}-edit-link`} onClick={() => setMode('edit')}>
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    {actionLabel}
                                </Typography>
                            </Action>
                        )}
                    </>
                }
            />
        ),
        [creditDecisionEnabled, mode, actionLabel, dispatch]
    );

    const content = useMemo(
        () => (
            <>
                {offerType === CASH ? (
                    offerType
                ) : (
                    <LenderNameContainer>
                        <Typography variant="h6" color="base.color.black">
                            {lenderLegalName}
                        </Typography>
                    </LenderNameContainer>
                )}
                <Typography variant="body-sm" color="base.color.gray.600">
                    <>
                        {mode === 'view' && <RebatesView />}
                        {mode === 'edit' && (
                            <RebatesEdit
                                setIncentivesMode={(mode: 'view' | 'edit') => {
                                    setMode(mode);
                                }}
                            />
                        )}
                    </>
                </Typography>
            </>
        ),
        [offerType, lenderLegalName, mode]
    );

    return <Card ref={manualIncentivesRef} data-testid="incentives-card" header={header} content={content} />;
};
