const personaSuffix: any = {
    dealer: 'Sales',
    shopper: 'Customer',
    dealmaker: 'You'
};

const personaPrefix: any = {
    dealer: 'Sales',
    shopper: 'Customer',
    dealmaker: 'Your'
};

export const getHeaderLabel = (
    mostRecentDealerFlag: boolean | undefined,
    mostRecentShopperFlag: boolean | undefined,
    offerSource?: string
) => {
    const source = (offerSource && offerSource.toLowerCase()) || 'dealmaker';
    const personaSuffixVerbiage = personaSuffix[source];
    const personaPrefixVerbiage = personaPrefix[source];
    const isLatest = mostRecentDealerFlag || mostRecentShopperFlag;
    return isLatest ? `Latest Offer (${personaSuffixVerbiage})` : `${personaPrefixVerbiage} Offer`;
};
