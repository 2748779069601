// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// actions
import { fetchDocUploadRSAA } from '../actions/docUploadActionCreators';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

const { INIT_SUCCESS } = initActionTypes;
export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case INIT_SUCCESS:
            store.dispatch(fetchDocUploadRSAA(MANAGER_VIEW_APP_PREFIX, state));
            break;
        default:
            break;
    }
};
