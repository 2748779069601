import React from 'react';
import { useSelector } from 'react-redux';

import { offerSelectors } from '@makemydeal/dr-dash-store';

import { kebabCase } from '@makemydeal/dr-common-utils';
import { PAYMENT_FREQUENCY } from '../../constants';
import { TextInput } from '@interstate/components/TextInput';

/**
 * CX requires an onchange handler prop, but this is a disabled and readOnly field so we can ignore its behavior for coverage
 */
// istanbul ignore next
const onChangeNoop = () => true;

const PaymentFrequencyField = () => {
    const paymentFrequency = useSelector(offerSelectors.getPaymentFrequency);

    return (
        <TextInput
            label={PAYMENT_FREQUENCY}
            name={kebabCase(PAYMENT_FREQUENCY)}
            onChange={onChangeNoop}
            disabled={true}
            value={paymentFrequency}
            data-testid={kebabCase(PAYMENT_FREQUENCY)}
        />
    );
};

export default PaymentFrequencyField;
