import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CheckBox } from '@interstate/components/CheckBox';
import { offerActionCreators, offerSelectors } from '@makemydeal/dr-dash-store';

import { kebabCase } from '@makemydeal/dr-common-utils';
import { ACQUISITION_FEE_ABBR, ACQUISITION_FEE_OPTION_LABEL_INTERSTATE } from '../../constants';
import { RSAAAction } from 'redux-api-middleware';

const AcquisitionFeeUpFrontField = () => {
    const dispatch = useDispatch();
    const checked = useSelector(offerSelectors.getAcqFeeUpFrontOverrideWithFallback);
    // istanbul ignore next
    const onChange = (e: any) => {
        dispatch(
            offerActionCreators.updateAcqFeeUpFrontOverride(
                e.target.checked === undefined ? (e as any).domEvent.target.checked : e.target.checked
            ) as unknown as RSAAAction<any, unknown, unknown>
        );
    };
    return (
        <CheckBox
            data-testid={kebabCase(ACQUISITION_FEE_ABBR)}
            id={kebabCase(ACQUISITION_FEE_ABBR)}
            label={ACQUISITION_FEE_OPTION_LABEL_INTERSTATE}
            name={kebabCase(ACQUISITION_FEE_ABBR)}
            onChange={onChange}
            checked={checked}
            value="1"
        />
    );
};

export default AcquisitionFeeUpFrontField;
