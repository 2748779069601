import { FC, useEffect, useState } from 'react';
import { LoadingIndicator } from '@interstate/components/LoadingIndicator';
import { CenteredLoadingIndicator } from './Loading.style';

export const LOADING_STATUS = [
    'Loading deal structure',
    'Loading deal versions',
    'Loading lenders',
    'Loading Deal Jacket',
    'Checking incentives',
    'Loading vehicle info',
    'Loading customer info',
    'Loading buying signals'
];

export const LoadingPage: FC<{ timeout?: number }> = ({ timeout }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const id = setInterval(() => {
            setIndex((old) => {
                if (old === LOADING_STATUS.length - 1) {
                    return 0;
                }
                return old + 1;
            });
        }, timeout);

        return () => {
            clearInterval(id);
        };
    }, []);

    return (
        <CenteredLoadingIndicator data-testid="loading-indicator">
            <LoadingIndicator color="gray" size="default" text={LOADING_STATUS[index]} />
        </CenteredLoadingIndicator>
    );
};

LoadingPage.defaultProps = {
    timeout: 2000
};
