import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { MobileView as InterstateMobileView } from './RebatesLayoutViews.interstate';
import { offerReduxSelectors, offerSelectors, transformIncentiveAmount } from '@makemydeal/dr-dash-store';
import { RebatesTotals } from './RebatesTotals.interstate';
import { useSelector } from 'react-redux';
import { Table, TableColumn } from '@interstate-104/components/Table';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { getIncentiveDisplayType } from './utils';

export const RebatesView: FC<any> = () => {
    const largerThanSM = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });

    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives);
    const dealerCashTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForDealerCash);
    const currentIncentivesTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const term = useSelector(offerSelectors.getSelectedTermMonths);

    if (!incentives?.length) return <span data-testid="manual-rebates-no-data">No rebates added.</span>;

    const displayFriendlyIncentivesData = incentives.map((incentive) => {
        return {
            program: incentive.program,
            name: incentive.name,
            code: incentive.code,
            type: getIncentiveDisplayType(incentive),
            amount: formatDollarsAndCents(transformIncentiveAmount(term, incentive))
        };
    });

    const columns: TableColumn[] = [
        {
            title: 'Program #',
            dataIndex: 'program'
        },
        {
            title: 'Incentive Name',
            dataIndex: 'name'
        },
        {
            title: 'Code',
            dataIndex: 'code'
        },
        {
            title: 'Type',
            dataIndex: 'type'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            align: 'right'
        }
    ];

    return (
        <>
            {!largerThanSM && <InterstateMobileView data={incentives} />}
            {largerThanSM && (
                <Table data-testid="incentives-table" dataDensity="small" columns={columns} data={displayFriendlyIncentivesData} />
            )}

            <RebatesTotals
                currentAppliedIncentiveTotal={currentIncentivesTotal}
                showEditTotals={true}
                dealerCashTotal={dealerCashTotal}
                currentEditedIncentivesTotal={currentIncentivesTotal}
                showRebatesTotal={false}
            />
        </>
    );
};
