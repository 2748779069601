// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import axios from 'axios';
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { PRINT_PDF } from '../actionTypes/printPdfActionTypes';

// utils
import { getPrintPayload } from '../selectors/printPdfSelectors';

// interfaces/types
import { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { getCurrentAxiosRequestInterceptor } from '@makemydeal/dr-shared-network';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => async (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
        case PRINT_PDF: {
            const locHref = locHrefUtil.getLocHref();
            const axiosRequestInterceptor = getCurrentAxiosRequestInterceptor();
            const endpoint = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, `/generatePdf`).url;

            const { headers } = await axiosRequestInterceptor({
                url: endpoint
            });

            const pdfPayload = getPrintPayload(state);

            try {
                const response = await axios.post(endpoint, pdfPayload, {
                    headers: {
                        Authorization: headers.Authorization
                    }
                });

                window.open(response.data.data.url, '_blank')?.focus();
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(`Error calling pdf dash bff endpoint: ${err}`);
            }

            break;
        }
    }
};
