import { useSelector } from 'react-redux';

import { CASH } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CardContent, CardHeader, Card as InterstateCard } from '@interstate-104/components/Card';
import { LoadingSectionParent } from '@makemydeal/dr-dash-components';
import { VehicleProtectionCardProps } from './VehicleProtectionCardProps';
import { VehicleProtectionPreviewTable } from './VehicleProtectionPreviewTable';
import { Action } from '@interstate-104/components/Action';
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import { VppPreviewSummaryContainer, VppPreviewSummaryRow } from './VehicleProtectionCardUI.interstate.style';

export const VehicleProtectionCardUIInterstate = ({
    actionLabel,
    hideContent,
    monthlyPayment,
    onActionClick,
    totalPrice
}: VehicleProtectionCardProps) => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isFinanceOrLease = !(currentOfferType === CASH);

    const content = (
        <CardContent>
            <VehicleProtectionPreviewTable />

            <VppPreviewSummaryContainer>
                <VppPreviewSummaryRow>
                    <Typography variant="body-sm">Total</Typography>
                    <Typography tag="span" variant="strong-sm" data-testid="vpp-card-total-cost">
                        {totalPrice}
                    </Typography>
                </VppPreviewSummaryRow>
                {isFinanceOrLease && (
                    <VppPreviewSummaryRow>
                        <Typography variant="body-sm">Monthly Payment</Typography>
                        <Typography tag="span" variant="strong-sm" data-testid="vpp-card-monthly-payment">
                            {monthlyPayment}
                        </Typography>
                    </VppPreviewSummaryRow>
                )}
            </VppPreviewSummaryContainer>
        </CardContent>
    );

    return (
        <LoadingSectionParent>
            <InterstateCard
                data-testid="vpp-preview"
                header={
                    <Grid container justifyContent={'space-between'}>
                        <CardHeader title="Vehicle Protection" />
                        <div>
                            <Action onClick={onActionClick} data-testid="vpp-preview-edit-link">
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    {actionLabel}
                                </Typography>
                            </Action>
                        </div>
                    </Grid>
                }
                content={hideContent ? null : content}
            />
        </LoadingSectionParent>
    );
};
