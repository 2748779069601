// externals
import { FC } from 'react';

// libraries
import { TextInput } from '@interstate-104/components/TextInput';

// types
import { CellFinanceDetailsProps } from './types';

// consts/enums
import { NA } from '../common/taxesAndFeesConsts';

export const FinanceCodeCell: FC<CellFinanceDetailsProps> = ({ detail }) => {
    return (
        <TextInput
            name="Code"
            label="Code"
            className="edit-fee--code-cell"
            required
            disabled
            data-testid={`edit-fee--code-cell-${detail.code}`}
            value={detail.code == null ? NA : `${detail.code}`}
        />
    );
};
