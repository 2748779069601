import { Grid } from '@interstate-104/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { MAX_ACTIVITY_CONTAINER_WIDTH } from '@makemydeal/dr-dash-components';
import styled from 'styled-components';

export const InterstateDealActivityContainer = styled(Grid)`
    && {
        padding: 40px 16px;
    }

    @media (max-width: ${MAX_ACTIVITY_CONTAINER_WIDTH}) {
        && {
            display: none;
        }
    }
`;

export const DealWrapper = styled(Grid)`
    @media (min-width: ${BreakPoint.TABLET_PORTRAIT}) {
        max-width: 320px;
        margin-bottom: 16px;
    }
`;

export const DealDashboardContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const HeaderRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0 0;
`;
