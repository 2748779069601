// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { tradeInSelectors, navigationActionTypes, dealXgDetailsSelectors } from '@makemydeal/dr-dash-store';
import { ACTIVITY_NAME_TRADE_IN } from '@makemydeal/dr-dpm-client';

// components
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'tradeIn';

const TradeCard = () => {
    const state = useSelector((state: StateTree) => state);
    const dispatch = useDispatch();
    const tradeNetValue = useSelector(tradeInSelectors.getTradeNetValue);
    const isTradeActivityEnabledAfterBootstrap = useSelector(featureToggleSelectors.isTradeActivityEnabledAfterBootstrap);
    const completed = useSelector(tradeInSelectors.isTradeInCompleted);
    const cardValue = tradeNetValue ? formatDollarsAndCents(tradeNetValue) : '$0.00';
    const tradeDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_TRADE_IN);

    return (
        <DealActivityCard
            dpmState={tradeDpmState}
            name={name}
            showCardAnchor={true}
            cardValue={cardValue}
            completed={completed}
            action={() => {
                if (isTradeActivityEnabledAfterBootstrap) {
                    dispatch({ type: navigationActionTypes.DASH_START_TRADE_ACTIVITY });
                }
            }}
        />
    );
};

export default TradeCard;
