// externals
import { Reducer } from 'redux';

// libraries
import { CalculationDetailsState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type CalculationDetailsReducer = Reducer<CalculationDetailsState>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducer: CalculationDetailsReducer = (state: CalculationDetailsState = {}, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.calculationDetails
            };
        }
        default: {
            return state;
        }
    }
};
