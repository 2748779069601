// libraries
import { FSA } from '@makemydeal/dr-platform-shared';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { getDiffPropsFromObjects } from '@makemydeal/dr-common-utils';

import { SOCKET_EVENT_RECEIVED, SOCKET_EVENT_IGNORED } from '../actionTypes/socketActionTypes';
import { PERSONA_WHITE_LIST } from '../constants';
import { PUSH_TO_DMS_WS_EVENT } from '../actionTypes/pushToDmsActionTypes';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export type EventReceived = FSA<typeof SOCKET_EVENT_RECEIVED | typeof SOCKET_EVENT_IGNORED | typeof PUSH_TO_DMS_WS_EVENT, string>;

export const dispatchEvent = (state: StateTree, event: any): EventReceived => {
    const persona = event.data?.changedBy?.persona;

    const enableOptionalPbcUi = featureToggleSelectors.enableOptionalPbcUi(state);

    if (enableOptionalPbcUi && event.topic === 'mv_pushToDms' && !event.message?.includes('subscribe')) {
        if (event.data?._type === 'pushToDmsEvent') {
            return { type: PUSH_TO_DMS_WS_EVENT, payload: event };
        }
    }

    const personaValidated = PERSONA_WHITE_LIST.includes(persona?.toLowerCase());

    if (personaValidated) {
        const activityMilestones = state.activityMilestones;
        event.updatedMilestones = getDiffPropsFromObjects(event.data.dealProgressMilestones, activityMilestones);
        return { type: SOCKET_EVENT_RECEIVED, payload: event };
    }
    return { type: SOCKET_EVENT_IGNORED, payload: event };
};
