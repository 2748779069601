// external
import styled from 'styled-components';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const ACCESSORY_NAME_CLASSNAME = 'accessory-name-cell';
export const MANUAL_LEGEND_CLASSNAME = 'manual-accessory-legend';
export const MANUAL_ROW_CLASSNAME = 'manual-accessory-row';
export const TABLE_ROW_CLASSNAME = 'accessory-table-row';

export const AccessoryActionIconContainer = styled.div`
    margin-top: 1.7rem;
`;

export const AccessoryFieldsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);

    .text-input-name {
        grid-column: span 2;
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${BreakPoint.PHONE}) {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &.lease-layout {
        grid-template-columns: repeat(4, 1fr);

        .text-input-name {
            grid-column: span 3;
        }

        @media (max-width: ${BreakPoint.SMALL}) {
            grid-template-columns: repeat(2, 1fr);

            .text-input-name {
                grid-column: span 2;
            }
        }

        @media (max-width: ${BreakPoint.PHONE}) {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }
`;

export const AccessoriesTotal = styled.div`
    display: flex;
    width: 200px;
    align-items: baseline;
    justify-content: space-between;
`;

export const AccessoriesTotalRow = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: space-between;
    margin: 1.1rem 1.1rem 1.1rem 0;

    @media (max-width: ${BreakPoint.SMALL}) {
        margin: 1.1rem 0;
    }
`;

export const AccessoryUpfrontWeOweContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: end;

    padding-left: 1rem;

    grid-column: span 2;

    @media (max-width: ${BreakPoint.SMALL}) {
        grid-column: auto;
    }

    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 1rem 0 0 0;
    }

    &.lease-layout {
        @media (max-width: ${BreakPoint.SMALL}) {
            padding: 1rem 0 0 0;
        }

        @media (max-width: ${BreakPoint.PHONE}) {
            align-items: start;
        }
    }
`;

export const AddAccessoryActionContainer = styled.div`
    padding-bottom: 1rem;
    border-bottom: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
    white-space: pre;
`;

export const ManualAccessoriesErrorContainer = styled.div<{ opened: boolean }>`
    transition: ${({ opened }) => (opened ? 'max-height 0.5s 0.5s;' : 'none')};
    max-height: ${({ opened }) => (opened ? '300px' : 0)};
    overflow: hidden;
`;

export const ManualAccessoriesButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;

    @media (min-width: ${BreakPoint.SMALL}) {
        flex-direction: row;
    }
`;

export const ManualAccessoriesFormContainer = styled.div`
    border-top: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
    padding: 1rem 0;

    &.first-accessory-form {
        border-top: none;
    }
`;

export const ManualAccessoriesFormRowContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const OptionalActionLinkContainer = styled.span`
    margin-right: 0.5rem;
`;

export const StyledAccessoriesTable = styled.div`
    .ant-table-tbody > tr.${MANUAL_ROW_CLASSNAME} {
        td:first-of-type {
            border-left: ${({ theme }) => `${theme.tokens.BaseSizeBorderMd} solid ${theme.tokens.BaseColorGold500}`};
            padding-left: ${({ theme }) =>
                `calc(${theme.tokens.ComponentTableCellPaddingSmVertical} - ${theme.tokens.BaseSizeBorderMd})`};
        }
    }

    div.${MANUAL_LEGEND_CLASSNAME} {
        border-left: ${({ theme }) => `${theme.tokens.BaseSizeBorderMd} solid ${theme.tokens.BaseColorGold500}`};
        padding: 0 0.6rem;
    }

    td div.${ACCESSORY_NAME_CLASSNAME} {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        white-space: normal;
        overflow: hidden;
    }
`;
