import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

import { Box } from '@interstate-104/components/Box';
import { Typography } from '@interstate-104/components/Typography';

import TaxItemSummaryDetailed from './TaxItemSummaryDetailed';
import TaxItemSummarySimple from './TaxItemSummarySimple';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { hasTaxBreakdown } from './helpers';
import { InterstateOnChangeEvent } from '@interstate-104/components/InterstateEvents';
import { TextInputEventValue } from '@interstate-104/components/TextInput';
import { actionCreators } from '@makemydeal/dr-offer-redux';
import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { LoadingSectionParent, PaymentLoading } from '@makemydeal/dr-dash-components';

const TaxItemSummary: React.FC<{ taxItem: paymentServicesTypes.TaxItem }> = ({ taxItem }) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const handleChange = (event: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => {
        const newRate = parseFloat(event.target.value);
        const taxBreakdown = taxItem.taxBreakdown as Record<string, any>;
        /* istanbul ignore next */
        if (taxBreakdown?.[keyName] === newRate || (!taxBreakdown && taxItem.taxRate === newRate)) {
            return;
        }
        const updatedTaxItem = {
            ...taxItem,
            taxBreakdown: {
                ...taxItem.taxBreakdown,
                [keyName]: newRate
            },
            manualChanges: {
                ...taxItem.manualChanges,
                [keyName]: true
            },
            isManualTax: true
        };
        const action = actionCreators.updateTaxItems([updatedTaxItem], offerType, true);
        dispatch(action);
    };

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="tax-items-loading-indicator" />
            <Box data-testid={`individualTax-${taxItem.taxName}`} sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Typography variant="body-md" color="base.color.black">
                    {taxItem.taxName}
                </Typography>
                <Box>
                    {hasTaxBreakdown(taxItem) ? (
                        <TaxItemSummaryDetailed taxItem={taxItem} key={taxItem.taxName} handleChange={handleChange} />
                    ) : (
                        <TaxItemSummarySimple taxItem={taxItem} handleChange={handleChange} />
                    )}
                </Box>
                <Box display="flex" justifyContent="flex-end" marginTop={1.5} paddingRight={1.8}>
                    <span>{`Total: ${formatDollarsAndCents(taxItem.taxAmount)}`}</span>
                </Box>
            </Box>
        </LoadingSectionParent>
    );
};

export default TaxItemSummary;
