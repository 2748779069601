import styled from 'styled-components';

export const PrintLinkAnchor = styled.a`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.tokens.BaseColorBlue700};
    font-weight: 500;
    font-size: 1rem;
`;
