import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';

// constants
import { DRIVE_LINE, DriveLineEnums, DriveLineOptions } from '../../../constants';

// components
import { Typography } from '@interstate-104/components/Typography';
import { SelectInput } from '@interstate-104/components/SelectInput';

// styling
import { DetailContainer } from '../trade.style';

interface DriveLineFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const getDriveLineShortName = (name: DriveLineEnums) => DriveLineOptions.find((item) => item.value === name)?.label;

export const DriveLineField: React.FC<DriveLineFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const driveLine = useSelector(tradeInSelectors.getDriveLine);

    const onChange = (event: any) => {
        dispatch(tradeInActionCreators.updateTradeInDriveLine(event.target.value));
    };

    return (
        <>
            {isManualFormOpen ? (
                <SelectInput
                    options={DriveLineOptions}
                    placeholder="Select"
                    displayDeselectOption={true}
                    label={DRIVE_LINE}
                    data-testid={kebabCase(DRIVE_LINE)}
                    name={kebabCase(DRIVE_LINE)}
                    onChange={onChange}
                    value={driveLine}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {DRIVE_LINE}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-driveline-value-label">
                                {getDriveLineShortName(driveLine as DriveLineEnums)}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};
