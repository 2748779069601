import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography/Typography/Typography';
import { kebabCase } from 'lodash';

export type IRowProps = {
    label: string;
    value: string;
};

export const ItemRow = ({ label, value }: IRowProps) => {
    return (
        <Box className="rowItem">
            <Typography variant="body-md" color="base.color.gray.600" data-testid="vehicle-drawer-label">
                {label}
            </Typography>
            <Typography data-testid={`vehicle-drawer-value-${kebabCase(label)}`} variant="body-md" className="vehicleInfoValue">
                {value}
            </Typography>
        </Box>
    );
};
