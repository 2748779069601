// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { offerSessionUtils, queryParamStore } from '@makemydeal/dr-shared-ui-utils';
import { featureToggleSelectors, initActionTypes, selectedOfferSelectors } from '@makemydeal/dr-shared-store';

// consts/enums
import { OFFER_SAVE_FAILURE, OFFER_SAVE_NOCHANGE_REQUEST, OFFER_SAVE_SUCCESS, SAVE_OFFER } from '../actionTypes/offerActionTypes';
import { DASH_MENU_LIFECYCLE_INIT_FINISH } from '../actionTypes/vehicleProtectionActionTypes';
import { INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT } from '../actionTypes/internalPrevOfferActionTypes';
import { BuildPayloadPurpose } from '../types/offerPayloadUtilsEnums';

// actions
import { buildOfferSavePayloadObject, offerSaveNoChangeSuccess, smartOfferSave } from '../actions/offerActionCreators';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { deskingActionTypes } from '../actionTypes';

// utils
import { savedOfferPayloadRegistry } from '../utils/savedOfferPayloadRegistry';
import { getDealXgId } from '../selectors/offerRedux';
import {
    UpdateInternalPrevOfferAfterInitAction,
    internalPatchPrevOfferPayloadAftermarketItems,
    internalUpdatePrevOfferFullPayload,
    updatePrevOfferAfterInit
} from '../actions/internalPrevOfferActionCreators';

const { BOOTSTRAP_SUCCESS, INIT_PENCIL_SUCCESS, INIT_SUCCESS } = initActionTypes;

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
        // just for potential backwards compatibility with the other activities from SP
        case INIT_PENCIL_SUCCESS: {
            let resetPerformed = false;
            if (savedOfferPayloadRegistry.isResetAllowed()) {
                savedOfferPayloadRegistry.resetState();
                resetPerformed = true;
            }
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                console.log(
                    `DASH DEBUG: "Smart Offer Save", resetting Deal XG ID and Version in Save Offer Registry ` +
                        `(reset: ${resetPerformed})`
                );
            }
            const selectedOffer = action.payload.offer || {};
            const { commonConsumerId: commonConsumerIdFromOffer, commonOrgId: commonOrgIdFromOffer } = selectedOffer;
            const commonConsumerId = commonConsumerIdFromOffer || offerSessionUtils.getCommonConsumerId();
            const commonOrgId = commonOrgIdFromOffer || offerSessionUtils.getCommonOrgId();
            if (commonConsumerIdFromOffer) {
                offerSessionUtils.setCommonConsumerId(selectedOffer.commonConsumerId);
            }
            if (commonOrgIdFromOffer) {
                offerSessionUtils.setCommonOrgId(selectedOffer.commonOrgId);
            }

            action.payload.offer = { ...action.payload.offer, commonOrgId, commonConsumerId };

            store.dispatch({ type: INIT_SUCCESS, payload: action.payload });

            break;
        }
        case BOOTSTRAP_SUCCESS: {
            if (featureToggleSelectors.isSmartInitOfferSaveForDashEnabled(state)) {
                const dealXgId = selectedOfferSelectors.getSelectedOfferDealXgId(state);
                if (dealXgId) {
                    store.dispatch(updatePrevOfferAfterInit(dealXgId));
                }
            }
            break;
        }
        case INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT: {
            const actionTyped = action as UpdateInternalPrevOfferAfterInitAction;
            const offerSavePayload = buildOfferSavePayloadObject(state, BuildPayloadPurpose.AppInit);
            const dealXgId = actionTyped.payload?.dealXgId || '';
            const dealXgVersion = offerSavePayload?.offer?.dealExchangeVersionId || '';
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                // eslint-disable-next-line no-console
                console.log(
                    `DASH DEBUG: "Smart Offer Save" (INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT): Deal XG ID = ${dealXgId}, Version = ${dealXgVersion}`
                );
            }
            store.dispatch(internalUpdatePrevOfferFullPayload(dealXgId, dealXgVersion, offerSavePayload));
            break;
        }
        case DASH_MENU_LIFECYCLE_INIT_FINISH: {
            if (featureToggleSelectors.isSmartInitOfferSaveForDashEnabled(state)) {
                const offerSavePayload = buildOfferSavePayloadObject(state, BuildPayloadPurpose.MenuInit);
                const dealXgId = getDealXgId(state);
                if (dealXgId) {
                    store.dispatch(
                        internalPatchPrevOfferPayloadAftermarketItems(
                            offerSavePayload?.menu?.menuProducts,
                            offerSavePayload?.accessories
                        )
                    );
                }
            }
            break;
        }

        case SAVE_OFFER: {
            const state = store.getState();
            const purpose =
                action.meta?.originalAction === deskingActionTypes.CONTINUE_IN_DESKING
                    ? BuildPayloadPurpose.ContinueInDesking
                    : BuildPayloadPurpose.OfferSave;
            const saveOfferAction = smartOfferSave(state, undefined, purpose);
            store.dispatch(saveOfferAction);
            break;
        }

        // ensure the sessionStorage is up to date with the latest dealXgVersion
        case OFFER_SAVE_SUCCESS: {
            const { dealXgId, dealXgVersion } = action.payload;
            const offerSavePayloadIndex = action.meta.offerSavePayloadIndex;
            const offerSavePayload = savedOfferPayloadRegistry.dequeueOfferPayloadObject(offerSavePayloadIndex);
            store.dispatch(internalUpdatePrevOfferFullPayload(dealXgId, dealXgVersion, offerSavePayload));
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                console.log(
                    `DASH DEBUG: "Smart Offer Save" (OFFER_SAVE_SUCCESS): Deal XG ID = ${dealXgId}, Version = ${dealXgVersion}`
                );
            }
            break;
        }

        case OFFER_SAVE_NOCHANGE_REQUEST: {
            const dealXgId = action.payload.dealXgId;
            const dealXgVersion = action.payload.dealXgVersion;
            const offerSaveSource = action.meta?.source;
            setTimeout(() => {
                store.dispatch(offerSaveNoChangeSuccess(dealXgId, dealXgVersion, offerSaveSource));
            }, 1000);
            break;
        }

        case OFFER_SAVE_FAILURE: {
            const offerSavePayloadIndex = action.meta?.offerSavePayloadIndex;
            savedOfferPayloadRegistry.dequeueOfferPayloadObject(offerSavePayloadIndex);
            break;
        }

        default:
            break;
    }
};
