// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, DealState } from '@makemydeal/dr-dash-types';
import { offerSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const SellingPrice = ({ deal, hasDelta = false }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const state = useDealRootState(deal);

    const offerPrice = offerSelectors.getSellingPrice(state);
    const sellingPrice = formatDollarsAndCents(offerPrice);

    return <DealSummaryItem label={constants.SELLING_PRICE} value={sellingPrice} hasDelta={hasDelta} />;
};

export default SellingPrice;
