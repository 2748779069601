import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextArea } from '@interstate-104/components/TextArea';
import { Typography } from '@interstate-104/components/Typography';
import { CardContent, CardHeader, Card as InterstateCard } from '@interstate-104/components/Card';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { LoadingSectionParent } from '@makemydeal/dr-dash-components';

export const OfferCommentsInterstate = () => {
    const [comment, setComment] = React.useState('');
    const dispatch = useDispatch();
    const isEnableDealerCommentsEnabled = useSelector(featureToggleSelectors.isEnableDealerCommentsEnabled);
    const handleCommentsChanged = (e: any) => {
        setComment(e.target.value);
        dispatch(offerReduxActionCreators.updatedShopperMessage(e.target.value));
        if (isEnableDealerCommentsEnabled) {
            dispatch(offerReduxActionCreators.updatedDealerMessage(e.target.value));
        }
    };

    return (
        <LoadingSectionParent>
            <InterstateCard
                data-testid="offer-notes-container"
                header={
                    <>
                        <CardHeader title="Offer Notes" data-testid="offer-comments-title" />
                        <Typography variant="body-md" tag="p">
                            Notes will appear in the offer history and will be visible on Accelerate and Sales View
                        </Typography>
                    </>
                }
                content={
                    <CardContent>
                        <TextArea
                            label=""
                            name="comment"
                            displayLabel={false}
                            value={comment}
                            placeholder="Add comments"
                            rows={5}
                            onChange={handleCommentsChanged}
                            data-testid="offer-comments"
                        />
                    </CardContent>
                }
            />
        </LoadingSectionParent>
    );
};

export default OfferCommentsInterstate;
