import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const RowGrid = styled.div`
    display: grid;
    gap: 0.5rem;
    align-items: start;
    grid-template-areas:
        'trashcan program name name'
        '. code type amount';
    margin-bottom: 0;

    @media (min-width: ${BreakPoint.SMALL}) {
        grid-template-areas: none;
        grid-template-columns: fit-content(5%) fit-content(12%) 1fr fit-content(15%) 1fr fit-content(10%);
        margin-bottom: 16px;
    }
`;

export const ManualRebatesFormContainer = styled.div`
    .text-input-program {
        grid-area: program;

        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    .text-input-name {
        grid-area: name;

        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    .text-input-code {
        grid-area: code;

        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    .text-input-amount {
        grid-area: amount;
        min-width: 100px;
        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    hr {
        bg-color: #cacfd9;
        width: 100%;
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        hr {
            display: none;
        }

        .text-input-amount input {
            text-align: right;
        }
    }
`;

export const FlexManualRebatesButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

export const ManualRebatesTypeDisplayContainer = styled.div`
    .type-display {
        color: #6d727a;
        &:after {
            color: #dd2b2b;
            content: '*';
            display: inline-block;
            margin-left: 0.25rem;
        }
    }
`;

export const RebateDeleteOrUndoContainer = styled.div`
    grid-area: trashcan;
    display: grid;

    @media (min-width: ${BreakPoint.SMALL}) {
        grid-area: unset;
    }
`;

export const TypeInputOrDisplayContainer = styled.div`
    grid-area: type;

    @media (min-width: ${BreakPoint.SMALL}) {
        grid-area: unset;
    }
`;

export const EditableRowsPlusAddButtonContainer = styled.div`
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #cacfd9;
`;

export const AddIncentiveContainer = styled.div`
    margin: 16px 0 8px 0;
`;
