import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// types
import { SendDialogTypes } from '@makemydeal/dr-dash-types';

// components
import { ModalDialog } from '@interstate/components/ModalDialog';
import { LoadingIndicator } from '@interstate/components/LoadingIndicator';
import { offerActionCreators, offerInfoSelectors } from '@makemydeal/dr-dash-store';
import { Alert } from '@interstate/components/Alert';
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import SendToCustomerDialogInterstate from '../sendToCustomerDialog/SendToCustomerDialog.interstate';

// styles
import { AlertContainerInterstate } from './ShareToCustomerDialog.style';
import { ShareToCustomerDialogMode } from './ShareToCustomerDialogMode';
import { ShareToCustomerDialogProps } from './ShareToCustomerDialogProps';

export const ShareToCustomerDialogInterstate = ({ show, onHide, header, dialogPrompt, prefix }: ShareToCustomerDialogProps) => {
    const dispatch = useDispatch();
    const [mode, setMode] = useState(ShareToCustomerDialogMode.SAVING);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const shopperURL = useSelector(offerInfoSelectors.getShopperURL);

    // Set mode when offer save status changes
    React.useEffect(() => {
        if (show) {
            if (offerIsBeingSaved === true) {
                return setMode(ShareToCustomerDialogMode.SAVING);
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === true) {
                setMode(ShareToCustomerDialogMode.SUCCESS);
                return;
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === false) {
                setMode(ShareToCustomerDialogMode.ERROR);
                return;
            }
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRetryClick = () => {
        dispatch(offerActionCreators.saveOffer());
    };

    switch (mode) {
        case ShareToCustomerDialogMode.SAVING:
            return (
                <ModalDialog
                    show={show}
                    size="large"
                    data-testid={`${prefix}-dialog-saving`}
                    onHide={onHide}
                    footer={false}
                    header={
                        <Typography variant="h3" color="base.color.blue.900">
                            {header}
                        </Typography>
                    }
                >
                    <LoadingIndicator text="We're updating your changes to this offer." size="small" />
                </ModalDialog>
            );

        case ShareToCustomerDialogMode.ERROR:
            return (
                <ModalDialog
                    show={show}
                    size="large"
                    data-testid={`${prefix}-dialog-error`}
                    header={
                        <Typography variant="h3" color="base.color.blue.900">
                            {header}
                        </Typography>
                    }
                    footer={
                        <Button
                            buttonStyle="primary"
                            data-testid={`${prefix}-retry-button`}
                            onClick={handleRetryClick}
                            size="small"
                        >
                            Retry
                        </Button>
                    }
                    onHide={onHide}
                >
                    {/* TODO: [US1141319] Remove AlertContainerInterstate once interstate fixes the modal padding issue: i.e. it give everything inside of it padding by default */}
                    <AlertContainerInterstate data-testid={`${prefix}-alert-container`}>
                        <Alert
                            role="offerupdatefailedalert"
                            type="error"
                            sx={{
                                marginBottom: '16px'
                            }}
                        >
                            <Typography variant="body-md" tag="span">
                                Offer update failed.
                            </Typography>
                        </Alert>
                        <Typography variant="body-md" tag="div">
                            <div>We ran into a problem updating this offer so the information wasn't shared. Please try again.</div>
                            <br />
                            <div>If the problem persists, please contact support:</div>
                            <div>1-888-895-2994 or create a support ticket.</div>
                        </Typography>
                    </AlertContainerInterstate>
                </ModalDialog>
            );

        case ShareToCustomerDialogMode.SUCCESS:
            return (
                <SendToCustomerDialogInterstate
                    shareUrl={shopperURL}
                    dialogHeader={header}
                    dialogPrompt={dialogPrompt ?? ''}
                    show={show}
                    onHide={onHide}
                    dialogType={SendDialogTypes.Offer}
                />
            );
    }
};
