import React from 'react';
import { useSelector } from 'react-redux';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import TaxCategory from './TaxCategory';
import { categorizeTaxes } from './helpers';
import { CAPITALIZED_TAXES_CATEGORY, MONTHLY_USE_TAXES_CATEGORY, UPFRONT_TAXES_CATEGORY } from './../constants';

const ItemizedLeaseSummary = ({ data }: { data: paymentServicesTypes.TaxItems }) => {
    const isTaxCapped = useSelector(offerReduxSelectors.isTaxCapped);

    const { capitalizedTaxes, monthlyUseTaxes, upfrontTaxes } = categorizeTaxes(data, !isTaxCapped);

    return (
        <>
            <TaxCategory
                categoryTitle={CAPITALIZED_TAXES_CATEGORY}
                taxItems={capitalizedTaxes}
                dataTestId="category_capitalized_taxes"
            />
            <TaxCategory
                categoryTitle={MONTHLY_USE_TAXES_CATEGORY}
                taxItems={monthlyUseTaxes}
                dataTestId="category_monthly_use_taxes"
            />
            <TaxCategory categoryTitle={UPFRONT_TAXES_CATEGORY} taxItems={upfrontTaxes} dataTestId="category_upfront_taxes" />
        </>
    );
};

export default ItemizedLeaseSummary;
