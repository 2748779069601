import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const InterstateOfferCardsContainer = styled(Grid)`
    overflow-x: hidden;

    & div.offer-card {
        margin: 0;
    }
`;

export const LoadingIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 21.05rem;
    @media (max-width: 1024px) {
        min-height: 20.45rem;
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const DealLifeCycleContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
`;
