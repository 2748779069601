import { BreakPoint } from '@makemydeal/dr-activities-common';
import { FlexCol } from '../../common/Layout.style';

import styled from 'styled-components';

export const StyledTextWithToggleGroupContainer = styled.div`
    display: flex;
    align-items: end;
    gap: 5px;
    @media (max-width: ${BreakPoint.SMALL}) {
        gap: 1px;
    }
`;

export const StyledTextWithToggleGroupText = styled(FlexCol)`
    input,
    fieldset,
    div {
        width: auto;
        min-width: auto !important; // override min-width of TextInput
    }
    width: 75%;

    @media (max-width: ${BreakPoint.SMALL}) {
        width: 70%;
        div {
            width: auto;
            min-width: auto !important; // override min-width of TextInput
        }
    }
`;

export const StyledTextWithToggleGroupToggle = styled(FlexCol)`
    margin-left: 0.5rem;
    width: 35%;
    button.MuiToggleButtonGroup-grouped:not(:first-of-type) {
        border: 1px solid #b2b2b2;
    }
    @media (max-width: ${BreakPoint.SMALL}) {
        #dollarToggle,
        #percentToggle {
            min-width: 10px;
        }
    }
`;
