// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// externals
import { Action, compose } from 'redux';

// utils
import { reducerRegistry } from './reducerRegistry';

let _store: any;

export function getStore() {
    return _store;
}

export function setStore(store: any) {
    _store = store;
}

export function addMiddleware(middleware: any) {
    const store = {
        getState: _store.getState,
        dispatch: (action: Action) => _store.dispatch(action)
    };
    _store.dispatch = compose(middleware(store))(_store.dispatch);
}

export function addRemainingMiddleware(middleware: any[]) {
    middleware.forEach((item) => {
        addMiddleware(item);
    });
}

export function addRemainingReducers(reducers: any) {
    reducerRegistry.registerMultiple(reducers);
}
