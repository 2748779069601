import React from 'react';

import { TableHead as InterstateTableHead, TableRow, TableCell } from '@interstate-104/components/SimpleTable';
import { StyledTableCell } from './TaxCard.style';

export type SimpleTableHeadColumn = {
    heading: string;
    options?: {
        align?: string;
    };
};

export type SimpleTableHeadProps = {
    columns: SimpleTableHeadColumn[];
};

export const TableHead: React.FC<SimpleTableHeadProps> = ({ columns }) => (
    <InterstateTableHead>
        <TableRow>
            {columns.map(({ heading, options }, index) => (
                <TableCell key={`head-cell-${index}`}>
                    <StyledTableCell textAlign={options?.align}>{heading}</StyledTableCell>
                </TableCell>
            ))}
        </TableRow>
    </InterstateTableHead>
);

export default TableHead;
