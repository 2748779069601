// externals
import OktaAuth from '@okta/okta-auth-js';

// utils
import { setOktaAuthForFetchInterceptor, setupAuthFetchIntercept } from './fetchIntercept';
import { setOktaAuthForAxiosInterceptor, setupAuthAxiosIntercept } from './axiosIntercept';

let authOkta: OktaAuth;

/**
 * Register Fetch and Axios intercepts to add auth headers for all client network requests.
 * @returns void
 */
export const setupAuthNetworkIntercepts = (appPrefix: string) => {
    setupAuthFetchIntercept(appPrefix);
    setupAuthAxiosIntercept(appPrefix);
};

export const setOktaAuthForInterceptors = (oktaAuth: OktaAuth) => {
    setOktaAuthForFetchInterceptor(oktaAuth);
    setOktaAuthForAxiosInterceptor(oktaAuth);
    authOkta = oktaAuth;
};

export const getOktaToken = () => {
    return authOkta && authOkta.getAccessToken ? authOkta.getAccessToken() : undefined;
};
