// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { formatDollars } from '@makemydeal/dr-common-utils';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { menuShopperActivity, navigationActionTypes, dealXgDetailsSelectors } from '@makemydeal/dr-dash-store';
import { ACTIVITY_NAME_VEHICLE_PROTECTION } from '@makemydeal/dr-dpm-client';

// components
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'vehicleProtection';

const MenuActivityVehicleProtectionCard = () => {
    const dispatch = useDispatch();

    const menuTotal = useSelector(menuShopperActivity.menuSelectors.getListTotalPrice);
    const selectedProducts = useSelector(menuShopperActivity.menuSelectors.getSelectedProtectionList);
    const isMenuActivityEnabledAfterBootstrap = useSelector(featureToggleSelectors.isMenuActivityEnabledAfterBootstrap);

    const completed = selectedProducts.length > 0;
    const cardValue = formatDollars(menuTotal);
    const vehicleProtectionDpmState = useSelector((state: StateTree) =>
        dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_VEHICLE_PROTECTION)
    );

    return (
        <DealActivityCard
            dpmState={vehicleProtectionDpmState}
            name={name}
            cardValue={cardValue}
            completed={completed}
            showCardAnchor={completed}
            action={() => {
                if (isMenuActivityEnabledAfterBootstrap) {
                    dispatch({ type: navigationActionTypes.DASH_START_MENU_ACTIVITY });
                }
            }}
        />
    );
};

export default MenuActivityVehicleProtectionCard;
