import React from 'react';
import { TruncateText } from '@interstate/components/TruncateText';
import { ChatBubbleLeftIcon } from '@interstate/components/Icons/ChatBubbleLeftIcon';
import { TruncatedMessageContainer, OfferCommentContainer } from './OfferMessage.style.interstate';
import { Typography } from '@interstate/components/Typography';

export interface IOfferMessageProps {
    shopperMessage?: string;
    dealerMessage?: string;
    offerSource?: string;
}

export const OfferMessage: React.FC<IOfferMessageProps> = ({ shopperMessage, dealerMessage, offerSource }: IOfferMessageProps) => {
    const isOfferSourceDealer = offerSource?.toLowerCase() === 'dealmaker';

    const getMessage = () => (isOfferSourceDealer ? dealerMessage : shopperMessage);

    const messageLength = getMessage()?.length || 0;
    const shouldTruncate = messageLength > 38;

    return (
        <OfferCommentContainer className="comment-container" data-testid="offer-message">
            {getMessage() ? (
                <>
                    <ChatBubbleLeftIcon data-testid="chat-icon" className="chat-icon" />
                    <TruncatedMessageContainer>
                        <Typography variant="body-xs" tag="span">
                            <TruncateText
                                ellipsis='..."'
                                text={`"${getMessage()} `}
                                displayTruncatedText={!shouldTruncate}
                                displayToolTip={shouldTruncate}
                            />
                            {!shouldTruncate && '"'}
                        </Typography>
                    </TruncatedMessageContainer>
                </>
            ) : (
                '--'
            )}
        </OfferCommentContainer>
    );
};
