/**
 * PURPOSE: Contains logic related to the application bootstrap process.
 */

// libraries
import { buildBootstrapMiddleware, DashMiddleware, GetRemainingReducers } from '@makemydeal/dr-shared-store';
import { offerSelectors } from '@makemydeal/dr-dash-store';
import { StateTree } from '@makemydeal/dr-dash-types';

// utils
import { getRemainingReducers } from '../reducers/remainingReducers';

// constants
import { APP_NAME } from '../../../constants';

export const internalBuildBootstrapMiddleware = (getRemainingReducers: GetRemainingReducers) => {
    return buildBootstrapMiddleware<StateTree>({
        getRemainingReducers,
        getDocumentTitle: (state) => {
            const lastName = offerSelectors.getShopperLastName(state);
            if (lastName) {
                return `${APP_NAME} - ${lastName}`;
            }
            return '';
        }
    });
};

export const bootstrapMiddleware: DashMiddleware = internalBuildBootstrapMiddleware(getRemainingReducers);
