// externals
import { FC } from 'react';

// libraries
import { ArrowUturnLeftIcon, TrashIcon } from '@interstate-104/components/Icons';
import { Button } from '@interstate-104/components/Button';

// styles
import { AccessoryActionIconContainer } from '../../ManualAccessories.interstate.styles';

export type ManualAccessoryActionIconProps = {
    deleted: boolean;
    index: number;
    restoreAccessory: (index: number) => void;
    removeAccessory: (index: number) => void;
};

export const ManualAccessoryActionIcon: FC<ManualAccessoryActionIconProps> = ({
    deleted,
    index,
    restoreAccessory,
    removeAccessory
}) => (
    <AccessoryActionIconContainer>
        {deleted ? (
            <Button
                buttonStyle="secondary"
                icon={<ArrowUturnLeftIcon />}
                id={`manual-accessories-restore-icon-${index}`}
                data-testid={`manual-accessories-restore-icon-${index}`}
                onClick={() => restoreAccessory(index)}
            />
        ) : (
            <Button
                buttonStyle="secondary"
                id={`manual-accessories-trash-icon-${index}`}
                data-testid={`manual-accessories-trash-icon-${index}`}
                icon={<TrashIcon />}
                onClick={() => removeAccessory(index)}
            />
        )}
    </AccessoryActionIconContainer>
);
