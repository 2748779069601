// externals
import { FC, useMemo, useState } from 'react';

// libraries
import { TextInput } from '@interstate-104/components/TextInput';
import { customInterstateValidationRules as rules } from '@makemydeal/dr-dash-components';

// interfaces/types
import {
    ColumnMapping,
    ValidateInterstateValidationRulesHandler,
    InputChangeInterstateEventHandler
} from '../manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../../constants';

export type ManualAccessoryNameInputStateProps = {
    deleted: boolean;
    index: number;
    name: string;
    nameColumnMapping: ColumnMapping<string>;
};

export type ManualAccessoryNameInputDispatchProps = {
    handleInputChange: InputChangeInterstateEventHandler;
    validateRules: ValidateInterstateValidationRulesHandler;
};

export const ACCESSORY_NAME_MAX_LENGTH = 256;

export type ManualAccessoryNameInputProps = ManualAccessoryNameInputDispatchProps & ManualAccessoryNameInputStateProps;

const getValidationRules = () => {
    const required = rules.getRequiredValidationRule(deskingConstants.REQUIRED);
    const maxLength = rules.getMaxLengthValidationRule(
        ACCESSORY_NAME_MAX_LENGTH,
        `Value should be less than ${ACCESSORY_NAME_MAX_LENGTH} symbols.`
    );

    return [required, maxLength];
};

export const ManualAccessoryNameInput: FC<ManualAccessoryNameInputProps> = ({
    deleted,
    index,
    name,
    nameColumnMapping: { fieldName },
    handleInputChange,
    validateRules
}) => {
    const [isNameTouched, setIsNameTouched] = useState(false);

    const validationRules = useMemo(getValidationRules, []);

    return (
        <TextInput
            autoFocus={!name.length}
            displayLabel={true}
            className={`text-input-${fieldName}`}
            data-testid={`${fieldName}-${index}`}
            label={deskingConstants.NAME}
            name={`${fieldName}-${index}`}
            onChange={(e) => {
                handleInputChange(e, fieldName);
            }}
            onFocus={() => {
                setIsNameTouched(true);
            }}
            placeholder=""
            value={name}
            getError={(_label, value) => {
                const isInitialValueEmpty = !isNameTouched && name === '';
                return validateRules(value, validationRules, fieldName, isInitialValueEmpty);
            }}
            required
            disabled={deleted}
        />
    );
};
