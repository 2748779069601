import React from 'react';
import { Action } from '@interstate/components/Action';
import { navigationActionCreators } from '@makemydeal/dr-dash-store';
import { useDispatch } from 'react-redux';
import { Typography } from '@interstate-104/components/Typography';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';

const DigitalDealJacketLink = () => {
    const dispatch = useDispatch();
    return (
        <>
            <h3
                style={{
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '120%',
                    textTransform: 'capitalize',
                    marginBottom: '0px'
                }}
            >
                Digital Deal Jacket
            </h3>
            <Action
                data-testid="digital-deal-jacket-link"
                sx={{ textAlign: 'left' }}
                onClick={() => dispatch(navigationActionCreators.navigateToDDJ())}
            >
                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                    {'Manage Documents'}
                    <ChevronRightIcon fontSize={10} />
                </Typography>
            </Action>
        </>
    );
};

export default DigitalDealJacketLink;
