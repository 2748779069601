// externals
import { FC } from 'react';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography/Typography/Typography';

// components
import { ShowWithFeatureToggle } from '../../utils/ShowWithFeatureToggle';
import { FormPriceInput } from '../formManager/FormPriceInput';
import { FormSelectInput } from '../formManager/FormSelectInput';

// consts/enums
import { WHOLESALE_BOOK_SOURCE_OPTIONS } from '../../constants';

// utils
import { bookOutValueValidator, pricingInputList } from '../formManager/helper';

const PricingForm: FC = () => {
    return (
        <Grid container spacing={2} paddingBottom={1}>
            <Grid xs={12}>
                <Typography variant="h5">Pricing</Typography>
            </Grid>
            {pricingInputList.map(({ name, label }) => (
                <Grid key={name} xs={6}>
                    <FormPriceInput name={name} label={label} />
                </Grid>
            ))}
            <ShowWithFeatureToggle name="useEnhancedTradeInMV">
                <Grid key="wholesaleValue" xs={6}>
                    <div data-testid="wholesaleValue-root">
                        <FormPriceInput name="wholesaleValue" label="Bookout Value" validator={bookOutValueValidator} />
                    </div>
                </Grid>

                <Grid key="wholesaleBookSource" xs={6}>
                    <FormSelectInput
                        name="wholesaleBookSource"
                        label="Bookout Source"
                        errorMessage="Required"
                        options={WHOLESALE_BOOK_SOURCE_OPTIONS}
                        maxHeight="250px"
                        placeholder="Select"
                    />
                </Grid>
            </ShowWithFeatureToggle>
        </Grid>
    );
};

export default PricingForm;
