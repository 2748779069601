// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInput } from '@interstate/components/SelectInput';
import { CREDIT_DECISION_STATUS } from '../../constants';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { offerReduxActionCreators } from '@makemydeal/dr-dash-store';

const CreditDecisionStatusField = () => {
    const dispatch = useDispatch();
    const toggled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const value = useSelector(offerReduxSelectors.getCreditDecisionStatus);
    const options = ['None', 'Approved', 'ConditionallyApproved', 'Declined', 'Pending'].map((t) => ({ value: t, label: t }));

    return (
        <SelectInput
            data-testid={'lender-decision-status'}
            htmlId="CreditDecisionStatusField"
            label={CREDIT_DECISION_STATUS}
            name={CREDIT_DECISION_STATUS}
            onChange={(event) => {
                const action = offerReduxActionCreators.updateCreditDecisionStatus(event.target.value as string);
                dispatch(action);
            }}
            value={value}
            disabled={!toggled}
            options={options}
            displayDeselectOption={false}
        />
    );
};

export default CreditDecisionStatusField;
