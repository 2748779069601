// externals
import { Reducer } from 'redux';

// libraries
import type { ConsumerInsightsState } from '@makemydeal/dr-dash-types';

// consts/enums
import { CONSUMER_INSIGHTS_FETCH_SUCCESS } from '../actionTypes/consumerInsightsActionTypes';

export type ConsumerInsightsReducer = Reducer<ConsumerInsightsState>;

export const reducer: ConsumerInsightsReducer = (state: ConsumerInsightsState = {}, action: any) => {
    switch (action.type) {
        case CONSUMER_INSIGHTS_FETCH_SUCCESS: {
            return action.payload.data.insights;
        }
        default: {
            return state;
        }
    }
};
