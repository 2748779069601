// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Modal } from '@interstate-104/components/Modal';
import { Grid } from '@interstate-104/components/Grid';
import { Button } from '@interstate-104/components/Button';
import { Alert } from '@interstate-104/components/Alert';
import { DocumentDuplicateIcon } from '@interstate/components/Icons/DocumentDuplicateIcon';
import { PushToDmsError } from './PushToDmsError.interstate';
import { Typography } from '@interstate-104/components/Typography';

// styling
import './pushToDmsDialog.css';

// constants
import { ALERT_SUCCESS_MESSAGE, DONE_BUTTON_TEXT, CLOSE_BUTTON_TEXT, DIALOG_HEADER_INTERSTATE } from '../pushToDmsConstants';

// libraries
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { pushToDmsActionCreators } from '@makemydeal/dr-dash-store';

// types
import { PushToDmsStatus } from '../pushToDmsTypes';

// selectors
import { pushToDmsSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { FooterGrid } from './PushToDmsDialog.style';

export interface IPushToDmsDialogProps {
    show: boolean;
    status: string;
    onHide(): void;
    onDone(): void;
}

const PushToDmsDialog = (props: IPushToDmsDialogProps) => {
    const { show, status, onHide, onDone } = props;

    const dispatch = useDispatch();

    const prefix = 'push-to-dms-dialog';

    const pushToDmsErrorRequest = useSelector(pushToDmsSelectors.getPushToDmsError);
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);
    const cdmsResponseStatusCode = useSelector(pushToDmsSelectors.getCdmsResponseStatusCode);
    const cdmsErrorMessage = useSelector(pushToDmsSelectors.getCdmsResponseErrorMessage);
    const vehicleStockNumber = useSelector(vehicleSelectors.getVehicleStockNumber);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(vehicleStockNumber);
        dispatch(pushToDmsActionCreators.copyDmsStockNumber(vehicleStockNumber));
    };

    const header = (
        <Typography data-testid={`${prefix}-header`} variant="h4" tag="h4" color="sem.color.on-surface.primary">
            {DIALOG_HEADER_INTERSTATE}
        </Typography>
    );
    const footer =
        status === PushToDmsStatus.Failure ? (
            <FooterGrid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button
                    id={`${prefix}-close-button`}
                    data-testid={`${prefix}-close-button`}
                    buttonStyle="secondary"
                    onClick={onDone}
                >
                    {CLOSE_BUTTON_TEXT}
                </Button>
            </FooterGrid>
        ) : (
            <FooterGrid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button id={`${prefix}-done-button`} data-testid={`${prefix}-done-button`} buttonStyle="primary" onClick={onDone}>
                    {DONE_BUTTON_TEXT}
                </Button>
            </FooterGrid>
        );

    return (
        <Modal
            size="large"
            data-testid={prefix}
            show={show}
            header={header}
            footer={{ footerComponent: footer }}
            onHide={onHide}
            sx={{
                '.MuiDialog-paper': {
                    width: 'auto'
                }
            }}
        >
            {status === PushToDmsStatus.Failure ? (
                <PushToDmsError
                    statusCode={returnedSuccessfulCdmsResponse ? pushToDmsErrorRequest.statusCode : cdmsResponseStatusCode}
                    errorDetails={returnedSuccessfulCdmsResponse ? pushToDmsErrorRequest.errorDetails : cdmsErrorMessage}
                />
            ) : (
                <Grid container flexDirection="column" paddingX={3} gap={2} width="320px">
                    <Alert id={`${prefix}-alert`} type="success" disableSuccessFade>
                        {ALERT_SUCCESS_MESSAGE}
                    </Alert>
                    <Grid container flexWrap="nowrap" alignItems="center" gap={1}>
                        <Typography
                            data-testid={`${prefix}-stock-number-input`}
                            variant="body-sm"
                            tag="p"
                            color="sem.color.on-surface.primary"
                        >
                            {vehicleStockNumber}
                        </Typography>

                        <Button
                            data-testid={`${prefix}-copy-button`}
                            buttonStyle="secondary"
                            onClick={handleCopyClick}
                            size="medium"
                        >
                            <DocumentDuplicateIcon id="IconNoteExample" />
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default PushToDmsDialog;
