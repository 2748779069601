import {
    dealXgDetailsSelectors,
    offerActionCreators,
    offerInfoSelectors,
    shopperNotificationsSelectors,
    testDriveSelectors
} from '@makemydeal/dr-dash-store';
import { SendDialogTypes, StateTree } from '@makemydeal/dr-dash-types';
import { ACTIVITY_NAME_TEST_DRIVE } from '@makemydeal/dr-dpm-client';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerUI } from '../../customerUI/useCustomerUI';
import SendToCustomerDialogInterstate from '../../sendToCustomerDialog/SendToCustomerDialog.interstate';
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'testDrive';

const TestDriveCard = () => {
    const state = useSelector((state: StateTree) => state);
    const isTestDriveCompleted = useSelector(testDriveSelectors.isTestDriveComplete);
    const cardValue = useSelector(testDriveSelectors.getDDPTestDriveStatusText);
    const notifications = shopperNotificationsSelectors.getShopperNotificationsByActivity(state, 'testDrive');
    const [showSendTestDrive, setShowSendTestDrive] = React.useState(false);
    const testDriveURL = useSelector(offerInfoSelectors.getTestDriveURL);
    const testDriveDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_TEST_DRIVE);
    const crmIntegrationToggle = useSelector(dealerSelectors.getCrmIntegrationToggle);
    const { showCustomerCard } = useCustomerUI();
    const dispatch = useDispatch();

    // istanbul ignore next # this is invoked WAY down the pipeline and out of scope here. We just want to delay getting hte URL until the url loads asynchronously
    const urlSelector = (state: any) => {
        const url = offerInfoSelectors.getTestDriveURL(state);
        return `\n${url}`;
    };

    const handleShowTestDriveModal = () => {
        dispatch(offerActionCreators.saveOffer());
        if (crmIntegrationToggle) {
            showCustomerCard(true, { tab: 'inbox', messageContentSelector: urlSelector, waitForOfferSave: true });
        } else {
            setShowSendTestDrive(true);
        }
    };

    const handleHideTestDriveModal = () => {
        setShowSendTestDrive(false);
    };

    const getLinkText = () => {
        let linkText;
        if (notifications.length) {
            linkText = 'Resend Link';
        } else if (!isTestDriveCompleted) {
            linkText = 'Send Link';
        }
        return linkText;
    };

    return (
        <div>
            <DealActivityCard
                dpmState={testDriveDpmState}
                name={name}
                cardValue={cardValue}
                completed={isTestDriveCompleted}
                action={handleShowTestDriveModal}
                linkText={getLinkText()}
                data-testid="testDrive-link"
            />

            <SendToCustomerDialogInterstate
                shareUrl={testDriveURL}
                dialogHeader="Send Test Drive to Customer"
                dialogPrompt="Send the customer a direct link to schedule a test drive"
                data-testid="send-to-customer-dialog"
                show={showSendTestDrive}
                onHide={handleHideTestDriveModal}
                dialogType={SendDialogTypes.TestDrive}
            />
        </div>
    );
};

export default TestDriveCard;
