import { PrinterIcon } from '@interstate/components/Icons';
import { PrintLinkAnchor } from './PrintOfferLink.styles';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { useDispatch } from 'react-redux';
import { printPdfActionCreator } from '@makemydeal/dr-dash-store';

export const PrintOfferLink = () => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(printPdfActionCreator.printPdf());
    };

    return (
        <PrintLinkAnchor data-testid="print-offer" theme={theme} onClick={handleClick}>
            <PrinterIcon />
            Print
        </PrintLinkAnchor>
    );
};
