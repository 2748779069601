import React from 'react';

// externals
import { useDispatch, useSelector } from 'react-redux';
import { SelectInputOption } from '@interstate-104/components/SelectInput';

// components
import { SelectInput } from './TradeInSelectInput';

// libraries
import { tradeInSelectors, tradeInComponentSelectors, tradeInActionCreators } from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// constants
import { TRADE_IN_TRIM } from '@makemydeal/dr-dash-components';

interface TrimDecodedFieldProps {
    isManualFormOpen: boolean;
}

export const TrimDecodedField: React.FC<TrimDecodedFieldProps> = ({ isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);
    const trimDecodedOptions = useSelector(tradeInSelectors.getTradeTrimDecodedOptions);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);

    const getTradeInTrimsDecoded = (): SelectInputOption[] =>
        trimDecodedOptions.map((trim) => ({ label: trim.name, value: trim.name })).sort();

    const onTrimDecodedChange = (event: any) => {
        const [trim] = trimDecodedOptions.filter((trim) => trim.name === event.target.value);
        updateTrimSelector(trim);
    };

    const updateTrimSelector = (trim: { name: string; id: string }) => {
        dispatch(tradeInActionCreators.updateTradeInTrim(trim));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                trim
            })
        );
    };

    return (
        <>
            {isManualFormOpen && (
                <SelectInput
                    id="trade-trim-decoded-select-input"
                    data-testid="trade-trim-decoded-select-input"
                    label={TRADE_IN_TRIM}
                    name={TRADE_IN_TRIM}
                    onChange={onTrimDecodedChange}
                    options={getTradeInTrimsDecoded()}
                    value={vehicleTrim}
                    placeholder="Select"
                    required
                    displayDeselectOption={false}
                />
            )}
        </>
    );
};

export default TrimDecodedField;
