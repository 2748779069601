import styled from 'styled-components';
import CXCard from '@cx/ui/Card';

export const StyledCXCard = styled(CXCard)<{ hasError?: boolean }>`
    display: flex;
    flex: 1;
    margin-bottom: 0;

    border-color: ${({ hasError }) => (hasError ? 'rgb(228, 73, 65)' : '#cacfd9')};

    .panel-body {
        flex-direction: column;
        flex: 1;
        flex-wrap: wrap;
    }
`;
export const CardTitle = styled.h5`
    margin: 0;
    line-height: 32px;
`;
export const CardLink = styled.button`
    padding: 0.5rem;
    border: 0;
    color: ${({ theme }) => theme.linkColor};
`;
export const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const CardLineItemRow = styled.div`
    margin-bottom: 0.75rem;
`;
export const CardSubsectionHeader = styled.h4`
    text-transform: capitalize;
    margin-bottom: 1rem;
`;

export const CardSubtitle = styled.h4`
    color: gray;
`;
