// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState } from '@makemydeal/dr-dash-types';
import { offerSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const MSRP = ({ deal }: DealState) => {
    const state = useDealRootState(deal);

    const retailPrice = offerSelectors.getRetailPriceOverrideWithFallback(state);
    const msrp = formatDollarsAndCents(retailPrice);

    return <DealSummaryItem label={constants.MSRP} value={msrp} />;
};

export default MSRP;
