import { SlideOut } from '@interstate-104/components/SlideOut';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { CommonConsumer } from '@makemydeal/dr-platform-types';
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { useOktaAuth } from '@okta/okta-react';
import { FC, Suspense, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomerEditModule } from './CustomerEditModule';
import { FindOrCreateModule } from './FindOrCreateModule';
import { COMMON_CONSUMER_REQUIRED_FIELDS, CUSTOMER_REQUIRED_FIELDS_FOR_PBC } from './types/CustomerSideBarRequiredFields';
import { CustomerSearchProps } from './types/CustomerSearchProps';

type CustomerSearchState = {
    consumer?: CommonConsumer;
    showCustomer: boolean;
};

const isNullOrEmpty = (value: string | undefined) => {
    if (value == null) return true;
    return value.trim().length === 0;
};

export const CustomerSearch: FC<CustomerSearchProps> = ({ show, setShow, onCustomerSelected, commonOrgId }) => {
    const [searchState, setSearchState] = useState<CustomerSearchState>({ showCustomer: false });
    const isLargerThanSmall = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const viewportMode = isLargerThanSmall ? 'desktop' : 'mobile';

    const environment = offerSessionUtils.getEnvironmentType();
    const oktaContext = useOktaAuth();
    const handleCloseSlider = () => {
        setShow(false);
        resetSearchState();
    };

    const resetSearchState = () => {
        setSearchState((_) => ({ showCustomer: false, customer: undefined }));
    };

    const getJwtToken = () => oktaContext?.oktaAuth.getAccessToken() || '';

    const localHandleCustomerSelected = (consumer?: CommonConsumer) => {
        if (consumer == null) return handleCloseSlider(); // edit was cancelled; just close the slide out
        if (COMMON_CONSUMER_REQUIRED_FIELDS.some((field) => isNullOrEmpty(consumer[field]))) {
            // NOTE: search or edit finished with an updated consumer; but the consumer is still missing fields.
            setSearchState({ consumer: consumer, showCustomer: true });
            return;
        }
        resetSearchState(); // onhide doesn't call when edit customer saved

        // NOTE: happy path, consumer is complete. Pass it along to the consuming component.
        onCustomerSelected(consumer);
    };

    return (
        <SlideOut
            show={show}
            header={''}
            data-testid="customer-search-drawer"
            position="right"
            panelWidth="auto"
            sx={{
                '.MuiPaper-root': {
                    width: { xs: 1, md: 584 }
                },
                '.interstate-close-sliding-drawer-button ': {
                    margin: '1rem'
                },
                '.interstate-sliding-drawer-dialog-title-container': {
                    'padding-top': 0,
                    'padding-bottom': 0
                },
                '.MuiDialogContent-root': {
                    'padding-bottom': '1rem'
                },
                '#sliding-drawer-footer-actions': {
                    display: 'none'
                }
            }}
            onHide={handleCloseSlider}
        >
            <Suspense fallback={<div>Loading...</div>}>
                {!searchState.showCustomer && (
                    <FindOrCreateModule
                        onFindOrCreate={localHandleCustomerSelected}
                        getAuthOverride={getJwtToken}
                        environment={environment}
                        dealershipId={commonOrgId} // NOTE: use "CoOrgTestId1" for debugging locally.
                        viewportMode={viewportMode}
                        requiredFields={CUSTOMER_REQUIRED_FIELDS_FOR_PBC}
                    />
                )}

                {/* NOTE: FYI: with state props bound together; showCustomer will never be true when consumer is null. */}
                {searchState.showCustomer && searchState.consumer != null && (
                    <CustomerEditModule
                        commonConsumer={searchState.consumer}
                        environment={environment}
                        getAuthOverride={getJwtToken}
                        didSaveOrDiscard={localHandleCustomerSelected}
                        requiredFields={CUSTOMER_REQUIRED_FIELDS_FOR_PBC}
                    />
                )}
            </Suspense>
        </SlideOut>
    );
};
