export enum StatusLabel {
    Submitted = 'Submitted',
    vehicleReserved = 'Vehicle Reserved'
}

export type Activities =
    | 'accessories'
    | 'acceptDeal'
    | 'creditApp'
    | 'docUpload'
    | 'finalizeDeal'
    | 'incentives'
    | 'reservation'
    | 'testDrive'
    | 'tradeIn'
    | 'vehicleProtection'
    | 'vppActivityVehicleProtection';

export const IconMappings: Record<Activities, string> = {
    accessories: '',
    creditApp: 'houseDollarSign',
    incentives: 'piggyBank',
    tradeIn: 'carDollarSign',
    vehicleProtection: 'handHoldingCar',
    vppActivityVehicleProtection: 'handHoldingCar',
    testDrive: 'calendarPlusSign',
    docUpload: 'upload',
    acceptDeal: 'carCheckMark',
    reservation: 'deposit',
    finalizeDeal: ''
};

export const LabelMappings: Record<Activities, string> = {
    accessories: 'Accessories',
    acceptDeal: 'Finish Your Deal',
    creditApp: 'Credit App',
    docUpload: 'Upload Docs',
    finalizeDeal: 'Contract & Finalize',
    incentives: 'Incentives',
    reservation: 'Reserve Vehicle',
    testDrive: 'Test Drive',
    tradeIn: 'Value Trade-In',
    vehicleProtection: 'Vehicle Protection',
    vppActivityVehicleProtection: 'Vehicle Protection'
};
