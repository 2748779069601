import { VehicleImageContent } from './Style';
import svg from '../assets/vehicleSkeleton.svg';
import { FC, useState } from 'react';

export type VehicleImageProps = {
    imageUrl: string;
};

export const VehicleImage: FC<VehicleImageProps> = ({ imageUrl }) => {
    const [ready, setReady] = useState(false);

    return (
        <VehicleImageContent marginBottom={4} marginTop={4}>
            {(!ready || !imageUrl) && (
                <img src={svg} width="100%" alt={'Vehicle image'} data-testid="vehicle-drawer-image-skeleton" />
            )}
            {imageUrl && (
                <img
                    src={imageUrl}
                    width="100%"
                    alt={'Vehicle image'}
                    data-testid="vehicle-drawer-vehicle-image"
                    onLoad={() => setReady(true)}
                    onError={() => setReady(false)}
                    style={ready ? undefined : { display: 'none' }}
                />
            )}
        </VehicleImageContent>
    );
};
