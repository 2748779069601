/**
 * Build an action that is picked up by the analytics middleware.
 * @param type typical redux action type
 * @param description description to be used downstream in the analytics system
 * @returns a redux action
 * @remarks This was named `getAnalyticsAction` in Shopper Platform.
 */
export const buildAnalyticsAction = (type: string, description: string) => {
    return {
        type,
        meta: {
            middleware: {
                analytics: {
                    name: description
                }
            }
        }
    };
};
