import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';
import { MAX_ACTIVITY_CONTAINER_WIDTH } from '../constants';

export const UpdateDealBannerWrapper = styled.div`
    display: flex;
    padding: 1.2rem 1rem;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-bottom: 1rem;
    width: ${MAX_ACTIVITY_CONTAINER_WIDTH};

    @media only screen and (max-width: 1200px) {
        padding-left: 2rem;
        width: 970px;
    }
    @media only screen and (max-width: ${BreakPoint.MEDIUM}) {
        width: 830px;
        padding: 1.2rem 0 1.2rem 5rem;
    }
    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        padding: 1.2rem 0 1.2rem 0;
        width: auto;
        margin: 0 1rem;
    }
`;
export const UpdateDealBannerContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid #ced2dc;
    border-radius: 4px;
`;
export const LogoContainer = styled.div`
    display: flex;
    width: 3rem;
    background-color: #ffbb33;

    .iconDealUpdateBanner {
        width: 100%;
        margin-top: 0.7rem;
        transform: scale(1.2, 1.2);
    }
`;

export const InfoContainer = styled.div`
    padding: 0.8rem;
    width: 100%;
`;
