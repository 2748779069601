// components
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { Badge } from '@interstate/components/Badge';
import { VehicleImage } from './VehicleImage';
import { Action } from '@interstate/components/Action';

// styles
import { VehicleListItemContainer, VehicleListImageContainer, VehicleListItemContent } from './VehicleListItem.interstate.style';

// types
import { IVehicle } from '@makemydeal/dr-platform-types';
import { CatalogVehicle } from '../../types';
import { useVehicleTitle } from '../../utils';

export type VehicleListItemProps = {
    vehicle: CatalogVehicle;
    onBuildVehicleClick: (vehicle: IVehicle) => void;
};

export const VehicleListItemNotInStock = ({ vehicle, onBuildVehicleClick }: VehicleListItemProps) => {
    const { vin = '' } = vehicle;

    const vehicleListItemTitle = useVehicleTitle(vehicle);

    return (
        <VehicleListItemContainer
            data-testid={`vehicle-list-item-${vin}`}
            content={
                <VehicleListItemContent>
                    <VehicleListImageContainer>
                        <VehicleImage vehicle={vehicle} />
                    </VehicleListImageContainer>
                    <div>
                        <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                            {vehicleListItemTitle}{' '}
                            <Badge variant="info" data-testid="isNotInStockBadge">
                                <Typography variant="body-sm" tag="div">
                                    Not in-stock
                                </Typography>
                            </Badge>
                        </Typography>
                        <Typography variant="body-md" tag="div">
                            {vin}
                        </Typography>
                        <Action data-testid="build-vehicle-link" onClick={() => onBuildVehicleClick(vehicle)}>
                            <span style={{ fontSize: '16px' }}>Build Vehicle</span>
                        </Action>
                    </div>
                </VehicleListItemContent>
            }
        />
    );
};
