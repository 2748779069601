// externals
import React from 'react';

// libraries
import { navigationHooks } from '@makemydeal/dr-dash-store';

// components
import Icon from '../common/Icon';

import { ChevronLeftIcon } from '@interstate/components/Icons/ChevronLeftIcon';
import { Button } from '@interstate/components/Button';

const BackToMyDealButton = () => {
    // TODO: do we need a lib for navigation constants like routes?
    const handleBackButtonClick = navigationHooks.useNavigate('/dashboard');
    return (
        <Button
            buttonStyle="aux"
            id="MyButton"
            onClick={handleBackButtonClick}
            size="small"
            startIcon={<ChevronLeftIcon />}
            data-testid="back-to-my-deal"
        >
            Back to my deal
        </Button>
    );
};

export default BackToMyDealButton;
