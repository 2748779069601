import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const StyledUpdateDealLifecycleButton = styled.div`
    display: inline-block;
    margin-left: 10px;
    button {
        min-width: 7em;
    }

    button.customSizeBtn[disabled] {
        opacity: unset;
    }

    svg {
        margin: auto;
    }
    .customSizeBtn {
        width: 50px;
    }
    @media (max-width: ${BreakPoint.PHONE}) {
        button {
            display: block;
            width: 100%;
        }
    }
`;
