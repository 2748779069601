// externals
import React from 'react';

// libraries
import { BaseSelectors } from '@makemydeal/dr-activities-common';

export function withSelectors(WrappedComponent: React.FC<any>, selector: BaseSelectors | undefined) {
    return class extends React.Component {
        render() {
            return selector ? <WrappedComponent selector={selector} /> : null;
        }
    };
}
