// externals
import React from 'react';

// libraries
import { capitalize, isValidNumber } from '@makemydeal/dr-common-utils';
import type { DealHistory, DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import { NOT_AVAILABLE } from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const TermLength = ({ deal, hasDelta = false }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const state = useDealRootState(deal);
    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    const termMonths = offerSelectors.getSelectedTermMonths(state);
    const value = termMonths != null && isValidNumber(termMonths) ? `${termMonths} Months` : NOT_AVAILABLE;
    const label = `${capitalize(offerType)} Term`;

    return <DealSummaryItem label={label} value={value} hasDelta={hasDelta} />;
};

export default TermLength;
