// libraries
import { RollbackState, StateTree } from '@makemydeal/dr-dash-types';
import type { FSA } from '@makemydeal/dr-platform-shared';

// consts/enums
import { PREPARE_STATE_COPY, ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE } from '../actionTypes/rollbackActionTypes';

export type PrepareStateCopyAction = FSA<typeof PREPARE_STATE_COPY, StateTree>;
export type RollbackStateAction = Required<FSA<typeof ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE, RollbackState>>;
export type AllRollbackActions = PrepareStateCopyAction | RollbackStateAction;

export const prepareStateCopy = (appState: StateTree): PrepareStateCopyAction => {
    return {
        type: PREPARE_STATE_COPY,
        payload: appState
    };
};

export const rollbackAppStateForSwitchVehicle = (rollbackState: RollbackState): RollbackStateAction => {
    return {
        type: ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE,
        payload: rollbackState
    };
};
