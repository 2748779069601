// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState } from '@makemydeal/dr-dash-types';
import { offerSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

export type TotalAmountDueProps = DealState & {
    showLargeValue?: boolean;
};

const AmountFinanced = ({ deal, showLargeValue }: TotalAmountDueProps) => {
    const state = useDealRootState(deal);
    const financeTotalAmountDue = offerSelectors.getAmountFinanced(state);

    return (
        <DealSummaryItem
            label={constants.TOTAL_AMOUNT_DUE_FINANCE}
            value={formatDollarsAndCents(financeTotalAmountDue)}
            showLargeValue={showLargeValue}
        />
    );
};

export default AmountFinanced;
