import { creditDecisionActionCreators, creditDecisionSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import {
    CardContainer,
    DescriptionInfo,
    FinancingContainer,
    FinancingTitle,
    LayoutContainer,
    LenderDecisionContainer
} from './LenderDecisionInfo.style';
import { LenderList } from './LenderList';
import { TitleSeparator } from './CreditDecision.style';
import LenderDecisionFooter from './LenderDecisionFooter';

import { useState } from 'react';
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { push } from 'connected-react-router';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { DealerDecisionStatus } from '@makemydeal/dr-dash-types';

const LenderDecisionInfo = () => {
    const dispatch = useDispatch();
    const dealRefId = useSelector(offerSelectors.getDealRefId);
    const lenderDecisions = useSelector(creditDecisionSelectors.getLenderCreditDecisions);
    const sourcePartnerId = useSelector(creditDecisionSelectors.getSourcePartnerId);
    const creditProviderIsRouteOne = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const environment = offerSessionUtils.getEnvironmentType() === 'prod' ? 'prod' : 'uat';

    const [showLenderDecisionFooter, setShowLenderDecisionFooter] = useState(false);
    const [selectedLenderId, setSelectedLenderId] = useState('');
    const [selectedDecision, setSelectedDecision] = useState({});

    const filteredLenderDecisions = lenderDecisions.filter((d) => {
        return d.approvalStatus !== DealerDecisionStatus.NOT_SUBMITTED;
    });

    const showFooter = () => {
        setShowLenderDecisionFooter(!showLenderDecisionFooter);
    };

    const hideFooterAndNavigate = () => {
        setShowLenderDecisionFooter(!showLenderDecisionFooter);
        Array.isArray(filteredLenderDecisions) && filteredLenderDecisions.forEach((lender) => (lender.isActive = false));
        dispatch(push('/dashboard'));
    };

    const applySelectedCreditDecisionAndNavigate = () => {
        dispatch(creditDecisionActionCreators.updateSelectedCreditDecision(selectedDecision));
        dispatch(push('/dashboard'));
    };

    const handleSelectedLenderId = (e: string) => {
        /* istanbul ignore next */
        if (selectedLenderId === e) {
            setSelectedLenderId('');
            setShowLenderDecisionFooter(false);
        } else {
            setSelectedLenderId(e);
            setShowLenderDecisionFooter(true);
            const widget = document.getElementById('ldWidget');
            if (widget && widget.childNodes.length > 0) {
                widget.childNodes[0].remove();
                const creditDecisionWidget = document.createElement('fni-credit-decision-widget');
                creditDecisionWidget.id = 'cd-widget-app';
                creditDecisionWidget.setAttribute('deal-ref-id', dealRefId);
                creditDecisionWidget.setAttribute('source-partner-id', sourcePartnerId);
                creditDecisionWidget.setAttribute('lender-id', e);
                creditDecisionWidget.setAttribute('is-route-one', String(creditProviderIsRouteOne));
                creditDecisionWidget.setAttribute('env', environment);
                creditDecisionWidget.setAttribute('data-testid', 'fni-credit-decision-widget');
                widget.append(creditDecisionWidget);
            }
        }
    };

    return (
        <LenderDecisionContainer data-testid="lender-decision-layout">
            <TitleSeparator />
            <DescriptionInfo>
                <FinancingContainer>
                    <FinancingTitle>Select A Decision Below To View The Detailed Terms</FinancingTitle>
                </FinancingContainer>
            </DescriptionInfo>

            <LayoutContainer>
                <CardContainer>
                    <span className="lenderDecisionTitle">Lender Decisions ({filteredLenderDecisions.length})</span>
                    <LenderList
                        onCardSelected={handleSelectedLenderId}
                        lenderDecisions={filteredLenderDecisions}
                        sortOrder={['approvalStatus']}
                        showFooter={showFooter}
                        setSelectedDecision={setSelectedDecision}
                    />
                </CardContainer>
                {selectedLenderId && (
                    <div className="lenderDecisionWidget" id="ldWidget">
                        <fni-credit-decision-widget
                            id="cd-widget-app"
                            deal-ref-id={dealRefId}
                            source-partner-id={sourcePartnerId}
                            lender-id={selectedLenderId}
                            is-route-one={String(creditProviderIsRouteOne)}
                            env={environment}
                            data-testid="fni-credit-decision-widget"
                        />
                    </div>
                )}
            </LayoutContainer>
            {showLenderDecisionFooter && (
                <LenderDecisionFooter
                    applySelectedCreditDecisionAndNavigate={applySelectedCreditDecisionAndNavigate}
                    hideFooter={hideFooterAndNavigate}
                />
            )}
        </LenderDecisionContainer>
    );
};

export default LenderDecisionInfo;
