// externals
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Button } from '@interstate-104/components/Button';
import { Typography } from '@interstate-104/components/Typography';
import PushToDmsDialog from '../pushToDms/pushToDmsDialog/PushToDmsDialog.interstate';
import CheckoutAnywhereDrawer from './CheckoutAnywhereDrawer';
import PushToRouteOneDialog from '../pushToRoutOne/pushToRouteOneDialog';

// style
import { ButtonsContainerInterstate, Container } from './FinishDealCard.style';

// selectors
import {
    configSelectors,
    offerActionCreators,
    offerInfoSelectors,
    offerReduxSelectors,
    offerSelectors,
    pushToDmsSelectors,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { LabelMappings } from '../DealActivityTypes';
import { PushToDmsStatus } from '../pushToDms/pushToDmsTypes';

// constants
import { apiConfigConsts, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import ConfirmPushToDmsDialogInterstate from '../pushToDms/confirmPushToDmsDialog/ConfirmPushToDmsDialog.interstate';
import { useLastPushToDmsUpdateText, usePushToDmsButtonText } from '../pushToDms/pushToDmsHooks';
import { ShowWithSelector } from '../../utils/ShowWithSelector';
import { UpdateOfferDialogInterstate } from '../../UpdateOfferDialog/UpdateOfferDialog.interstate';

const FinishDealCard = () => {
    const [showConfirmPushToDmsDialog, setConfirmPushToDmsDialog] = useState(false);
    const [showPushToDmsDialog, setShowPushToDmsDialog] = useState(false);
    const [showPushToR1Dialog, setShowPushToR1Dialog] = useState(false);
    const [pushToDmsStatus, setPushToDmsStatus] = useState(PushToDmsStatus.Ready);
    const [hasBeenPushed, setHasBeenPushed] = useState<{ time: string | Date }>({
        time: ''
    });

    const offerSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);
    const toggleFsDealRefId = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const enablePushToR1 = useSelector(featureToggleSelectors.enablePushToR1);
    const isDMSEnabled = useSelector(dealerSelectors.getDMSIntegrationToggle) !== 'OFF';
    const dealRefId = useSelector(offerSelectors.getDealRefId) || offerSessionUtils.getSessionValue(apiConfigConsts.FS_DEAL_REF_ID);
    const vehicleStockNumber = useSelector(vehicleSelectors.getVehicleStockNumber);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const creditProvider = useSelector(dealerSelectors.getCreditProvider);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const sendToDMSText = useLastPushToDmsUpdateText(hasBeenPushed.time);
    const pushToDmsButtonText = usePushToDmsButtonText();

    const enablePushToR1CTA = enablePushToR1 && creditProvider !== 'dealertrack' && offerType !== 'cash';

    const parseUnifiDeepLinkFromConfigUrl = (configUrl: any) => {
        if (configUrl.length > 0) {
            const fsProvider = toggleFsDealRefId ? 'FD' : 'DRS';
            configUrl = `${configUrl}/dealjacket_deal_summary/${fsProvider}/${dealRefId}/`;
        }
        return configUrl;
    };

    const openUniFiDeepLinkUrl = () => {
        const url: string = parseUnifiDeepLinkFromConfigUrl(configUrl);
        window.open(url, '_blank');
    };

    // istanbul ignore next
    const handleConfirmPushToDmsShow = () => {
        setConfirmPushToDmsDialog(true);
    };

    // istanbul ignore next
    const handleConfirmPushToDmsSaved = () => {
        setConfirmPushToDmsDialog(false);
        setShowPushToDmsDialog(true);
        setPushToDmsStatus(PushToDmsStatus.Ready);
        setHasBeenPushed(Object.assign({}, { ...hasBeenPushed }, { time: new Date() }));
    };

    // istanbul ignore next
    const handleConfirmPushToDmsFailed = () => {
        setConfirmPushToDmsDialog(false);
        setShowPushToDmsDialog(true);
        setPushToDmsStatus(PushToDmsStatus.Failure);
    };

    // istanbul ignore next
    const handleConfirmPushToDmsCancel = () => {
        setConfirmPushToDmsDialog(false);
    };

    // istanbul ignore next
    const handleConfirmPushToDmsHide = () => {
        setConfirmPushToDmsDialog(false);
    };

    // istanbul ignore next
    const handlePushToDmsHide = () => {
        setShowPushToDmsDialog(false);
    };

    // istanbul ignore next
    const handlePushToDmsDone = () => {
        setShowPushToDmsDialog(false);
    };

    // istanbul ignore next
    const handlePushToR1DialogShow = () => {
        setShowPushToR1Dialog(true);
    };

    // istanbul ignore next
    const handleHidePushToR1Dialog = () => {
        setShowPushToR1Dialog(false);
    };

    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    const [isCheckoutAnywhereDrawerEnabled, setIsCheckoutAnywhereDrawerEnabled] = useState(false);
    const dispatch = useDispatch();

    const handleSaveOffer = () => {
        dispatch(offerActionCreators.saveOffer());
        setShowUpdateOffer(true);
    };

    return (
        <Container>
            {/* TODO: US1183743 - NavDrawer Typography */}
            <h3
                style={{
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '120%',
                    textTransform: 'capitalize',
                    padding: '16px 0',
                    margin: 0,
                    color: '#000'
                }}
            >
                {LabelMappings['finalizeDeal']}
            </h3>
            <ButtonsContainerInterstate>
                <ShowWithSelector
                    selector={dealerSelectors.enableCheckoutAnywhereToggle}
                    active={
                        <>
                            <UpdateOfferDialogInterstate
                                show={showUpdateOffer}
                                onHide={() => setShowUpdateOffer(false)}
                                onSuccess={() => setIsCheckoutAnywhereDrawerEnabled(true)}
                            />
                            <Button
                                sx={{ backgroundColor: 'transparent' }}
                                className="interstate"
                                buttonStyle="secondary"
                                size="medium"
                                onClick={handleSaveOffer}
                                data-testid="open-checkout-link"
                                block
                            >
                                Get Checkout Link
                            </Button>
                        </>
                    }
                />
                {isCheckoutAnywhereDrawerEnabled && (
                    <CheckoutAnywhereDrawer
                        setShowUpdateOffer={setShowUpdateOffer}
                        setIsOpenSlider={setIsCheckoutAnywhereDrawerEnabled}
                        isOpenSlider={isCheckoutAnywhereDrawerEnabled}
                    />
                )}

                <Button
                    sx={{ backgroundColor: 'transparent' }} // TODO: Waiting for transparent background on secondary buttons
                    className="interstate"
                    buttonStyle="secondary"
                    size="medium"
                    disabled={!dealRefId}
                    onClick={openUniFiDeepLinkUrl}
                    data-testid="finalizeDeal-link"
                    block
                >
                    Open in F&I
                </Button>
                {isDMSEnabled && (
                    <Button
                        block
                        sx={{ backgroundColor: 'transparent' }} // TODO: Waiting for transparent background on secondary buttons
                        className="interstate"
                        buttonStyle="secondary"
                        data-testid="push-to-dms-button"
                        size="medium"
                        onClick={handleConfirmPushToDmsShow}
                    >
                        {pushToDmsButtonText}
                    </Button>
                )}
            </ButtonsContainerInterstate>
            {sendToDMSText && (
                <>
                    <Typography
                        variant="body-sm"
                        color="sem.color.on-surface.subdued"
                        tag="div"
                        sx={{
                            marginTop: '16px'
                        }}
                        data-testid="last-pushed-time-string"
                    >
                        {sendToDMSText}
                    </Typography>
                    <Typography
                        variant="strong-md"
                        color="sem.color.on-surface.default"
                        tag="div"
                        sx={{
                            marginTop: '4px'
                        }}
                    >
                        {offerSaved && returnedSuccessfulCdmsResponse ? `Stock #${vehicleStockNumber}` : ''}
                    </Typography>
                </>
            )}

            {enablePushToR1CTA && (
                <>
                    <Button
                        block
                        sx={{ backgroundColor: 'transparent', marginTop: '16px' }} // TODO: Waiting for transparent background on secondary buttons
                        className="interstate"
                        buttonStyle="secondary"
                        data-testid="push-to-routeone-button"
                        size="medium"
                        onClick={handlePushToR1DialogShow}
                    >
                        Push to Route One
                    </Button>
                    <Typography
                        variant="body-sm"
                        color="sem.color.on-surface.muted"
                        tag="div"
                        sx={{
                            marginTop: '10px'
                        }}
                        data-testid="sent-to-routeone-label"
                    >
                        Deal sent to <b>RouteOne</b> on
                    </Typography>
                </>
            )}
            <ConfirmPushToDmsDialogInterstate
                show={showConfirmPushToDmsDialog}
                data-testid="confirm-push-to-dms-dialog"
                onSaved={handleConfirmPushToDmsSaved}
                onFailed={handleConfirmPushToDmsFailed}
                onHide={handleConfirmPushToDmsHide}
                onCancel={handleConfirmPushToDmsCancel}
            />
            <PushToDmsDialog
                data-testid="push-to-dms-dialog"
                show={showPushToDmsDialog}
                status={pushToDmsStatus}
                onHide={handlePushToDmsHide}
                onDone={handlePushToDmsDone}
            />
            <PushToRouteOneDialog data-testid="push-to-route-dialog" show={showPushToR1Dialog} onHide={handleHidePushToR1Dialog} />
        </Container>
    );
};

export default FinishDealCard;
