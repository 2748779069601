import React, { useState, useEffect } from 'react';

// components
import { SelectInput } from '@interstate/components/SelectInput';
import { Alert } from '@interstate/components/Alert';
import ActionButton from '../common/ActionButton';
import { Button } from '@interstate/components/Button';

// libraries
import { DealLifecycle } from '@makemydeal/dr-dash-types';
import { dealLifeCycleActionCreators } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate/components/Typography';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// hooks
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// consts/enums
import { SALE, DELIVERED, CONTRACT_SIGNED } from '../constants';

// styles
import { StyledUpdateDealLifecycleButton } from './UpdateDealLifecycleButton.style';
import { ModalDialog } from '@interstate/components/ModalDialog';
import { DatePicker } from '@interstate/components/DatePicker';

type ErrorType = {
    attempts: number;
    titleMessage: string;
    message: (() => JSX.Element) | null;
};

export type DealLifecycleModalProps = {
    show: boolean;
    setShowModal: any;
    dealLifecycle: DealLifecycle;
};

const initialErrorState = {
    attempts: 0,
    titleMessage: '',
    message: null
};

const oneYearAgoFromNow = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

export const DealLifecycleModal = (props: DealLifecycleModalProps) => {
    const { show, setShowModal, dealLifecycle } = props;
    const { updateDealLifecycleStatus } = dealLifecycle;
    const { updateDealLifeCycleData, updateDealLifeCycleReady } = dealLifeCycleActionCreators;
    const dispatch = useDispatch();
    const isSmallScreenSize = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const [dealStatusValue, setDealStatusValue] = React.useState(dealLifecycle.status || 'InProgress');
    const [saleFieldValue, setSaleFieldDate] = React.useState(dealLifecycle.soldDate || '');
    const [contractSignedFieldValue, setContractSignedFieldDate] = React.useState(dealLifecycle.contractSignedDate || '');
    const [deliveredFieldValue, setDeliveredFieldDate] = React.useState(dealLifecycle.deliveredDate || '');
    const [error, setError] = useState<ErrorType>(initialErrorState);

    const isDealStatusLost = dealStatusValue === 'Lost';
    const isSaleDateRequired =
        !isDealStatusLost && saleFieldValue === '' && (contractSignedFieldValue !== '' || deliveredFieldValue !== '');
    const isContractSignedDateRequired =
        !isDealStatusLost && contractSignedFieldValue === '' && saleFieldValue !== '' && deliveredFieldValue !== '';
    const isDeliveredDateRequired = deliveredFieldValue === '' && dealStatusValue === 'Sold';
    const isUpdateButtonDisabled =
        (dealStatusValue !== 'Lost' && saleFieldValue === '') || isContractSignedDateRequired || isDeliveredDateRequired;

    const setDate = (event: any, dateField: string) => {
        const userEnteredDate = event.target.value;
        const checkUserEnteredDate = userEnteredDate.dateValue === undefined ? '' : userEnteredDate.dateValue;
        if (dateField === SALE) {
            setSaleFieldDate(checkUserEnteredDate);
        } else if (dateField === CONTRACT_SIGNED) {
            setContractSignedFieldDate(checkUserEnteredDate);
        } else if (dateField === DELIVERED) {
            setDeliveredFieldDate(checkUserEnteredDate);
        }
    };

    const updateDealLifeCycleDataHandler = () => {
        dispatch(
            updateDealLifeCycleData(MANAGER_VIEW_APP_PREFIX, {
                status: dealStatusValue,
                soldDate: new Date(saleFieldValue),
                contractSignedDate: new Date(contractSignedFieldValue),
                deliveredDate: new Date(deliveredFieldValue)
            })
        );
    };

    const updateDealStatusValue = (event: any) => setDealStatusValue(event.target.value);

    const isFieldEmpty = (fieldValue: string): boolean => {
        return fieldValue === '';
    };

    useEffect(() => {
        if (updateDealLifecycleStatus === 'success') {
            setTimeout(() => {
                setShowModal(false);
            }, 500);

            setError(initialErrorState);
        }

        if (updateDealLifecycleStatus === 'failure') {
            if (error.attempts < 2) {
                setError((prev) => ({
                    attempts: prev.attempts + 1,
                    titleMessage: 'An Error Has Ocurred',
                    message: () => <p>There's a problem updating. Please try again.</p>
                }));
            } else {
                setError((prev) => ({
                    attempts: prev.attempts + 1,
                    titleMessage: 'The System Is Currently Unavailable',
                    message: () => (
                        <p data-testid="third-attempt-error-message">
                            Sorry, the system you're attempting to update is currently unavailable. Please contact Support at{' '}
                            <a href="tel:888-740-2165">888-740-2165</a>
                        </p>
                    )
                }));
            }
        }
    }, [updateDealLifecycleStatus]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(updateDealLifeCycleReady());
        }, 500);
    }, [error]);

    useEffect(() => {
        if (show) {
            setError(initialErrorState);
            dispatch(updateDealLifeCycleReady());
        }
    }, [show]);

    return (
        <div data-testid="dr-dash-deal-status-modal">
            <div data-testid="deal-status-modal">
                <ModalDialog
                    show={show}
                    footer={
                        <>
                            <Button size="small" buttonStyle="secondary" htmlId="cancel-btn" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>

                            <StyledUpdateDealLifecycleButton>
                                <ActionButton
                                    status={updateDealLifecycleStatus}
                                    prefix="update-btn"
                                    readyPrefix="ready-button"
                                    successAction="email-sent"
                                    onAction={updateDealLifeCycleDataHandler}
                                    actionText="Update"
                                    loadingText="Updating..."
                                    disabled={isUpdateButtonDisabled || error.attempts > 2}
                                />
                            </StyledUpdateDealLifecycleButton>
                        </>
                    }
                    header={
                        <Typography variant="h3" color="base.color.blue.900" data-testid="deal-status-modal-header">
                            Manage This Deal's Progress
                        </Typography>
                    }
                    onHide={() => setShowModal(false)}
                    size={!isSmallScreenSize ? 'largeOnXS' : 'large'}
                >
                    {error.attempts >= 1 && (
                        <div data-testid="update-dealLifecycle-error">
                            <Alert role="alert" htmlId="update-dealLifecycle-error" type="warning">
                                <strong>{error.titleMessage}</strong>
                                <br />
                                {error.message && error.message()}
                            </Alert>
                        </div>
                    )}
                    <SelectInput
                        options={[
                            {
                                label: 'In Progress',
                                value: 'InProgress'
                            },
                            {
                                label: 'Lost',
                                value: 'Lost'
                            },
                            {
                                label: 'Sold',
                                value: 'Sold',
                                disabled: isFieldEmpty(deliveredFieldValue)
                            }
                        ]}
                        onChange={updateDealStatusValue}
                        name="change-deal-status"
                        label="Change Deal Status"
                        value={dealStatusValue}
                        displayDeselectOption={false}
                    />

                    <Typography sx={{ margin: '24px 0 8px 0' }} tag="div" variant="strong-sm">
                        Add Milestone Date
                    </Typography>

                    <DatePicker
                        id="sale-date-picker"
                        label="Sale"
                        dateFormat="MM/dd/yyyy"
                        value={new Date(saleFieldValue)}
                        onChange={(e) => {
                            setDate(e, SALE);
                        }}
                        disabled={isDealStatusLost}
                        required={isSaleDateRequired}
                        hasError={isSaleDateRequired}
                        locale="en-US"
                        maxDate={new Date()}
                        minDate={oneYearAgoFromNow}
                        errorMessage="Sale is required"
                    />
                    <DatePicker
                        id="contract-signed-date-picker"
                        label={CONTRACT_SIGNED}
                        dateFormat="MM/dd/yyyy"
                        value={new Date(contractSignedFieldValue)}
                        onChange={(e) => {
                            setDate(e, CONTRACT_SIGNED);
                        }}
                        disabled={isFieldEmpty(saleFieldValue) || isDealStatusLost}
                        required={isContractSignedDateRequired}
                        locale="en-US"
                        maxDate={new Date()}
                        minDate={oneYearAgoFromNow}
                        hasError={isContractSignedDateRequired}
                        errorMessage="Contract Signed is required"
                    />
                    <DatePicker
                        id="delivered-date-picker"
                        label={DELIVERED}
                        dateFormat="MM/dd/yyyy"
                        value={new Date(deliveredFieldValue)}
                        onChange={(e) => {
                            setDate(e, DELIVERED);
                        }}
                        disabled={isFieldEmpty(contractSignedFieldValue) || isDealStatusLost}
                        required={isDeliveredDateRequired}
                        locale="en-US"
                        maxDate={new Date()}
                        minDate={oneYearAgoFromNow}
                        hasError={isDeliveredDateRequired}
                        errorMessage="Delivered is required"
                    />
                </ModalDialog>
            </div>
        </div>
    );
};
