import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

export enum ETaxNames {
    UPFRONT = 'upfrontTaxes',
    MONTHLYUSE = 'monthlyUseTaxes',
    ADDITIONAL = 'additionalTaxes',
    CAPITALIZED = 'capitalizedTaxes'
}

export type TaxNames = 'additionalTaxes' & 'capitalizedTaxes' & 'monthlyUseTaxes' & 'upfrontTaxes';

export type CategorizedTaxes = Record<keyof TaxNames, paymentServicesTypes.TaxItems>;

export type TaxDetail = {
    [key: string]: any;
};
