// libraries
import type { IConfigObj } from '@makemydeal/dr-common-utils';
import type { UrlsConfig } from '@makemydeal/dr-dash-bff-types';
import type {
    InitConfigurationServicesState,
    InitConfigurationState,
    InitConfigurationStaticAssetsState,
    KbbConfig,
    StateTree
} from '@makemydeal/dr-dash-types';

export const getConfig = (state: StateTree): InitConfigurationState => {
    return state.config;
};

export const getConfigStaticImages = (state: StateTree): IConfigObj | undefined => {
    return getConfig(state).staticImages;
};

export const getConfigServices = (state: StateTree): InitConfigurationServicesState => {
    return getConfig(state).services;
};

export const getServicesGateway = (state: StateTree): IConfigObj | undefined => {
    return getConfigServices(state)?.gateway;
};

export const getServicesBff = (state: StateTree): IConfigObj => {
    return getConfigServices(state)?.bff;
};

export const getServicesKbb = (state: StateTree): Partial<KbbConfig> => {
    return getConfigServices(state)?.kbb || ({} as Partial<KbbConfig>);
};

export const getUrls = (state: StateTree): UrlsConfig | undefined => {
    return getConfig(state).urls;
};

export const getStaticAssets = (state: StateTree): InitConfigurationStaticAssetsState | undefined => {
    return getConfig(state).staticAssets;
};

export const getGtmId = (state: StateTree): string => {
    return getConfigServices(state)?.google?.googleTagManagerId || '';
};

export const getWebsocketUrl = (state: StateTree): string => {
    return getConfigServices(state)?.websockets?.url || '';
};

export const getCopyToDeskingURL = (state: StateTree): string => {
    return getConfig(state).urls?.copyToDesking || '';
};

export const getDealertrackUrl = (state: StateTree): string => {
    return getConfig(state).urls?.dealertrack || '';
};

export const getVAutoInventoryUrl = (state: StateTree): string => {
    const vAutoUrl = getConfig(state).urls?.vAutoInventory;
    return vAutoUrl || '';
};

export const getVAutoTriforceScriptUrl = (state: StateTree): string => {
    const triforceScriptUrl = getConfig(state).urls?.triforceScriptUrl;
    return triforceScriptUrl || '';
};

export const getWalkMeUrl = (state: StateTree): string => {
    const walkMeScriptUrl = getConfig(state).urls?.walkMeScriptUrl;
    return walkMeScriptUrl || '';
};

export const getVAutoAppraisalUrl = (state: StateTree): string => {
    const appraisalUrl = getConfig(state).urls?.vAutoAppraisal;
    return appraisalUrl || '';
};

export const getTextMessageBaseUrl = (state: StateTree): string => {
    return getConfig(state).urls?.textMessageBaseUrl || '';
};

export const getPartSitesCatalogUrl = (state: StateTree): string => {
    return getConfig(state).urls?.partSites || '';
};

export const getCheckoutAnywhereBaseUrl = (state: StateTree): string => {
    const checkoutAnywhereBaseUrl = getConfig(state).urls?.checkoutAnywhereBaseUrl;
    return checkoutAnywhereBaseUrl || '';
};

export const getDDJRedirectBaseUrl = (state: StateTree): string => {
    const ddjRedirectUrl = getConfig(state).urls?.ddjRedirectUrl;
    return ddjRedirectUrl || '';
};

export const getDDJSourceUrl = (state: StateTree): string => {
    const ddjSourceUrl = getConfig(state).urls?.ddjSourceUrl;
    return ddjSourceUrl || '';
};
