/*
 * "Offer" part of state tree is maintained by offer-redux.  Anything else offer related that isn't
 * handled by offer-redux (i.e. it isn't related to penciling) should go here.
 */

// externals
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { OfferInfoState } from '@makemydeal/dr-dash-types';
import { bootstrapActionTypes, initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import {
    COBUYER_SHOW_INVALID_MODAL,
    LINKS_FAILURE,
    LINKS_SUCCESS,
    OFFER_SAVE_FAILURE,
    OFFER_SAVE_NOCHANGE_REQUEST,
    OFFER_SAVE_NOCHANGE_SUCCESS,
    OFFER_SAVE_REQUEST,
    OFFER_SAVE_SUCCESS,
    OFFER_SUBMISSION_FAILURE,
    OFFER_SUBMISSION_REQUEST,
    OFFER_SUBMISSION_SUCCESS,
    SAVE_OFFER,
    SOFT_SAVE_FAILURE,
    SOFT_SAVE_SUCCESS,
    SUBMIT_OFFER
} from '../actionTypes/offerActionTypes';
import { OFFER_SENT_WITH_TEST_DRIVE, TEST_DRIVE_READY_TO_SEND } from '../actionTypes/testDriveActionTypes';
import { SOCKET_EVENT_RECEIVED } from '../actionTypes/socketActionTypes';

// utils
import { storeLinksInState, updateOfferSaveFailureState, updateOfferSavedSuccessState } from '../utils/saveOfferStateUtils';
import { queryParamStore } from '@makemydeal/dr-shared-ui-utils';

const { INIT_SUCCESS } = initActionTypes;
const { BOOTSTRAP_REQUEST } = bootstrapActionTypes;

export type OfferInfoReducer = Reducer<OfferInfoState>;

export const reducer: OfferInfoReducer = (state = {} as OfferInfoState, action: AnyFSA) => {
    if (!action) {
        return state;
    }

    return produce(state, (draft: Draft<OfferInfoState>) => {
        switch (action.type) {
            case INIT_SUCCESS:
            case OFFER_SUBMISSION_SUCCESS: {
                const offer = action.payload.offer || {};
                draft.offerId = offer.id || offer.offerId;
                draft.hasBeenSent = offer.hasBeenSent || false;
                draft.isOfferBeingSubmitted = false;
                if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                    const payloadDealXgId = action.payload.offer?.dealExchangeDealId || '';
                    const payloadDealXgVersion = action.payload.offer?.dealExchangeVersionId || '';
                    console.log(
                        `DASH DEBUG: "Smart Offer Save", Deal XG ID ${payloadDealXgId} ver ${payloadDealXgVersion}` +
                            `, action "${action.type}"`
                    );
                }
                return;
            }
            case OFFER_SUBMISSION_FAILURE: {
                draft.hasBeenSent = false;
                draft.isOfferBeingSubmitted = false;
                return;
            }
            case OFFER_SUBMISSION_REQUEST:
            case SUBMIT_OFFER: {
                draft.isOfferBeingSubmitted = true;
                return;
            }
            case TEST_DRIVE_READY_TO_SEND: {
                if (!state.hasBeenSent) {
                    draft.isTestDriveReadyBeforeOffer = true;
                    return;
                }
                return;
            }
            case OFFER_SENT_WITH_TEST_DRIVE: {
                draft.isTestDriveReadyBeforeOffer = false;
                return;
            }
            case SAVE_OFFER:
            case OFFER_SAVE_NOCHANGE_REQUEST:
            case OFFER_SAVE_REQUEST: {
                draft.isOfferBeingSaved = true;
                return;
            }
            case OFFER_SAVE_SUCCESS: {
                draft.dealXgVersion = action.payload.dealXgVersion;
                updateOfferSavedSuccessState(draft);
                storeLinksInState(draft, action.payload);
                return;
            }
            case SOFT_SAVE_SUCCESS:
            case LINKS_SUCCESS: {
                updateOfferSavedSuccessState(draft);
                storeLinksInState(draft, action.payload);
                return;
            }
            case OFFER_SAVE_NOCHANGE_SUCCESS: {
                return updateOfferSavedSuccessState(draft);
            }
            case OFFER_SAVE_FAILURE: {
                return updateOfferSaveFailureState(draft);
            }
            case SOFT_SAVE_FAILURE:
            case LINKS_FAILURE: {
                return updateOfferSaveFailureState(draft);
            }
            case SOCKET_EVENT_RECEIVED: {
                const dealRefId = action.payload.data.coxAutoReferences?.dealRefId;
                if (dealRefId) {
                    draft.dealRefId = dealRefId;
                    draft.fsDealId = dealRefId;
                }
                if (action.payload.data.changedBy?.persona.toLowerCase() === 'system') return;
                draft.socketEventNotification = {
                    updatedMilestones: action.payload.updatedMilestones,
                    changedBy: action.payload.data.changedBy,
                    dealProgressMilestones: action.payload.data.dealProgressMilestones,
                    message: action.payload.data.message
                };
                return;
            }
            case BOOTSTRAP_REQUEST: {
                draft.socketEventNotification = undefined;
                return;
            }
            case COBUYER_SHOW_INVALID_MODAL: {
                draft.showInvalidCoBuyerModal = action.payload ?? false;
                return;
            }
        }
    });
};
