// external
import styled from 'styled-components';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const VppPreviewSummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
    @media (min-width: ${BreakPoint.SMALL}) {
        margin-right: 16px;
    }
`;

export const VppPreviewSummaryRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
`;
