import { FC } from 'react';
import { DrawerHeader } from './DrawerHeader';
import PricingForm from './PricingForm';
import { useSelector } from 'react-redux';
import { vehicleSelectors } from '@makemydeal/dr-dash-store';
import { DrawerContent } from './Style';
import { VehicleImage } from './VehicleImage';
import { VehicleNotes } from './VehicleNotes';
import { VehicleInfo } from './VehicleInfo';
import PaymentStatusInterstate from '../../common/PaymentStatus.interstate';
import { Box } from '@interstate/components/Box';

export const ContentForm: FC = () => {
    const {
        condition,
        driveLine,
        engine,
        exteriorColor,
        fuel,
        imageUrl,
        interiorColor,
        inventoryDate,
        location,
        make,
        model,
        notes,
        stockNumber,
        transmission,
        trim,
        vin,
        year,
        isCustom
    } = useSelector(vehicleSelectors.getVehicle);

    return (
        <DrawerContent>
            <Box pb={2}>
                <PaymentStatusInterstate />
            </Box>
            <DrawerHeader {...{ condition, inventoryDate, year, model, make, trim, vin, stockNumber, isCustom }} />
            <VehicleInfo {...{ driveLine, engine, exteriorColor, fuel, interiorColor, location, transmission }} />
            <PricingForm />
            <VehicleImage imageUrl={imageUrl} />
            <VehicleNotes notes={notes} />
        </DrawerContent>
    );
};
