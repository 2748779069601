// libraries
import type { DealerInstalledOptions } from '@makemydeal/dr-dash-bff-types';

// state
import type { StateTree } from '@makemydeal/dr-dash-types';

export const getVehicle = (state: StateTree): any => {
    return state.vehicle || {};
};

export const getVehicleCondition = (state: StateTree): string => {
    return getVehicle(state).condition;
};

export const getVehicleConditionOrDefault = (state: StateTree): string => {
    return getVehicle(state).condition || '';
};

export const getVehicleConditionLower = (state: StateTree): string => {
    return getVehicleConditionOrDefault(state).toLowerCase();
};

export const getVehicleImageUrl = (state: StateTree): string => {
    return getVehicle(state).imageUrl;
};

export const getVehicleModel = (state: StateTree): string => {
    return getVehicle(state).model;
};

export const getVehicleMake = (state: StateTree): string => {
    return getVehicle(state).make;
};

export const getVehicleTrim = (state: StateTree): string => {
    return getVehicle(state).trim;
};

export const getVehicleVin = (state: StateTree): string => {
    return getVehicle(state).vin;
};

export const getVehicleExteriorColor = (state: StateTree): string => {
    return getVehicle(state).exteriorColor;
};

export const getVehicleYear = (state: StateTree): number => {
    return getVehicle(state).year;
};

export const getListedPrice = (state: StateTree): number => {
    return getVehicle(state).listedPrice;
};

export const getRetailPrice = (state: StateTree): number => {
    return getVehicle(state).retailPrice;
};

export const getVehicleStockNumber = (state: StateTree) => {
    return getVehicle(state).stockNumber;
};

export const getVehicleId = (state: StateTree) => {
    return getVehicle(state).vehicleId;
};

export function getDealerInstalledOptions(state: StateTree) {
    const vehicle = getVehicle(state);
    return vehicle.dealerInstalledOptions || [];
}

export function getDIOTotalValues(state: StateTree): number {
    const installedOptions = getDealerInstalledOptions(state) as DealerInstalledOptions[];
    if (installedOptions.length <= 0) return 0;
    const totalDIOValues = installedOptions.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
    return totalDIOValues;
}
export const isVehicleNew = (state: StateTree) => {
    return getVehicleConditionLower(state) === 'new';
};

export const getMileage = (state: StateTree) => {
    return getVehicle(state).mileage;
};
