// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import {
    DpmClient,
    DpmClientOptions,
    APP_NAME_DEAL_CENTRAL,
    PERSONA_DEAL_MAKER,
    ACTIVITY_STATE_COMPLETED,
    DpmClientErrorHandling,
    DpmDealLoadErrorHanding,
    ACTIVITY_STATE_IN_PROGRESS,
    ACTIVITY_NAME_VEHICLE_PROTECTION,
    ACTIVITY_NAME_TRADE_IN,
    ACTIVITY_NAME_ACCESSORIES
} from '@makemydeal/dr-dpm-client';
import { restApiCaller } from '@makemydeal/dr-shared-network';
import { Actions as menuActions } from '@makemydeal/dr-activities-menu';
import { Actions as tradeInActions } from '@makemydeal/dr-activities-tradein';
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { featureToggleSelectors, initActionTypes } from '@makemydeal/dr-shared-store';

// selectors
import * as configSelectors from '../selectors/config';
import * as dealXgDetailsSelectors from '../selectors/dealXgDetailsSelectors';
import { getDealXgId } from '../selectors/offerRedux';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// const/enums
import { updateMilestones } from '../actions/dpmActionCreators';
import * as vppActions from '../actionTypes/vehicleProtectionActionTypes';
import * as accessoriesActions from '../actionTypes/accessoriesActionTypes';

const { INIT_SUCCESS } = initActionTypes;

const options: DpmClientOptions = {
    appName: APP_NAME_DEAL_CENTRAL,
    persona: PERSONA_DEAL_MAKER
};

const dpmClient = new DpmClient(restApiCaller, options);

/* istanbul ignore next */
dpmClient.setActivityHook((activityText: string) => {
    // NOTE: You can add anything here to assist in troubleshooting DPM client (debugger statement is helpful when running locally).
});

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => async (action: AnyFSA) => {
    next(action);
    const state = store.getState();
    if (!featureToggleSelectors.isDpmEnabledAfterBootstrap(state)) {
        return;
    }
    const bff = configSelectors.getServicesBff(state);
    const urlFromConfig = urlBuilder.buildFromConfig(bff, 'deal/{dealXgId}/dpm');
    dpmClient.setUrl(urlFromConfig);

    try {
        switch (action.type) {
            case INIT_SUCCESS: {
                const dealExchangeDealId = getDealXgId(state);
                if (dealExchangeDealId) {
                    dpmClient.handleDealLoad(dealExchangeDealId, DpmDealLoadErrorHanding.DisallowPendingActivityState);
                }
                break;
            }
            case vppActions.VEHICLE_PROTECTION_IN_PROGRESS:
            case menuActions.UPDATED_OFFER_MENU_PRODUCTS: {
                const vehicleProtectionDpmState = dealXgDetailsSelectors.getDpmStateByActivity(
                    state,
                    ACTIVITY_NAME_VEHICLE_PROTECTION
                );

                const dpmResponse = await dpmClient.updateDealProgressWithValidation(
                    ACTIVITY_NAME_VEHICLE_PROTECTION,
                    ACTIVITY_STATE_IN_PROGRESS,
                    vehicleProtectionDpmState,
                    DpmClientErrorHandling.ThrowExceptions
                );

                const { activityMilestoneState } = (dpmResponse as any).data;
                store.dispatch(updateMilestones(activityMilestoneState));
                break;
            }
            case vppActions.VEHICLE_PROTECTION_COMPLETED:
            case menuActions.VEHICLE_PROTECTION_COMPLETED: {
                const vehicleProtectionDpmState = dealXgDetailsSelectors.getDpmStateByActivity(
                    state,
                    ACTIVITY_NAME_VEHICLE_PROTECTION
                );
                const dpmResponse = await dpmClient.updateDealProgressWithValidation(
                    ACTIVITY_NAME_VEHICLE_PROTECTION,
                    ACTIVITY_STATE_COMPLETED,
                    vehicleProtectionDpmState,
                    DpmClientErrorHandling.ThrowExceptions
                );
                const { activityMilestoneState } = (dpmResponse as any).data;
                store.dispatch(updateMilestones(activityMilestoneState));
                break;
            }
            case accessoriesActions.ACCESSORIES_ACTIVITY_IN_PROGRESS: {
                const accessoriesDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_ACCESSORIES);

                const dpmResponse = await dpmClient.updateDealProgressWithValidation(
                    ACTIVITY_NAME_ACCESSORIES,
                    ACTIVITY_STATE_IN_PROGRESS,
                    accessoriesDpmState,
                    DpmClientErrorHandling.ThrowExceptions
                );

                const { activityMilestoneState } = (dpmResponse as any).data;
                store.dispatch(updateMilestones(activityMilestoneState));
                break;
            }
            case accessoriesActions.ACCESSORIES_ACTIVITY_COMPLETED: {
                const accessoriesDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_ACCESSORIES);

                const dpmResponse = await dpmClient.updateDealProgressWithValidation(
                    ACTIVITY_NAME_ACCESSORIES,
                    ACTIVITY_STATE_COMPLETED,
                    accessoriesDpmState,
                    DpmClientErrorHandling.ThrowExceptions
                );

                const { activityMilestoneState } = (dpmResponse as any).data;
                store.dispatch(updateMilestones(activityMilestoneState));
                break;
            }
            case tradeInActions.UPDATE_TRADE_VALUE_SOURCE:
            case tradeInActions.TRADE_IN_OPENED:
            case tradeInActions.TRADE_IN_ICO_DISPLAYED: {
                const tradeDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_TRADE_IN);

                const dpmResponse = await dpmClient.updateDealProgressWithValidation(
                    ACTIVITY_NAME_TRADE_IN,
                    ACTIVITY_STATE_IN_PROGRESS,
                    tradeDpmState,
                    DpmClientErrorHandling.ThrowExceptions
                );
                const { activityMilestoneState } = (dpmResponse as any).data;
                store.dispatch(updateMilestones(activityMilestoneState));
                break;
            }
            case tradeInActions.UPDATED_TRADE_IN: {
                const tradeDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_TRADE_IN);

                const dpmResponse = await dpmClient.updateDealProgressWithValidation(
                    ACTIVITY_NAME_TRADE_IN,
                    ACTIVITY_STATE_COMPLETED,
                    tradeDpmState,
                    DpmClientErrorHandling.ThrowExceptions
                );
                const { activityMilestoneState } = (dpmResponse as any).data;
                store.dispatch(updateMilestones(activityMilestoneState));
                break;
            }

            default:
                break;
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Unable to update DPM state::${action.type}::${JSON.stringify(err)}`);
    }
};
