import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const SavingMessage = styled.p`
    margin-bottom: 1rem;
`;

export const ErrorMessage = styled.div`
    p {
        margin-bottom: 1rem;
    }
`;

export const LoadingIndicatorContainer = styled.div`
    padding: 1.5rem 0 2.5rem 0;
`;

export const AlertContainer = styled.div`
    margin-top: 0.5rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
`;

export const AlertContainerInterstate = styled.div`
    padding: 0 32px;
    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 0 16px;
    }
`;
