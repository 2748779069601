// externals
import { useSelector } from 'react-redux';

// store
import { deskingSelectors, paymentSelectors } from '@makemydeal/dr-dash-store';

import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';

const PartSitesAccessoriesAlert = ({ hasChanges }: { hasChanges: boolean }) => {
    const isPaymentError = useSelector(paymentSelectors.getIsError);
    const failedAccessories = useSelector(deskingSelectors.wasTheLastFailure('UPDATED_OFFER_MENU_PRODUCTS'));

    const shouldShowAlert = isPaymentError && failedAccessories;

    if (!hasChanges || !shouldShowAlert) {
        return null;
    }

    return (
        <Alert
            data-testid={'catalog-accessories-payment-error'}
            role="catalog-accessories-payment-error"
            type={'error'}
            sx={{ mt: 2 }}
        >
            <Typography tag="span" variant="body-sm" data-testid="catalog-accessories-payment-error-message">
                Some selected accessories are causing payment calculation issues, so deal payment can't be calculated.
            </Typography>
        </Alert>
    );
};

export default PartSitesAccessoriesAlert;
