// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

// selectors
import { getTradeIn } from './../selectors/trade';
import { connectionSelectors, vehicleSelectors } from '../selectors';

export const createTradePayload = (state: StateTree) => {
    const tradeData = getTradeIn(state);
    const connectionId = connectionSelectors.getConnectionId(state);
    const { vin } = vehicleSelectors.getVehicle(state);

    return {
        tradeData,
        vin,
        connectionId
    };
};
