import React from 'react';
import { Box } from '@interstate-104/components/Box';
import { Typography } from '@interstate-104/components/Typography';
import EmptyCategoryFallback from './EmptyCategoryFallback';
import TaxItemSummary from './TaxItemSummary';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

interface TaxCategorySectionProps {
    categoryTitle: string;
    taxItems: paymentServicesTypes.TaxItem[];
    dataTestId: string;
}

const TaxCategory: React.FC<TaxCategorySectionProps> = ({ categoryTitle, taxItems, dataTestId }) => (
    <Box data-testid={dataTestId} marginTop={3}>
        <Typography variant="h5" sx={{ marginBottom: -1 }}>
            {categoryTitle}
        </Typography>
        <hr />
        {taxItems?.length ? (
            taxItems.map((taxItem, i) => (
                <TaxItemSummary data-testid={taxItem.taxName} taxItem={taxItem} key={taxItem.taxName + i} />
            ))
        ) : (
            <EmptyCategoryFallback />
        )}
    </Box>
);

export default TaxCategory;
