// externals
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { offerReduxSelectors, configSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import '../custom.d';
import { DIGITAL_DEAL_JACKET_PARTNER_ID, DIGITAL_DEAL_JACKET_SOURCE } from '../constants';
import { HostEnvironment, hostUrlHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

const DigitalDealJacket = () => {
    const dealxgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const redirectBaseUrl = useSelector(configSelectors.getDDJRedirectBaseUrl);
    const sourceUrl = useSelector(configSelectors.getDDJSourceUrl);
    const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());

    const ddjEnv = useMemo(() => {
        const environments = {
            [HostEnvironment.Dev]: 'dev',
            [HostEnvironment.Dev2]: 'dev',
            [HostEnvironment.Local]: 'dev',
            [HostEnvironment.QA]: 'uat',
            [HostEnvironment.DarkProd]: 'uat',
            [HostEnvironment.UAT]: 'uat',
            [HostEnvironment.Prod]: 'prod',
            [HostEnvironment.None]: '',
            [HostEnvironment.Unknown]: ''
        };
        return environments[environment];
    }, [environment]);

    useEffect(() => {
        if (!document.querySelector('script[id*="ddj-pbc-url"]')) {
            const script = document.createElement('script');
            script.src = sourceUrl;
            script.type = 'text/javascript';
            script.id = 'ddj-pbc-url';
            document.body.appendChild(script);
        }
    }, [sourceUrl]);

    return (
        <ddj-app
            external-id={dealxgId}
            source={DIGITAL_DEAL_JACKET_SOURCE}
            env={ddjEnv}
            redirect-url={redirectBaseUrl}
            partner-id={DIGITAL_DEAL_JACKET_PARTNER_ID}
            partner-dealer-id={dealerId}
        ></ddj-app>
    );
};

export default DigitalDealJacket;
