import styled from 'styled-components';

export const TruncatedMessageContainer = styled.span`
    margin-left: 0.5rem;
`;

export const OfferCommentContainer = styled.div`
    display: flex;
    align-items: center;

    .chat-icon {
        width: 12px;
        height: 12px;
    }
`;
