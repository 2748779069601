// externals
import React, { BaseSyntheticEvent, PropsWithChildren, useState } from 'react';

// components
import { Typography } from '@interstate-104/components/Typography';
import { Action } from '@interstate-104/components/Action';

// styles
import { TradeDetailsFooter } from './trade.interstate.style';

export type TradeDetailsProps = {
    defaultExpandedValue?: boolean;
    isManualFormOpen: boolean;
};

export const TradeDetailsAccordion: React.FC<PropsWithChildren<TradeDetailsProps>> = ({
    isManualFormOpen,
    defaultExpandedValue,
    children
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpandedValue);

    const handleShowDetails = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    const tradeDetailsExpandedMessage = `${isExpanded ? 'Less' : 'More'} Details`;

    return (
        <>
            {isManualFormOpen ? (
                <div>
                    <div data-testid="detail-fields-container" hidden={!isExpanded}>
                        {children}
                    </div>
                    <TradeDetailsFooter>
                        <Action onClick={handleShowDetails}>
                            <Typography variant="anchor-block-sm" color="base.color.blue.700" data-testid="show-details-button">
                                {tradeDetailsExpandedMessage}
                            </Typography>
                        </Action>
                    </TradeDetailsFooter>
                </div>
            ) : (
                <div data-testid="detail-fields-container">{children}</div>
            )}
        </>
    );
};
