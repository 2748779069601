// externals
import { Middleware, MiddlewareAPI } from 'redux';

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { MiddlewareManager } from '@makemydeal/dr-activities-menu';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { configSelectors } from '@makemydeal/dr-dash-store';

// utils
import { clientLogger, payloadUtils } from '@makemydeal/dr-shared-ui-utils';
import { configureMenu } from './config';
import { InitConfiguration } from '@makemydeal/dr-dash-bff-types';

// keep a cached version of the middleware once we're able to build it.
// exported to support testing
export let cachedMenuMiddlewareMgr: MiddlewareManager | undefined;

// needed for testing
export const menuMiddlewareClearCache = () => (cachedMenuMiddlewareMgr = undefined);

export const menuMiddleware: Middleware<unknown, StateTree> = (store: MiddlewareAPI) => (next: any) => (action: AnyFSA) => {
    if (action.type === bootstrapActionTypes.BOOTSTRAP_SUCCESS) {
        // get what we can from state.  This typically will be the bff endpoint only since its placed as part of initial state
        const state = store.getState();
        const bff = configSelectors.getServicesBff(state);

        // now we will not have the toggles, static images or the gateway in state
        // so we will have to replace them
        const config = payloadUtils.getConfigFromInitPayload<InitConfiguration>(action.payload);
        const featureToggles = payloadUtils.getFeatureTogglesFromInitPayload(action.payload);
        const { staticImages, services: { gateway } = {} } = config;

        // we need staticImages to continue
        if (staticImages && gateway) {
            cachedMenuMiddlewareMgr = configureMenu({
                services: {
                    gateway,
                    bff
                },
                staticImages,
                featureToggles
            });
        } else {
            clientLogger.clientWarnMessage('Could not bootstrap the menu activity.');
        }
    }

    if (cachedMenuMiddlewareMgr) {
        cachedMenuMiddlewareMgr.middleware(store)(next)(action);
    } else {
        next(action);
    }
};
