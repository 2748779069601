// libraries
import type { DealHistory, DealHistoryState, StateTree } from '@makemydeal/dr-dash-types';

export const getDealHistory = (state: StateTree): DealHistoryState => {
    return state.dealHistory || { offers: [] };
};

export const getDealHistoryOffers = (state: StateTree): DealHistory[] => {
    const dealHistory = getDealHistory(state);
    return dealHistory.offers || [];
};

export const getDefaultDealHistory = (state: StateTree) => {
    const deals = getDealHistory(state);
    return deals.offers && deals.offers.length
        ? deals.offers.reduce((prev: any, curr: any) => {
              return Date.parse(curr.offer.createdOn as string) < Date.parse(prev.offer.createdOn as string) ? curr : prev;
          })
        : ({} as DealHistory);
};

export const getDealHistoryIsLoading = (state: StateTree): boolean => {
    const dealHistory = getDealHistory(state);
    return dealHistory.isLoading || false;
};

// Offers from MV should be considered dealer offers
export const getDealerOffers = (state: StateTree): DealHistory[] => {
    const deals = getDealHistoryOffers(state);
    const dealerOffers = deals.filter((deal) => deal.offer.offerSource?.toLowerCase() === 'dealmaker');
    return dealerOffers;
};

// Offers generated from either Showroom or AMD should be considered shopper offers
export const getShopperOffers = (state: StateTree): DealHistory[] => {
    const deals = getDealHistoryOffers(state);
    const shopperOffers = deals.filter(
        (deal) => deal.offer.offerSource?.toLowerCase() === 'shopper' || deal.offer.offerSource?.toLowerCase() === 'dealer'
    );
    return shopperOffers;
};

const getLatestOffer = (offers: DealHistory[]) => {
    const latestOffer = offers
        .filter((deal) => {
            // TODO: DASH - offers that have a falsy createdOn value can't be timelined, so are filtered out
            return !!deal.offer.createdOn;
        })
        .reduce((previousOffer: DealHistory, currentOffer: DealHistory) => {
            const previousDate = new Date(previousOffer.offer?.createdOn as string);
            const currentDate = new Date(currentOffer.offer.createdOn as string);
            return previousDate > currentDate ? previousOffer : currentOffer;
        }, {} as DealHistory);
    return Object.keys(latestOffer).length ? latestOffer : undefined;
};

export const getLatestDealerOffer = (state: StateTree): DealHistory | undefined => {
    const dealerOffers = getDealerOffers(state);
    const latestDealerOffer = getLatestOffer(dealerOffers);
    return latestDealerOffer;
};

export const getLatestShopperOffer = (state: StateTree): DealHistory | undefined => {
    const shopperOffers = getShopperOffers(state);
    const latestShopperOffer = getLatestOffer(shopperOffers);
    return latestShopperOffer;
};

export const getSelectedDealHistory = (state: StateTree): DealHistory[] => {
    const dealHistory = getDealHistory(state);
    return dealHistory.selectedDeals || [];
};

export const getSelectedDealBySelectedColumn = (state: StateTree): DealHistory | undefined => {
    const dealHistory = getDealHistory(state);
    return (dealHistory.selectedDeals as DealHistory[]).find((deal) => deal.isSelected);
};
