// externals
import { Reducer } from 'redux';

// libraries
import { NavigationItemStatus } from '@makemydeal/dr-activities-common';
import { MenuActivityState } from '@makemydeal/dr-activities-menu';
import { menuShopperActivity } from '@makemydeal/dr-dash-store';
// routing
import { MenuContainer } from './components/MenuContainer';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// config
import { getMenuSelectors, menuRoutes, menuReducer } from './config';
import { menuMiddleware } from './middleware';

// utils
import { withSelectors } from '../common/withSelectors';

const DxgMenuDetails = null as unknown as React.FC<any>;

const DxgMenuSummary = null as unknown as React.FC<any>;

export type MenuReducer = Reducer<MenuActivityState>;

export const menuConfiguration: IFlowConfig<MenuActivityState> = {
    name: menuShopperActivity.constants.APP_NAME,
    navCard: {
        label: 'Explore Vehicle Protection',
        icon: 'handHoldingCar',
        iconClass: 'menu',
        cardId: 'dr-sp-menu-card',
        title: 'Vehicle Protection',
        nextStepsDescription: 'We offer a wide variety of warranties and plans to protect your vehicle.',
        mobileDescription: 'Protect your vehicle',
        dashboardIcon: 'handHoldingCar',
        timeSaved: 10,
        detailComponent: withSelectors(DxgMenuDetails, getMenuSelectors()),
        summaryComponent: withSelectors(DxgMenuSummary, getMenuSelectors()),
        secondaryCardCompletedTitle: 'Completed Vehicle Protection',
        secondaryCardTitle: 'Protect Yourself And Your Vehicle'
    },
    showCardSelector: () => true,
    isCompleteSelector: (state) => {
        const isCompleted = getMenuSelectors()?.isCompleted(state) || false;
        const status = getMenuSelectors()?.vehicleProtectionStatus(state) || NavigationItemStatus.DEFAULT;
        return isCompleted || status === NavigationItemStatus.READY_TO_SEND || status === NavigationItemStatus.SENT;
    },
    isUnavailableSelector: () => false,
    routingComponent: MenuContainer,
    defaultRoute: menuRoutes.MENU_PRODUCTS,

    routes: [...Object.keys(menuRoutes).map((routeName) => (menuRoutes as any)[routeName])],
    middleware: menuMiddleware,
    reducer: menuReducer,
    isLocked: () => false,
    dependsOnLeadFormCompletion: () => false,
    status: (state) => {
        const menuSelectors = getMenuSelectors();
        return menuSelectors ? menuSelectors.vehicleProtectionStatus(state) : NavigationItemStatus.UNAVAILABLE;
    },
    isDisabled: (state) => {
        const menuSelectors = getMenuSelectors();
        return menuSelectors ? menuSelectors.isMenuDisabled(state) : true;
    },
    showInNextSteps: () => true,
    showOnDealerOffer: () => true
};
