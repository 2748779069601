// externals
import { useSelector } from 'react-redux';

// components
import { Table } from '@interstate-104/components/Table';

// libraries
import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

// utils
import { transformProductToModel } from '@makemydeal/dr-activities-vehicle-protection';

// types
import { InterstateTableProps } from '@interstate-104/components/Table/Types/tableTypes';

export const VehicleProtectionPreviewTable = () => {
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);
    const isFetchingRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const isFetchingVppProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);

    const menuProducts = products
        .filter((product) => product.selected)
        .map((product) => transformProductToModel(product, isFetchingRates || isFetchingVppProducts));

    const columns: InterstateTableProps['columns'] = [
        { title: 'Product Name', dataIndex: 'productName', ellipsis: { showTitle: true }, width: '35%' },
        { title: 'Months', dataIndex: 'productCoverageLength' },
        { title: 'Miles', dataIndex: 'productCoverageMiles' },
        { title: 'Deductible', dataIndex: 'productDeductible' },
        { title: 'Price', dataIndex: 'productPrice' }
    ];

    return <Table scrollX={640} enablePagination={false} columns={columns} data={menuProducts} dataDensity="small" />;
};
