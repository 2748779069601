import { useState, FC, useCallback } from 'react';

import { Action } from '@interstate/components/Action';
import { CarSolidIcon } from '@interstate/components/Icons';
import { SlideOut } from '@interstate-104/components/SlideOut';
import { ContentForm } from './components/ContentForm';
import { FormContext } from './formManager/formContext';
import useForm from './formManager/useForm';
import DrawerFooterActions from './components/DrawerFooterActions';

export const VehicleInfoDrawer: FC = () => {
    const [showDrawer, setShowDrawer] = useState(false);

    const hideDrawer = useCallback(() => setShowDrawer(false), []);
    const formManager = useForm(hideDrawer);

    return (
        <>
            <Action
                size="xs"
                data-testid="btnVehicleInfo"
                actionIcon={{
                    icon: <CarSolidIcon />,
                    start: true
                }}
                onClick={() => setShowDrawer(true)}
            >
                Vehicle
            </Action>
            <FormContext.Provider value={formManager}>
                <SlideOut
                    data-testid="vehicle-info-drawer"
                    id="vehicle-info-drawer"
                    show={showDrawer}
                    position="left"
                    panelWidth="480px"
                    onHide={hideDrawer}
                    footer={{
                        footerComponent: <DrawerFooterActions />
                    }}
                    header="Vehicle Details"
                >
                    <ContentForm />
                </SlideOut>
            </FormContext.Provider>
        </>
    );
};

export default VehicleInfoDrawer;
