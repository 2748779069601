// libraries
import type { CreditDecision, StateTree } from '@makemydeal/dr-dash-types';
import { OfferType, FINANCE, LEASE } from '@makemydeal/dr-platform-types';

export const getLenderCreditDecisions = (state: StateTree): CreditDecision[] => {
    return state.creditDecision?.decisionsList || [];
};

export const getSourcePartnerId = (state: StateTree): string => {
    return state.creditDecision?.sourcePartnerId || '';
};

export const getLenderCreditDecisionsByOfferType = (state: StateTree, offerType: OfferType): CreditDecision[] => {
    if (!state.creditDecision?.decisionsList?.filter) {
        return [];
    }

    if (offerType === FINANCE) {
        return state.creditDecision.decisionsList.filter(
            (d: CreditDecision) => d.financeMethod === 'Finance' || d.financeMethod === 'Retail'
        );
    }

    if (offerType === LEASE) {
        return state.creditDecision.decisionsList.filter((d: CreditDecision) => d.financeMethod === 'Lease');
    }

    return [];
};

export const getSelectedCreditDecision = (state: StateTree): CreditDecision => {
    return state.creditDecision.selectedDecision || {};
};
