// externals
import { useCallback, useEffect, useState } from 'react';

// libraries
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInput, TextInputEventValue } from '@interstate/components/TextInput';
import { extractNumberFromInputValue } from '@makemydeal/dr-shared-ui-utils';

// utils
import { getCleanNumericString, getFormattedPrice } from '../../utils';

type TextInputForPriceProps = {
    value: number | undefined;
    onChange: (value: number) => void;
    dataTestId: string;
    htmlId: string;
    label?: string;
    name: string;
    error?: string;
};

const TextInputForPrice = ({ value, onChange, dataTestId, htmlId, label, name, error }: TextInputForPriceProps) => {
    const [stringValue, setStringValue] = useState(getFormattedPrice(value, false));
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const formatted = getFormattedPrice(value, isFocused);

        setStringValue((stringValue: string) => {
            // this allows '-' '.' '-0.' etc. for 0 values not to be changed to '0'
            if (isFocused && value === 0) {
                return stringValue.startsWith('$') ? formatted : stringValue;
            }

            return formatted;
        });
    }, [value, isFocused]);

    const handleOnChange = useCallback(
        (e: InterstateOnChangeEvent<TextInputEventValue>) => {
            const rawValue = e.target.value ? e.target.value.toString() : '';
            const cleanNumericString = getCleanNumericString(rawValue);

            const previousNumericValue = extractNumberFromInputValue(stringValue);
            const numericValue = extractNumberFromInputValue(cleanNumericString);

            setStringValue(cleanNumericString || ' ');

            if (previousNumericValue !== numericValue) {
                onChange(numericValue);
            }
        },
        [onChange, stringValue]
    );

    const shouldDisplayLabel = !!label;
    const labelText = label || '';

    return (
        <TextInput
            data-testid={dataTestId}
            htmlId={htmlId}
            displayLabel={shouldDisplayLabel}
            label={labelText}
            name={name}
            onChange={handleOnChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={stringValue}
            error={error}
        />
    );
};

export default TextInputForPrice;
