import { Alert } from '@interstate-104/components/Alert';
import { useDispatch, useSelector } from 'react-redux';
import {
    navigationActionCreators,
    offerReduxActionCreators,
    offerReduxSelectors,
    paymentSelectors
} from '@makemydeal/dr-dash-store';
import { Action } from '@interstate-104/components/Action';
import { useEffect } from 'react';
/* istanbul ignore next */
export const LenderChangeAlert = () => {
    const dispatch = useDispatch();

    const creditDecisionEnabled = useSelector(offerReduxSelectors.getIsUserProgramQuotes);
    const actionForPayment = useSelector(paymentSelectors.getActionForPayment);
    const isShopperZipUpdateFailed = useSelector(paymentSelectors.getIsShopperUpdateFailed);
    const showLenderChangeAlert = useSelector(paymentSelectors.getIsLenderChanged);
    useEffect(() => {
        if (isShopperZipUpdateFailed && actionForPayment === 'UPDATED_SHOPPER_CONTACT') {
            dispatch(offerReduxActionCreators.updatedShopperLocationFromConsumerRetry());
        }
    }, [showLenderChangeAlert, isShopperZipUpdateFailed, actionForPayment, dispatch]);
    return (
        <>
            {showLenderChangeAlert && !creditDecisionEnabled && (
                <Alert
                    type={'info'}
                    displayCloseButton
                    onCloseClick={() => {
                        dispatch(offerReduxActionCreators.closeLenderChangedBanner());
                    }}
                >
                    <b>New Lender Program Applied</b>
                    <br />
                    <p>
                        The deal location has been changed and a new lender program has been applied. To choose a new program go to
                        <Action
                            data-testid="programs-link"
                            sx={{ padding: '6px', border: '0' }}
                            onClick={() => dispatch(navigationActionCreators.navigateToPrograms())}
                        >
                            <h5 style={{ color: '#005ba8', textDecoration: 'underline' }}>Programs and Incentives.</h5>
                        </Action>
                    </p>
                </Alert>
            )}
        </>
    );
};
