/**
 * This file provides the dash app with the additional selectors needed by the menu activity
 */

// libraries
import type { IAdditionalSelectors } from '@makemydeal/dr-activities-menu';
import type { IStateTreeBase } from '@makemydeal/dr-activities-common';
import type { StateTree } from '@makemydeal/dr-dash-types';
import type { OfferType } from '@makemydeal/dr-platform-types';
import { OPTIMIZELY_TEST_NAMES } from '@makemydeal/dr-browser-utils';
import { getRoute } from '@makemydeal/dr-activities-testdrive';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// selectors
import * as accessoriesSelectors from '../../selectors/accessoriesSelectors';
import * as connectionSelectors from '../../selectors/connectionSelectors';
import * as creditAppSelectors from '../../selectors/creditAppSelectors';

import * as offerInfoSelectors from '../../selectors/offerInfoSelectors';
import * as offerReduxSelectors from '../../selectors/offerRedux';
import * as offerSelectors from '../../selectors/offerSelectors';
import * as paymentTermSelectors from '../../selectors/paymentTermSelectors';
import * as vehicleSelectors from '../../selectors/vehicleSelectors';
import * as offerAdditionalSelectors from '../../selectors/offerAdditionalSelectors';
import { paymentSelectors } from '@makemydeal/dr-offer-redux';

export const additionalSelectors: IAdditionalSelectors = {
    accessoriesSelectors: {
        getAccessoriesListUnfiltered: (state: IStateTreeBase) =>
            accessoriesSelectors.getAccessoriesListUnfiltered(state as StateTree)
    },
    leadFormSelectors: {
        isLeadFormSubmittedSuccess: (state: IStateTreeBase) => true
    },
    connectionSelectors: {
        getConnectionId: (state: IStateTreeBase) => connectionSelectors.getConnectionId(state as StateTree) || ''
    },
    creditSelectors: {
        isCreditAppCompleted: (state: IStateTreeBase) => creditAppSelectors.isCreditAppCompleted(state as StateTree) as boolean
    },
    configSelectors: {
        isP202RedesignEnabled: () => true,
        isActivityEnabled: (state: IStateTreeBase) =>
            featureToggleSelectors.isMenuActivityEnabledAfterBootstrap(state as StateTree),
        menuSelectionsVisibleToUserType: () => 'dealer'
    },
    counterOfferSelectors: {
        getTotalCashOffer: () => 0
    },
    dealerSelectors: {
        getDealerId: (state: IStateTreeBase) => dealerSelectors.getDealerId(state as StateTree),
        isVppEnabled: (state: IStateTreeBase) => dealerSelectors.isVppEnabled(state as StateTree),
        microSiteEnabled: () => false,
        useDRCRM: (state: IStateTreeBase) => dealerSelectors.useDRCRM(state as StateTree),
        getDealerPhone: (state: IStateTreeBase) => dealerSelectors.getDealerPhone(state as StateTree),
        isMenuFitToHeightEnabled: (state: IStateTreeBase) => dealerSelectors.isMenuFitToHeightEnabled(state as StateTree),
        isMenuPricingEnabled: (state: IStateTreeBase) => dealerSelectors.isMenuPricingEnabled(state as StateTree)
    },
    featureToggleSelectors: {
        isAccessoriesEnabled: featureToggleSelectors.isAccessoriesEnabled,
        creditOverwriteBlockEnabled: dealerSelectors.isCreditOverwriteBlockAndFni2Enabled,
        enableMenuOnCash: featureToggleSelectors.enableMenuOnCash,
        enableMenuSelectionsRevertLogic: featureToggleSelectors.enableMenuSelectionsRevertLogic,
        isCashEnabled: featureToggleSelectors.isCashEnabled,
        isMenuPaymentsDisclaimerEnabled: featureToggleSelectors.isMenuPaymentsDisclaimerEnabled,
        isMenuUpdateWhenSdpTermsChangeEnabled: featureToggleSelectors.isMenuUpdateWhenSdpTermsChangeEnabled,
        isNewPaymentWorkFlow: featureToggleSelectors.isNewPaymentWorkFlow,
        isVppMonthlyPaymentEnabled: featureToggleSelectors.isVppMonthlyPaymentEnabled,
        showMenuPaymentError: featureToggleSelectors.showMenuPaymentError,
        viewDetailsVppNonRatedLongText: featureToggleSelectors.viewDetailsVppNonRatedLongText,
        isLambdaVppEnabled: featureToggleSelectors.isLambdaVppEnabled,
        isPaymentBuildingCardsEnabled: featureToggleSelectors.isPaymentBuildingCardsEnabled as (state: IStateTreeBase) => boolean,
        usePaymentProductsMonthlyPayment: featureToggleSelectors.usePaymentProductsMonthlyPayment as (
            state: IStateTreeBase
        ) => boolean
    },
    offerSelectors: {
        // TODO: Investigate why this requires the cast to OfferType - probably undefined/null not being handled by calling code?
        getOfferType: (state: IStateTreeBase) => offerReduxSelectors.getCurrentOfferType(state as StateTree) as OfferType,
        getShopperZip: offerReduxSelectors.getShopperZip, // TODO: US548421 - Take this out and use offer-redux directly
        getOfferDetails: (state: IStateTreeBase) => offerSelectors.getOfferDetailsForMenu(state as StateTree)
    },
    paymentSelectors: {
        isCalculatingPayment: (state: IStateTreeBase) => paymentSelectors.isCalculatingPayment(state as StateTree) as any
    },
    paymentTermSelectors: {
        getCurrentPaymentTerm: (state: IStateTreeBase) => paymentTermSelectors.getCurrentPaymentTerm(state as StateTree) as any
    },
    vehicleSelectors: {
        getVehicleCondition: (state: IStateTreeBase) => vehicleSelectors.getVehicleCondition(state as StateTree),
        getVehicleVin: (state: IStateTreeBase) => vehicleSelectors.getVehicleVin(state as StateTree) || '',
        getVehicleDetails: (state: IStateTreeBase) => vehicleSelectors.getVehicle(state as StateTree)
    },
    offerInfoSelectors: {
        // TODO: Investigate why this requires the cast to boolean - probably undefined/null not being handled by calling code?
        getHasBeenSent: (state: IStateTreeBase) => offerInfoSelectors.getHasBeenSent(state as StateTree) as boolean,
        // TODO: Investigate why this requires the cast to boolean - probably undefined/null not being handled by calling code?
        getIsOfferBeingSubmitted: (state: IStateTreeBase) =>
            offerInfoSelectors.getIsOfferBeingSubmitted(state as StateTree) as boolean,
        getCurrentOfferType: (state: IStateTreeBase) => offerReduxSelectors.getCurrentOfferType(state as StateTree),
        getCashOfferRoundedUp: (state: IStateTreeBase) => offerAdditionalSelectors.getMvCashOfferRoundedUp(state as StateTree),
        getMonthlyPaymentRoundedByOfferType: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType,
        getSelectedTerm: offerReduxSelectors.getSelectedTerm,
        getOfferCreationDate: (state: IStateTreeBase) => offerReduxSelectors.getOfferCreationDate(state)
    },
    leadFormInfoSelectors: {
        isOfferSubmitFailed: () => false
    },
    dealerSelectorsTheme: {
        getDealerTheme: (state: IStateTreeBase) => dealerSelectors.getDealerSelectorsTheme(state as StateTree)
    },
    testDriveSelectors: {
        getRoute
    },
    optimizelySelectors: {
        isVariationActive: (state: StateTree, optimizelyTest: any, optimizelyVariation: string) => {
            return optimizelyTest.name === 'vehicleProtectionInPrimaryFlow';
        },
        getOptimizelyTests: () => ({
            vehicleProtectionInPrimaryFlow: {
                name: 'vehicleProtectionInPrimaryFlow',
                primaryFlow: 'some-fake-value-for-now'
            }
        }),
        activateTests: () => null,
        getTestValue: () => null,
        getTestNames: () => OPTIMIZELY_TEST_NAMES,
        track: () => null
    },
    navigationCardSelectors: {
        getNextCardDetails: () => null
    },
    tradeInSelectors: {
        isTradeInCompleted: () => false
    },
    termsSelectors: {
        isTermsComplete: true // Terms always complete
    },
    editPaymentActivitiesSelectors: {
        messageBox: (() => {}) as any
    },
    widgetSettingsSelectors: {
        getDealSetupTaxCallMade: () => false // widget isn't involved for Manager View
    }
};
