// libraries
import { Actions, ConfigMenu, MiddlewareManager, Selectors, getAllRoutes, init, reducer } from '@makemydeal/dr-activities-menu';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { configSelectors, menuShopperActivity, featurePackageSelectors } from '@makemydeal/dr-dash-store';
import { IConfigObj } from '@makemydeal/dr-common-utils';

const {
    hostActions,
    additionalSelectors,
    constants: { MENU_ROUTE_ROOT, APP_NAME }
} = menuShopperActivity;

export const menuActions = Actions;
export const menuRoutes = getAllRoutes(MENU_ROUTE_ROOT);
export const menuReducer = reducer;

let menuSelectors: Selectors; // these won't be set until menu is configured

// allows for easier testing
export const getMenuSelectors = (): Selectors | undefined => menuSelectors;

export const configureMenu = (config: ConfigMenu): MiddlewareManager => {
    const { selectors, middlewareMgr } = init(APP_NAME, additionalSelectors, hostActions, config, MENU_ROUTE_ROOT);
    featurePackageSelectors.addOrReplace(APP_NAME, selectors);
    menuSelectors = selectors;

    return middlewareMgr;
};

export const getConfigMenuFromState = (state: StateTree): ConfigMenu => {
    const gateway = configSelectors.getServicesGateway(state) as IConfigObj;
    const bff = configSelectors.getServicesBff(state);
    const staticImages = configSelectors.getConfigStaticImages(state) as IConfigObj;
    const featureToggles = featureToggleSelectors.getSimpleFeatureToggles(state);
    const menuConfig: ConfigMenu = {
        services: {
            bff,
            gateway
        },
        staticImages,
        featureToggles
    };

    return menuConfig;
};
