import React from 'react';

import { TableHead as InterstateTableHead, TableRow, TableCell } from '@interstate-104/components/SimpleTable';
import { StyledTableCell } from './FeesCard.style';

export type SimpleTableHeadColumn = {
    heading: string;
    options: {
        minWidth: number;
        textAlign?: string;
        paddingRight?: number;
        justifyContent?: string;
    };
};

export type SimpleTableHeadProps = {
    columns: SimpleTableHeadColumn[];
};

export const TableHead: React.FC<SimpleTableHeadProps> = ({ columns }) => (
    <InterstateTableHead>
        <TableRow>
            {columns.map((column, index) => {
                const { heading } = column;
                const { justifyContent, minWidth, paddingRight, textAlign } = column.options;
                return (
                    <TableCell key={`head-cell-${index}`}>
                        <StyledTableCell
                            $paddingRight={paddingRight}
                            $textAlign={textAlign}
                            $minWidth={minWidth}
                            $justifyContent={justifyContent}
                        >
                            {heading}
                        </StyledTableCell>
                    </TableCell>
                );
            })}
        </TableRow>
    </InterstateTableHead>
);

export default TableHead;
