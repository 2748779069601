// externals
import { FC } from 'react';

// libraries
import { formatUtils, CustomInterstateValidationRule } from '@makemydeal/dr-dash-components';
import { InterstateOnChangeEvent } from '@interstate-104/components/InterstateEvents';
import { CheckBoxEventValue } from '@interstate-104/components/CheckBox';
import { SelectInputEventValue } from '@interstate-104/components/SelectInput';
import { RuleResults } from '@interstate-104/components/Validation/Types/validationTypes';

// components
import { ManualAccessoryActionIcon } from './ManualAccessoryActionIcon.interstate';
import { ManualAccessoryNameInput } from './ManualAccessoryNameInput.interstate';
import { ManualAccessoryIdField } from './ManualAccessoryIdField.interstate';
import { ManualAccessoryInstallField } from './ManualAccessoryInstallField.interstate';
import { ManualAccessorySaleField } from './ManualAccessorySaleField.interstate';
import { ManualAccessoryCostField } from './ManualAccessoryCostField.interstate';
import { ManualAccessoryResidualField } from './ManualAccessoryResidualField.interstate';
import { ManualAccessoryProfitField } from './ManualAccessoryProfitField.interstate';
import { ManualAccessoryUpfrontField } from './ManualAccessoryUpfrontField.interstate';
import { ManualAccessoryWeOweField } from './ManualAccessoryWeOweField.interstate';

// interfaces/types
import { AccessoryFormPropsInterstate } from '../manualAccessoriesTypes';

// utils
import { buildColumnMappings } from '../manualAccessoriesUtils';

// styles
import {
    ManualAccessoriesFormRowContainer,
    AccessoryFieldsContainer,
    AccessoryUpfrontWeOweContainer
} from '../../ManualAccessories.interstate.styles';

export const AccessoryRowForm: FC<AccessoryFormPropsInterstate> = ({
    accessory,
    index,
    updateAccessory,
    removeAccessory,
    restoreAccessory,
    updateAccessoryValidity,
    isLeaseLayout
}) => {
    const { amount, deleted, name, code, cost, profit, residual, upFront, weOwe, installPrice } = accessory;

    const optionalClassName = isLeaseLayout ? 'lease-layout' : '';

    const columnMappings = buildColumnMappings(accessory);

    const validateRules = (
        value: number | string | undefined,
        rules: CustomInterstateValidationRule[],
        fieldName: string,
        suppressAlerts: boolean
    ): RuleResults => {
        const ruleResults = rules.map((rule) => rule(value));
        const firstErrorResult = ruleResults.find((ruleResult) => !ruleResult.isValid);
        const isValid = firstErrorResult === undefined;

        updateAccessoryValidity(index, fieldName, isValid, suppressAlerts);

        if (isValid || suppressAlerts) {
            return {
                validationState: { isValid: true, type: 'success' },
                triggered: false
            };
        }

        return {
            validationState: { isValid: false, message: firstErrorResult.errorMessage, type: 'error' },
            triggered: true
        };
    };

    const handleStringChange = (event: InterstateOnChangeEvent<string>, field: string) => {
        const value = event.target.value;

        updateAccessory(index, field, value);
    };

    const handleSelectInputChange = (event: InterstateOnChangeEvent<SelectInputEventValue>, field: string) => {
        const value = event.target.value;

        updateAccessory(index, field, value);
    };

    const handleNumberChange = (event: InterstateOnChangeEvent<string>, field: string) => {
        const value = event.target.value;

        updateAccessory(index, field, formatUtils.convertToNumber(value));
    };

    const handleInputChange = (event: InterstateOnChangeEvent<string | SelectInputEventValue>, field: string) => {
        switch (field) {
            case columnMappings.Name.fieldName:
            case columnMappings.ID.fieldName:
                handleStringChange(event as InterstateOnChangeEvent<string>, field);
                break;
            case columnMappings.Profit.fieldName:
                handleSelectInputChange(event, field);
                break;
            case columnMappings.Cost.fieldName:
            case columnMappings.Sale.fieldName:
            case columnMappings.Residual.fieldName:
            case columnMappings.Install.fieldName:
                handleNumberChange(event as InterstateOnChangeEvent<string>, field);
                break;
        }
    };

    const handleCheckboxCheck = (event: InterstateOnChangeEvent<CheckBoxEventValue>, field: string) => {
        updateAccessory(index, field, !!event.target.value.checked);
    };

    return (
        <ManualAccessoriesFormRowContainer id={`accessory-row-form-${index}`} data-testid={`accessory-row-form-${index}`}>
            <ManualAccessoryActionIcon
                deleted={deleted}
                index={index}
                restoreAccessory={restoreAccessory}
                removeAccessory={removeAccessory}
            />
            <AccessoryFieldsContainer className={optionalClassName}>
                <ManualAccessoryNameInput
                    deleted={deleted}
                    index={index}
                    name={name}
                    nameColumnMapping={columnMappings.Name}
                    handleInputChange={handleInputChange}
                    validateRules={validateRules}
                />
                <ManualAccessoryIdField
                    deleted={deleted}
                    index={index}
                    code={code}
                    idColumnMapping={columnMappings.ID}
                    handleInputChange={handleInputChange}
                    validateRules={validateRules}
                />
                <ManualAccessorySaleField
                    deleted={deleted}
                    index={index}
                    amount={amount}
                    saleColumnMapping={columnMappings.Sale}
                    handleInputChange={handleInputChange}
                    validateRules={validateRules}
                    weOwe={weOwe}
                />
                <ManualAccessoryCostField
                    deleted={deleted}
                    index={index}
                    cost={cost}
                    costColumnMapping={columnMappings.Cost}
                    handleInputChange={handleInputChange}
                    validateRules={validateRules}
                    weOwe={weOwe}
                />
                <ManualAccessoryInstallField
                    deleted={deleted}
                    index={index}
                    install={installPrice}
                    installColumnMapping={columnMappings.Install}
                    handleInputChange={handleInputChange}
                    validateRules={validateRules}
                />
                {isLeaseLayout ? (
                    <ManualAccessoryResidualField
                        deleted={deleted}
                        index={index}
                        residual={residual}
                        residualColumnMapping={columnMappings.Residual}
                        handleInputChange={handleInputChange}
                        validateRules={validateRules}
                    />
                ) : null}
                <ManualAccessoryProfitField
                    deleted={deleted}
                    index={index}
                    profit={profit}
                    profitColumnMapping={columnMappings.Profit}
                    handleInputChange={handleInputChange}
                />
                <AccessoryUpfrontWeOweContainer className={optionalClassName}>
                    {isLeaseLayout ? (
                        <ManualAccessoryUpfrontField
                            deleted={deleted}
                            index={index}
                            upFront={upFront}
                            upfrontColumnMapping={columnMappings.Upfront}
                            handleCheckboxCheck={handleCheckboxCheck}
                        />
                    ) : null}
                    <ManualAccessoryWeOweField
                        deleted={deleted}
                        index={index}
                        weOwe={weOwe}
                        weOweColumnMapping={columnMappings.WeOwe}
                        handleCheckboxCheck={handleCheckboxCheck}
                    />
                </AccessoryUpfrontWeOweContainer>
            </AccessoryFieldsContainer>
        </ManualAccessoriesFormRowContainer>
    );
};
