/**
 * PURPOSE: Provide reducers that are required for the initial boostrap of the application.  These reducers cannot
 *   be selectively loading based on feature toggle flags.  For the the other reducers that can be conditionally
 *   added take a look at `remainingReducers.ts`.
 */

// externals
import { reducer as formReducer } from 'redux-form';

// libraries
import { navigationReducer } from '@makemydeal/dr-activities-common';
import type { InitConfigurationState } from '@makemydeal/dr-dash-types';
import { locHrefUtil, apiConfigHelper, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// reducers
import { configReducer, selectedOfferReducer, sharedBootstrapReducer, featureToggleReducer } from '@makemydeal/dr-shared-store';
import { vdpSourceReducer, manualIncentivesReducer } from '@makemydeal/dr-dash-store';

export const initialState: InitConfigurationState = {
    urls: {},
    staticAssets: {
        legacyThemeJson: ''
    },
    staticImages: {
        host: '',
        protocol: ''
    },
    services: {
        websockets: {
            url: ''
        },
        bff: {
            host: '',
            protocol: ''
        },
        gateway: {
            host: '',
            protocol: ''
        },
        kbb: {
            tradeInApiKey: ''
        },
        shortio: {
            apiKey: '',
            endpoint: ''
        },
        google: {
            googleTagManagerId: ''
        }
    }
};

const handleBootstrapInitialLoad: configReducer.BuildConfigReducerBootstrapInitialLoadCallback<InitConfigurationState> = (
    appPrefix,
    action,
    state
) => {
    // get urls based on windows location
    const bffApiConfig = apiConfigHelper.buildBffApiConfig(appPrefix, locHrefUtil.getLocHref());
    const assetsBaseUrl = apiConfigHelper.buildThemeAssetBasePath(appPrefix, locHrefUtil.getLocHref());
    const shallowCloned = { ...state };
    shallowCloned.services = {
        ...state.services,
        bff: {
            ...state.services.bff,
            ...bffApiConfig.configObj
        }
    };
    shallowCloned.staticAssets = {
        ...state.staticAssets,
        legacyThemeJson: `${assetsBaseUrl}/css/global-blue/theme-global-blue.json`
    };

    return shallowCloned;
};

export const initialReducers = {
    sharedBootstrap: sharedBootstrapReducer.reducer,
    config: configReducer.buildConfigReducer(MANAGER_VIEW_APP_PREFIX, initialState, handleBootstrapInitialLoad),
    featureToggles: featureToggleReducer.reducer,
    // TODO: DASH - we should try to deprecate redux forms soon
    form: formReducer,
    navigation: navigationReducer,
    selectedOffer: selectedOfferReducer.reducer,
    vdpSource: vdpSourceReducer.reducer,
    manualIncentives: manualIncentivesReducer.reducer
};
