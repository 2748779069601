import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@interstate/components/Grid';
import { NumericInput } from '@interstate/components/NumericInput';
import { kebabCase } from '@makemydeal/dr-common-utils';

import { COST, MSRP, ODOMETER, SELLING_PRICE } from '../../constants';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { changeVehicleCreators, changeVehicleSelectors } from '../../store';

export const NotInStockVehicleData: React.FC = () => {
    const [msrp, setMsrp] = useState<string>('');
    const [sellingPrice, setSellingPrice] = useState<string>('');
    const [cost, setCost] = useState<string>('');
    const [odometer, setOdometer] = useState<string>('');
    const [msrpError, setMsrpError] = useState<string>('');
    const [sellingPriceError, setSellingPriceError] = useState<string>('');
    const shouldShowCost = useSelector(changeVehicleSelectors.shouldShowCost);

    const dispatch = useDispatch();

    const createOnBlurHandler =
        ({
            name,
            setValue,
            setError
        }: {
            name?: string;
            setValue: React.Dispatch<React.SetStateAction<string>>;
            setError?: React.Dispatch<React.SetStateAction<string>>;
        }) =>
        (e: InterstateOnChangeEvent<string>) => {
            const { value } = e.target;
            if (name && setError) {
                setError(!Number(value) ? `${name} is required` : '');
            }
            setValue(value ? `${Number(value).toFixed(2)}` : '');
        };

    useEffect(() => {
        const newValue = {
            msrp: msrp ? Number(msrp) : undefined,
            sellingPrice: sellingPrice ? Number(sellingPrice) : undefined,
            cost: cost ? Number(cost) : undefined,
            odometer: odometer ? +odometer : undefined
        };
        dispatch(changeVehicleCreators.updateCustomVehicle(newValue));
    }, [msrp, sellingPrice, cost, odometer, dispatch]);

    return (
        <Grid
            container
            columnSpacing="8px"
            rowGap={1}
            sx={{
                '& label': { whiteSpace: 'nowrap !important' }
            }}
        >
            <Grid xs={5.5} md={3}>
                <NumericInput
                    placeholder="0.00"
                    name={kebabCase(MSRP)}
                    onChange={(e) => setMsrp(e.target.value)}
                    onBlur={createOnBlurHandler({ name: MSRP, setValue: setMsrp, setError: setMsrpError })}
                    value={msrp}
                    label={`${MSRP} (required)`}
                    data-testid={kebabCase(MSRP)}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    decimalMinLength={2}
                    inputPrefix={'$'}
                    autoInsertCommas={true}
                    error={msrpError}
                />
            </Grid>
            <Grid xs={6.5} md={3.25}>
                <NumericInput
                    placeholder="0.00"
                    name={kebabCase(SELLING_PRICE)}
                    onChange={(e) => setSellingPrice(e.target.value)}
                    onBlur={createOnBlurHandler({
                        name: SELLING_PRICE,
                        setValue: setSellingPrice,
                        setError: setSellingPriceError
                    })}
                    value={sellingPrice}
                    label={`${SELLING_PRICE} (required)`}
                    data-testid={kebabCase(SELLING_PRICE)}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    decimalMinLength={2}
                    inputPrefix={'$'}
                    autoInsertCommas={true}
                    error={sellingPriceError}
                />
            </Grid>
            {shouldShowCost && (
                <Grid xs={5.5} md={2.75}>
                    <NumericInput
                        placeholder="0.00"
                        name={kebabCase(COST)}
                        onChange={(e) => setCost(e.target.value)}
                        onBlur={createOnBlurHandler({ setValue: setCost })}
                        value={cost}
                        label={COST}
                        data-testid={kebabCase(COST)}
                        allowDecimal={true}
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        inputPrefix={'$'}
                        autoInsertCommas={true}
                    />
                </Grid>
            )}
            <Grid xs={6.5} md={3}>
                <NumericInput
                    placeholder="0"
                    name={kebabCase(ODOMETER)}
                    onChange={(e) => setOdometer(e.target.value ? `${+e.target.value}` : '')}
                    value={odometer}
                    label={ODOMETER}
                    data-testid={kebabCase(ODOMETER)}
                    allowDecimal={false}
                    autoInsertCommas={true}
                    required={true}
                    minValue={1}
                />
            </Grid>
        </Grid>
    );
};
