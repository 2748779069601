import React, { useEffect, useState, useMemo } from 'react';
import { SelectInput } from '@interstate/components/SelectInput';
import { Button } from '@interstate/components/Button';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';
import { Grid } from '@interstate/components/Grid';
import { changeVehicleSelectors } from '../../store/config';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar } from '@interstate/components/Snackbar';
import { Typography } from '@interstate/components/Typography';
import {
    fetchCatalogMakes,
    fetchCatalogModels,
    fetchCatalogTrims,
    fetchCatalogYears,
    fetchCatalogVehicles,
    clearCatalogVehicles
} from '../../store/actionCreators';
import BuildVehicleSearchResults from './BuildVehicleSearchResults';

export enum BuildVehicleSelects {
    inventoryType = 'inventoryType',
    year = 'year',
    make = 'make',
    model = 'model',
    trim = 'trim'
}

enum InventoryType {
    NEW = 'New',
    USED = 'Used',
    CERTIFIED = 'CPO'
}

const selectOrder = [
    BuildVehicleSelects.inventoryType,
    BuildVehicleSelects.year,
    BuildVehicleSelects.make,
    BuildVehicleSelects.model,
    BuildVehicleSelects.trim
] as const;

type SelectName = (typeof selectOrder)[number];
type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

export type InitBuildVehicleValueType = {
    vin: string | undefined;
    year: string;
    make: string;
    model: string;
    trim: string;
};

export type BuildVehicleProps = {
    initValue?: InitBuildVehicleValueType;
};

const inventoryTypeOptions = [InventoryType.NEW, InventoryType.USED, InventoryType.CERTIFIED];
const selectLabels = {
    [BuildVehicleSelects.inventoryType]: 'Inventory Type',
    [BuildVehicleSelects.year]: 'Year',
    [BuildVehicleSelects.make]: 'Make',
    [BuildVehicleSelects.model]: 'Model',
    [BuildVehicleSelects.trim]: 'Trim'
};

const inventoryTypeToVehicleConditionMap = {
    [InventoryType.NEW]: 'new',
    [InventoryType.USED]: 'used',
    [InventoryType.CERTIFIED]: 'certified'
};

const BuildVehicle = ({ initValue }: BuildVehicleProps) => {
    const [vin, setVin] = useState<string | undefined>(undefined);
    const [selectValues, setSelectValues] = useState<Record<SelectName, string>>({
        [BuildVehicleSelects.inventoryType]: '',
        [BuildVehicleSelects.year]: '',
        [BuildVehicleSelects.make]: '',
        [BuildVehicleSelects.model]: '',
        [BuildVehicleSelects.trim]: ''
    });

    const yearOptions = useSelector(changeVehicleSelectors.getCatalogYears);
    const makeOptions = useSelector(changeVehicleSelectors.getCatalogMakes);
    const modelOptions = useSelector(changeVehicleSelectors.getCatalogModels);
    const trimOptions = useSelector(changeVehicleSelectors.getCatalogTrims);
    const inventoryType = selectValues[BuildVehicleSelects.inventoryType];
    const catalogVehicles = useSelector(changeVehicleSelectors.getCatalogVehicles);
    const mappedCatalogVehicles = useMemo(() => {
        if (!inventoryType) return catalogVehicles;
        // type and condition default to Used after mapping vehicle service response so they need to be overridden to selected inventory type
        const condition = inventoryTypeToVehicleConditionMap[inventoryType as InventoryType];

        return catalogVehicles.map((vehicle) => ({
            ...vehicle,
            vin,
            condition,
            type: condition
        }));
    }, [catalogVehicles, vin, inventoryType]);
    const isFetching = useSelector(changeVehicleSelectors.getIsFetching);
    const showCatalogError = useSelector(changeVehicleSelectors.getShowCatalogError);

    const selectOptions = useMemo(
        () => ({
            [BuildVehicleSelects.inventoryType]: inventoryTypeOptions,
            [BuildVehicleSelects.year]: yearOptions,
            [BuildVehicleSelects.make]: makeOptions,
            [BuildVehicleSelects.model]: modelOptions,
            [BuildVehicleSelects.trim]: trimOptions
        }),
        [yearOptions, makeOptions, modelOptions, trimOptions]
    );
    const isCreateButtonDisabled = !selectValues[BuildVehicleSelects.inventoryType] || !selectValues[BuildVehicleSelects.trim];
    const showSearchResults = !isCreateButtonDisabled && mappedCatalogVehicles.length > 0;
    const dispatch = useDispatch();
    const canSelectValue = useMemo(
        () => ({
            [BuildVehicleSelects.inventoryType]: true,
            [BuildVehicleSelects.year]: !!selectOptions.year.length,
            [BuildVehicleSelects.make]: !!selectOptions.make.length && !!selectValues.year,
            [BuildVehicleSelects.model]: !!selectOptions.model.length && !!selectValues.make,
            [BuildVehicleSelects.trim]: !!selectOptions.trim.length && !!selectValues.model
        }),
        [selectValues, selectOptions]
    );
    const { getServicesBff } = changeVehicleSelectors.additionalSelectors;
    const bffEndpoint = useSelector(getServicesBff);

    useEffect(() => dispatch(clearCatalogVehicles()), [dispatch]);

    const handleSelectChange = (selectName: SelectName, value: any) => {
        dispatch(clearCatalogVehicles());
        if (selectName !== BuildVehicleSelects.inventoryType) {
            setVin(undefined);
        }
        setSelectValues((prevState) => ({
            ...prevState,
            [selectName]: value
        }));

        const index = selectOrder.indexOf(selectName);

        if (selectName === BuildVehicleSelects.inventoryType || index === selectOrder.length - 1) {
            return;
        }

        const startIndex = index + 1;

        selectOrder.slice(startIndex).forEach((name) => {
            setSelectValues((prevState) => ({
                ...prevState,
                [name]: ''
            }));
        });

        const selectFetchActions = {
            [BuildVehicleSelects.year]: fetchCatalogYears(bffEndpoint),
            [BuildVehicleSelects.make]: fetchCatalogMakes(bffEndpoint, value),
            [BuildVehicleSelects.model]: fetchCatalogModels(bffEndpoint, selectValues[BuildVehicleSelects.year], value),
            [BuildVehicleSelects.trim]: fetchCatalogTrims(
                bffEndpoint,
                selectValues[BuildVehicleSelects.year],
                selectValues[BuildVehicleSelects.make],
                value
            )
        };

        dispatch(selectFetchActions[selectOrder[startIndex] as Exclude<SelectName, BuildVehicleSelects.inventoryType>]);
    };

    const searchVehicles = () => {
        dispatch(fetchCatalogVehicles(bffEndpoint, selectValues.year, selectValues.make, selectValues.model, selectValues.trim));
    };

    useEffect(() => {
        dispatch(fetchCatalogYears(bffEndpoint));

        if (initValue?.year) {
            setVin(initValue.vin);
            setSelectValues({
                inventoryType: '',
                year: initValue.year,
                make: initValue.make,
                model: initValue.model,
                trim: initValue.trim
            });

            dispatch(fetchCatalogMakes(bffEndpoint, initValue.year));
            dispatch(fetchCatalogModels(bffEndpoint, initValue.year, initValue.make));
            dispatch(fetchCatalogTrims(bffEndpoint, initValue.year, initValue.make, initValue.model));
        }
    }, [initValue, dispatch, bffEndpoint]);

    useEffect(() => {
        if (initValue?.year) {
            const inventoryTypeSelect = document.querySelector('#select-input-options-inventoryType') as HTMLElement;
            inventoryTypeSelect.click();
        }
    }, [initValue]);

    return (
        <>
            <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                Enter vehicle information
            </Typography>
            <Grid spacing={1} container xs rowGap={1} data-testid="main-finance-content" flexWrap="wrap">
                {(Object.entries(selectLabels) as Entries<typeof selectLabels>).map(([name, label]) => {
                    return (
                        <Grid xs={4} alignSelf="flex-end" key={name}>
                            <SelectInput
                                label={label}
                                name={name}
                                onChange={(e) => handleSelectChange(name, e.target.value)}
                                options={
                                    selectValues[name] && !selectOptions[name].length
                                        ? [{ value: selectValues[name], label: selectValues[name] }]
                                        : selectOptions[name].map((value: string) => ({
                                              value,
                                              label: value
                                          }))
                                }
                                displayDeselectOption={false}
                                disabled={name !== BuildVehicleSelects.inventoryType && (isFetching || !canSelectValue[name])}
                                value={selectValues[name]}
                                data-testid={name}
                                id={name}
                            />
                        </Grid>
                    );
                })}
                <Grid xs={4} sm={4} lg={0.8} alignSelf="flex-end">
                    <Button
                        buttonStyle="primary"
                        icon={<ChevronRightIcon />}
                        id="build-vehicle-button"
                        data-testid="build-vehicle-button"
                        size="medium"
                        disabled={isCreateButtonDisabled}
                        onClick={searchVehicles}
                    />
                </Grid>

                <Snackbar
                    data-testid="error-notification"
                    show={!!showCatalogError}
                    message={'Failed to load data'}
                    position="top-center"
                    type="error"
                />
            </Grid>
            {showSearchResults && <BuildVehicleSearchResults vehicles={mappedCatalogVehicles} selectValues={selectValues} />}
        </>
    );
};

export default BuildVehicle;
