// externals
import React from 'react';
import { getReasonPhrase } from 'http-status-codes';

// components
import { Alert } from '@interstate/components/Alert';
import { Action } from '@interstate/components/Action';
import { Grid } from '@interstate/components/Grid';
import { PUSH_ERROR_DIALOG_HEADER_INTERSTATE } from '../pushToDmsConstants';
import { Typography } from '@interstate/components/Typography';
import { DEALER_SUPPROT_REQUEST_FORM } from '../../../constants';

export interface IPushToDmsErrorProps {
    statusCode: number;
    errorDetails: string | undefined;
}

export const PushToDmsError = (props: IPushToDmsErrorProps) => {
    const { statusCode, errorDetails } = props;

    // istanbul ignore next
    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPROT_REQUEST_FORM);
    };

    return (
        <Grid container flexDirection="column" gap={2}>
            <Alert data-testid="dms-error-modal" type="error">
                <Typography variant="h6" tag="h6">
                    {PUSH_ERROR_DIALOG_HEADER_INTERSTATE}
                </Typography>
                <Typography variant="body-sm" tag="div" data-testid="dms-error-code-text">
                    {`Error Code: ${statusCode} - ${getReasonPhrase(statusCode)}`}
                </Typography>
                {errorDetails && (
                    <Typography
                        variant="body-sm"
                        tag="div"
                        data-testid="dms-error-detail-text"
                    >{`Details: ${errorDetails}`}</Typography>
                )}
            </Alert>
            <Grid>
                <Typography variant="h6" tag="h6">
                    Please contact support:
                </Typography>
                <Typography variant="body-sm" tag="span">
                    1-888-895-2994 or create a
                </Typography>
                <Action onClick={openSupportTicketUrl} data-testid="support-ticket" sx={{ marginLeft: '4px' }}>
                    <Typography variant="anchor-inline-md" color="sem.color.surface.button.primary.rest">
                        support ticket
                    </Typography>
                </Action>
            </Grid>
        </Grid>
    );
};
