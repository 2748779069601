// externals
import React from 'react';

// libraries
import { Grid } from '@interstate/components/Grid';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

import { taxElementsToLineItems } from '../utils/transform';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';

export type TotalTaxProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const TotalTax = ({ deal, useWrapper = true, hasDelta = false }: TotalTaxProps) => {
    const state = useDealRootState(deal);

    const totalTax = offerReduxSelectors.getTotalTax(state);

    const additionalBreakdown = offerSelectors.getAdditionalTaxBreakdown(state);
    const additionalItems = taxElementsToLineItems(additionalBreakdown);
    const additionalTotal = offerSelectors.getAdditionalTaxTotal(state);

    const primaryBreakdown = offerReduxSelectors.getPrimaryTaxBreakdown(state);

    if (primaryBreakdown == null) {
        return null;
    }

    const primaryItems = taxElementsToLineItems(primaryBreakdown);
    const primaryTotal = offerSelectors.getPrimaryTaxTotal(state);

    const isPrimary = primaryTotal > 0;
    const isAdditional = additionalTotal > 0;

    return (
        <DealSummaryItemWrapper
            label={constants.TOTAL_TAXES}
            value={formatDollarsAndCents(totalTax)}
            useWrapper={useWrapper}
            hasDelta={hasDelta}
        >
            {(isPrimary || isAdditional) && (
                <Grid>
                    {isPrimary && (
                        <LineItemWithChildren
                            testId="deal-summary-lineitem-capitalized-taxes"
                            label={constants.PRIMARY_TAXES}
                            value={formatDollarsAndCents(primaryTotal)}
                            childItems={primaryItems}
                        />
                    )}
                    {isAdditional && (
                        <LineItemWithChildren
                            label={constants.ADDL_TAXES}
                            value={formatDollarsAndCents(additionalTotal)}
                            childItems={additionalItems}
                        />
                    )}
                </Grid>
            )}
        </DealSummaryItemWrapper>
    );
};

export default TotalTax;
