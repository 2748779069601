/**
 * Checks whether at least one of fields is empty
 * @param fields Trade In form fields
 * @returns
 */
export function hasEmptyField({
    vehicleCondition,
    vehicleExteriorColor,
    vehicleMake,
    vehicleMileage,
    vehicleModel,
    vehicleTrim,
    vehicleYear
}: Record<
    'vehicleMileage' | 'vehicleYear' | 'vehicleMake' | 'vehicleModel' | 'vehicleTrim' | 'vehicleExteriorColor' | 'vehicleCondition',
    string | number
>) {
    return (
        !vehicleCondition ||
        !vehicleExteriorColor ||
        !vehicleMake ||
        !vehicleMileage ||
        !vehicleModel ||
        !vehicleTrim ||
        !vehicleYear
    );
}
