// TODO: Remove this for testing.
// istanbul ignore file

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import { CONFIRM_PUSH_TO_ROUTEONE } from '../actionTypes/pushToRouteOneActionTypes';

// actions
import { pushToRouteOneRSAA } from '../actions/pushToRouteOneActionCreators';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case CONFIRM_PUSH_TO_ROUTEONE:
            store.dispatch(pushToRouteOneRSAA(MANAGER_VIEW_APP_PREFIX, state));
            break;
        default:
            break;
    }
};
