// externals
import { Action } from '@interstate/components/Action';
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import { ChevronLeftIcon } from '@interstate/components/Icons';
import { RadioButtonList } from '@interstate/components/RadioButtonList';
import { Typography } from '@interstate/components/Typography';
import { useCallback, useEffect, useState } from 'react';

// types
import { EditProductPlanProps } from '../../types';

// utils
import { findByPlanKey, getPlanOptionsFromPlans, getProductPlanKey } from '../../utils/editProductUtils';

// styles
import { ProductPlanDetails } from '@makemydeal/dr-dash-types';
import { StyledSlideOut, StyledSlideOutFooterTransparent } from '../vehicleProtection/VehicleProtection.style';

const EditProductPlanDrawer = ({
    open,
    productProps,
    plans,
    onClose,
    onEdit,
    hasInapplicablePlan
}: {
    open?: boolean;
    productProps: EditProductPlanProps;
    plans: ProductPlanDetails[];
    onClose: () => void;
    onEdit: (productCode: string, productPlan: string) => void;
    hasInapplicablePlan: boolean;
}) => {
    const [selectedPlanKey, setSelectedPlanKey] = useState<string>(getProductPlanKey(productProps));

    useEffect(() => {
        setSelectedPlanKey(hasInapplicablePlan ? getProductPlanKey(plans[0]) : getProductPlanKey(productProps));
    }, [productProps, plans, hasInapplicablePlan, setSelectedPlanKey]);

    const planOptions = getPlanOptionsFromPlans(plans);

    const handleApply = useCallback(() => {
        const selectedPlanDetails = plans.find(findByPlanKey(selectedPlanKey)) as ProductPlanDetails;
        const changedProductOrPlan = selectedPlanKey !== getProductPlanKey(productProps);

        if (selectedPlanDetails && changedProductOrPlan) {
            onEdit(selectedPlanDetails.productCode, selectedPlanDetails.productProviderPlan);
        }
        onClose();
    }, [productProps, plans, onClose, onEdit, selectedPlanKey]);

    const handleClose = useCallback(() => {
        setSelectedPlanKey(getProductPlanKey(productProps));
        onClose();
    }, [setSelectedPlanKey, productProps, onClose]);

    const isApplyButtonDisabled = hasInapplicablePlan ? false : selectedPlanKey === getProductPlanKey(productProps);

    return (
        <StyledSlideOut
            show={open}
            onHide={handleClose}
            position="right"
            header={
                <Action
                    actionIcon={{ icon: <ChevronLeftIcon />, start: true }}
                    onClick={handleClose}
                    size="xs"
                    data-testid="edit-product-plan-drawer-back"
                >
                    Back
                </Action>
            }
            data-testid="edit-product-plan-drawer"
            panelWidth={392}
            footer={{
                footerComponent: (
                    <StyledSlideOutFooterTransparent>
                        <Button
                            data-testid="edit-product-plan-drawer-cancel-btn"
                            buttonStyle="tertiary"
                            size="medium"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid="edit-product-plan-drawer-apply-btn"
                            buttonStyle="primary"
                            size="medium"
                            disabled={isApplyButtonDisabled}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </StyledSlideOutFooterTransparent>
                )
            }}
        >
            <Box marginBottom="1rem">
                <Typography variant="strong-md">Plan/Coverage</Typography>
            </Box>
            <RadioButtonList
                label=""
                displayLabel={false}
                data-testid="edit-product-plan-drawer-plan-radio-group"
                onChange={(event) => {
                    if (event.target.value) {
                        setSelectedPlanKey(event.target.value);
                    }
                }}
                options={planOptions}
                value={hasInapplicablePlan ? planOptions[0].value : getProductPlanKey(productProps)}
            />
        </StyledSlideOut>
    );
};

export default EditProductPlanDrawer;
