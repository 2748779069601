// External
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MittProvider, useMitt } from './mittProvider';

// Components
import { Button } from '@interstate/components/Button';
import { SlideOut } from '@interstate-104/components/SlideOut';

// Styles
import './custom.d';
import { DrawerFooter } from './FinishDealCard.style';

// Selectors
import { configSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { CheckoutErrorCard } from './CheckoutErrorCard';

interface CheckoutAnywhereDrawerProps {
    setShowUpdateOffer: (show: boolean) => void;
    setIsOpenSlider: (open: boolean) => void;
    isOpenSlider: boolean;
}

const CheckoutAnywhereDrawer = ({ setShowUpdateOffer, setIsOpenSlider, isOpenSlider }: CheckoutAnywhereDrawerProps) => {
    const { emitter } = useMitt();
    const [isCheckoutReady, setIsCheckoutReady] = useState(false);
    const [isScriptFailed, setIsScriptFailed] = useState(false);

    const offer = useSelector(offerSelectors.getOfferState);
    const scriptBaseUrl = useSelector(configSelectors.getCheckoutAnywhereBaseUrl);

    const loadScripts = () => {
        const scriptAlpha = document.createElement('script');
        scriptAlpha.src = `${scriptBaseUrl}/dri-checkout-launcher/latest/dri-checkout-launcher.esm.js`;
        scriptAlpha.type = 'module';
        scriptAlpha.id = 'checkout-cb-module';
        document.body.appendChild(scriptAlpha);

        const scriptBeta = document.createElement('script');
        scriptBeta.src = `${scriptBaseUrl}/dri-checkout-launcher/latest/dri-checkout-launcher.js`;
        scriptBeta.noModule = true;
        scriptBeta.id = 'checkout-cb-js';
        document.body.appendChild(scriptBeta);

        scriptBeta.addEventListener('error', () => setIsScriptFailed(true));
        scriptAlpha.addEventListener('error', () => setIsScriptFailed(true));

        return () => {
            const cbScripts = document.querySelectorAll('script[id*="checkout-cb-"]');
            cbScripts.forEach((elem) => elem.remove());
        };
    };

    useEffect(() => {
        return loadScripts();
    }, []);

    useEffect(() => {
        setShowUpdateOffer(false);

        const handleLauncherEvent = () => {
            setIsCheckoutReady(false);
            emitter.emit('dri-checkout-launcher.checkout', { dealXgId: offer.dealXgId });
        };

        const handleDataEvent = () => {
            setIsCheckoutReady(true);
        };

        const handleErrorEvent = () => {
            setIsCheckoutReady(true);
        };

        emitter.on('dri-checkout-launcher.event', handleLauncherEvent);
        emitter.on('dri-checkout-launcher.data', handleDataEvent);
        emitter.on('dri-checkout-launcher.error', handleErrorEvent);

        setIsOpenSlider(true);
    }, [emitter, offer.dealXgId, setIsOpenSlider, setShowUpdateOffer]);

    const handleCloseSlider = () => {
        setIsOpenSlider(false);
        emitter.emit('dri-checkout-launcher.close');
    };

    return (
        <SlideOut
            show={isOpenSlider}
            header=""
            data-testid="checkout-anywhere-drawer"
            position="right"
            panelWidth="auto"
            sx={{
                '.MuiPaper-root': {
                    width: { xs: 1, md: 584 }
                },
                '.interstate-close-sliding-drawer-button ': {
                    margin: '1rem'
                },
                '.interstate-sliding-drawer-dialog-title-container': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: '-35px'
                },
                '.MuiDialogContent-root': {
                    paddingBottom: '1rem'
                },
                '.MuiBackdrop-root': {
                    backgroundColor: '#6e6e6e'
                },
                '.ids-SlideOut-content': {
                    marginTop: '-40px'
                },
                '#ids-slideout-close-button': {
                    zIndex: 10
                },
                '#sliding-drawer-footer-actions': {
                    display: 'none'
                }
            }}
            onHide={handleCloseSlider}
        >
            <MittProvider>
                {isScriptFailed && <CheckoutErrorCard handleClick={handleCloseSlider} />}
                <dri-checkout-launcher />
                {isCheckoutReady && (
                    <DrawerFooter>
                        <Button data-testid="ca-close-button" onClick={handleCloseSlider}>
                            Close
                        </Button>
                    </DrawerFooter>
                )}
            </MittProvider>
        </SlideOut>
    );
};

export default CheckoutAnywhereDrawer;
