// externals
import { isEmpty } from 'lodash';

// libraries
import { CalculationDetails, ProductCalculationEngineService } from '@makemydeal/dr-dash-bff-types';
import type { CalculationDetailsState, StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// selectors
import * as offerReduxSelectors from './offerRedux';

export const getCalculationDetailsState = (state: StateTree): CalculationDetailsState => {
    return state.calculationDetails || ({} as any);
};

export const getCalculationDetails = (state: StateTree): CalculationDetails | undefined => {
    const calculationDetails = getCalculationDetailsState(state);

    if (isEmpty(calculationDetails)) {
        return undefined;
    }

    return calculationDetails;
};

export const createCalculationDetailsForOfferSave = (state: StateTree): CalculationDetails | undefined => {
    const useCalculationEndpoint = featureToggleSelectors.isCalculationEndpointEnabled(state);
    const rootCalcDetails = getCalculationDetails(state);

    if (useCalculationEndpoint) {
        const currentCalculationEndpoint = offerReduxSelectors.getCurrentCalculationEndpoint(state);
        if (rootCalcDetails) {
            const updatedCalcDetails = {
                ...rootCalcDetails,
                payment: {
                    .../* istanbul ignore next */ rootCalcDetails?.payment,
                    calculationEngine: {
                        .../* istanbul ignore next */ rootCalcDetails.payment?.calculationEngine,
                        endpoint: currentCalculationEndpoint
                    }
                }
            };
            return updatedCalcDetails;
        }
        return {
            payment: {
                calculationEngine: {
                    endpoint: currentCalculationEndpoint
                }
            }
        };
    } else {
        return rootCalcDetails;
    }
};

export const getPartnerCode = (state: StateTree): string | undefined => {
    return getCalculationDetailsState(state).payment?.calculationSourceIds?.partnerCode;
};

export const getPartnerDealerId = (state: StateTree): string | number | undefined => {
    return getCalculationDetailsState(state).payment?.calculationSourceIds?.partnerDealerId;
};

export const getProductsCalculationEngineService = (state: StateTree): ProductCalculationEngineService | undefined => {
    return getCalculationDetailsState(state).products?.calculationEngine?.service;
};

export const isFieCalculationEngineService = (state: StateTree): boolean => {
    const productsService = getProductsCalculationEngineService(state);

    return productsService === ProductCalculationEngineService.COX_FIE || productsService === undefined;
};
