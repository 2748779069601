// istanbul ignore file
import styled from 'styled-components';

export type StyledExpandableAreaPropTypes = {
    expanded: boolean;
    height: number;
    initializing: boolean;
    duration: number;
};

export const StyledExpandableArea = styled.div<StyledExpandableAreaPropTypes>`
    ${({ initializing, duration }) => (initializing ? '' : `transition: margin ${duration}ms ease;`)}
    margin-top: ${({ expanded, height }) => (expanded ? '0px' : `-${height}px`)};
`;

export const ExpandableAreaContainer = styled.div`
    overflow: hidden;
    position: relative;
`;
