import { Badge } from '@interstate/components/Badge';
import { Card, CardFooter } from '@interstate/components/Card';
import { Flag } from '@interstate/components/Flag';
import { CheckmarkSolidIcon, DocumentTextIcon, ExclamationCircleIcon } from '@interstate/components/Icons';
import { formatDollars, kebabCase } from '@makemydeal/dr-common-utils';
import { OfferLenderDecisionPayload, configSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { DealerDecisionStatus } from '@makemydeal/dr-dash-types';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getOfferDetailsHref } from '../../utils/offerUtils';
import { CardElement, InfoContainer, InfoDetail, LogoContainer } from './LenderDecisionCard.style';

const LenderDecisionCard = ({ data }: { data: OfferLenderDecisionPayload }) => {
    const {
        approvalStatus,
        approvedAmount,
        approvedRate,
        decisionShown,
        buyRate,
        monthlyPayment,
        approvedTerm,
        lenderId,
        lenderName,
        lenderLogo
    } = data;
    const [imageFetchError, setImageFetchError] = useState(false);
    const fsDealRefIdEnabled = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const enableLenderLogos = useSelector(featureToggleSelectors.isLenderLogosEnabled);
    const toggleEnableBuyRateForCreditDecision = useSelector(featureToggleSelectors.isEnableBuyRateForCreditDecision);
    const dealRefId = useSelector(offerSelectors.getDealRefId);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const isRouteOneDealer = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const link = getOfferDetailsHref(configUrl, fsDealRefIdEnabled, dealRefId, lenderId);

    const handleImageFetchError = () => {
        setImageFetchError(true);
    };

    return (
        <CardElement>
            <Card
                sx={{
                    '.MuiCardActions-root': {
                        justifyContent: 'flex-end'
                    }
                }}
                header={
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                        {decisionShown && (
                            <Flag variant="info" data-testid="badge-customer-viewing">
                                Active Automated Decision
                            </Flag>
                        )}
                        <LogoContainer>
                            <div>
                                <InfoDetail>
                                    <span data-testid={`decisionCard-lenderName-${kebabCase(lenderName)}`}>
                                        {!enableLenderLogos || imageFetchError || !lenderLogo ? (
                                            lenderName
                                        ) : (
                                            <img
                                                className="decisionCardLenderLogo"
                                                data-testid={`decisionCard-lenderLogo-${kebabCase(lenderName)}`}
                                                onError={handleImageFetchError}
                                                src={lenderLogo}
                                            />
                                        )}
                                    </span>
                                </InfoDetail>
                            </div>
                            <div>
                                {approvalStatus === DealerDecisionStatus.APPROVED && (
                                    <Badge
                                        htmlId="badgeGreen"
                                        variant="success"
                                        startIcon={<CheckmarkSolidIcon />}
                                        className="statusContainer"
                                    >
                                        <span>{approvalStatus}</span>
                                    </Badge>
                                )}
                                {(approvalStatus === DealerDecisionStatus.CONDITIONALLY_APPROVED ||
                                    approvalStatus === DealerDecisionStatus.COUNTER_OFFER) && (
                                    <Badge
                                        htmlId="badgeYellow"
                                        startIcon={<DocumentTextIcon />}
                                        variant="warning"
                                        className="statusContainer"
                                    >
                                        <span>Check Details</span>
                                    </Badge>
                                )}
                                {approvalStatus === DealerDecisionStatus.DECLINED && (
                                    <Badge
                                        startIcon={<ExclamationCircleIcon />}
                                        htmlId="badgeRed"
                                        variant="error"
                                        className="statusContainer"
                                    >
                                        <span>{approvalStatus}</span>
                                    </Badge>
                                )}
                            </div>
                        </LogoContainer>
                    </div>
                }
                id="LenderDecisionCard"
                className="cardContainer"
                content={
                    <InfoContainer>
                        <InfoDetail>
                            <span>Amount</span>
                            <span>{formatDollars(approvedAmount)}</span>
                        </InfoDetail>
                        <InfoDetail>
                            <span>Term</span>
                            <span>{approvedTerm}mos</span>
                        </InfoDetail>
                        <InfoDetail>
                            <span>Buy Rate</span>
                            {toggleEnableBuyRateForCreditDecision ? (
                                buyRate ? (
                                    <span>{buyRate}%</span>
                                ) : (
                                    <span></span>
                                )
                            ) : (
                                <span>{approvedRate}%</span>
                            )}
                        </InfoDetail>
                        <InfoDetail>
                            <span>Payment</span>
                            <span>{monthlyPayment}</span>
                        </InfoDetail>
                    </InfoContainer>
                }
                footer={
                    isRouteOneDealer ? null : (
                        <CardFooter
                            id={'card-footer-id'}
                            data-testid={'card-footer-data-testid'}
                            actions={[
                                {
                                    label: 'Offer Details',
                                    id: 'offer-details-link',
                                    'data-testid': 'offerDetails-link',
                                    onClick: () => window.open(link, '_blank', 'rel=noreferrer')
                                }
                            ]}
                        />
                    )
                }
            />
        </CardElement>
    );
};

export { LenderDecisionCard };
