import React, { useMemo } from 'react';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, SECURITY_DEPOSIT } from '../../constants';
import { deskingSelectors, offerActionTypes, offerReduxActionCreators, offerSelectors } from '@makemydeal/dr-dash-store';
import { usePaymentCall } from '../../utils/usePaymentCall';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { useSelector } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';

const SecurityDepositField = () => {
    const {
        value: depositValue,
        handleChange: handleDepositeChanged,
        handleBlur: handleDepositBlurred
    } = usePaymentCall(
        offerSelectors.getSecurityDepositOverrideWithFallback,
        offerReduxActionCreators.updateSecurityDepositAmountOverride,
        { formatValueToString: (amount: any) => formatDollarsAndCents(amount) }
    );

    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_SECURITY_DEPOSIT_OVERRIDE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    return (
        <NumericInput
            label={SECURITY_DEPOSIT}
            name={kebabCase(SECURITY_DEPOSIT)}
            onChange={handleDepositeChanged}
            onBlur={handleDepositBlurred}
            placeholder={'0.00'}
            error={failedMessage}
            value={depositValue}
            data-testid={kebabCase(SECURITY_DEPOSIT)}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas={true}
        />
    );
};

export default SecurityDepositField;
