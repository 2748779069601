import { IVehicle } from '@makemydeal/dr-platform-types';
import { useMemo } from 'react';

const isValidCviVehicle = (vehicle: IVehicle): boolean => {
    const { listedPrice, mileage, condition } = vehicle;

    const minMileageValue = condition === 'new' ? 0 : 1;

    const isValidMileage = typeof mileage === 'number' && mileage >= minMileageValue;
    const isValidListedPrice = typeof listedPrice === 'number' && listedPrice > 0;

    return isValidMileage && isValidListedPrice;
};

const isValidCustomVehicle = (vehicle: IVehicle): boolean => {
    const { listedPrice, mileage, retailPrice } = vehicle;

    return !!retailPrice && !!listedPrice && !!mileage;
};

export const useValidateVehicle = (vehicle: IVehicle): boolean | undefined => {
    return useMemo(() => {
        const { vin, stockNumber, chromeStyleId } = vehicle;

        if (stockNumber && vin) {
            return isValidCviVehicle(vehicle);
        }
        if (chromeStyleId) {
            return isValidCustomVehicle(vehicle);
        }

        return undefined;
    }, [vehicle]);
};
