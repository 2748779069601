// libraries
import { toast } from '@cx/ui/Toast';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import type { AnyFSA } from '@makemydeal/dr-platform-shared';

// consts/enums
import { actions } from '@makemydeal/dr-offer-redux';
import { OFFER_SAVE_FAILURE, OFFER_SAVE_NOCHANGE_SUCCESS, OFFER_SAVE_SUCCESS } from '../actionTypes/offerActionTypes';

// utils
import { saveOffer, updateManualIncentives } from '../actions/offerActionCreators';
import * as navigationActionCreators from '../actions/navigationActionCreators';
import { addManualAccessories } from '../actions/accessoriesActionCreators';
import { unselectAllVppProducts, updateVppSurchargesAttributes } from '../actions/vehicleProtectionActionCreators';
import * as rollbackSelectors from '../selectors/rollbackSelectors';
import { featureToggleSelectors, rollbackActionCreators } from '@makemydeal/dr-shared-store';
import {
    changeVehicleCreators,
    changeVehicleSelectors,
    CHANGE_VEHICLE_CLICKED,
    VEHICLE_CHANGE_SUCCESS
} from '@makemydeal/dr-activities-change-vehicle';
import { getSelectedManualAccessories } from '../selectors/accessoriesSelectors';
import { getVppSurchargesOverrides } from '../selectors/vehicleProtectionSelectors';

import { actions as offerReduxActions } from '@makemydeal/dr-offer-redux';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    switch (action.type) {
        case actions.PAYMENT_RECEIVED: {
            if (action.meta?.originalAction.type === actions.SWITCH_VEHICLE) {
                store.dispatch(saveOffer());
            }
            break;
        }
        case actions.PAYMENT_FAILURE: {
            if (action.meta?.originalAction.type === actions.SWITCH_VEHICLE) {
                const state = store.getState();
                const enableStateRollback = featureToggleSelectors.getFeatureToggle(state, 'enableStateRollback');
                const originalState = rollbackSelectors.getRollbackState(state);
                if (enableStateRollback) {
                    store.dispatch(rollbackActionCreators.rollbackAppStateForSwitchVehicle(originalState));
                }
                store.dispatch(changeVehicleCreators.vehicleChangeFailure());
            }
            break;
        }
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case OFFER_SAVE_SUCCESS: {
            if (changeVehicleSelectors.isChangeProcessing(store.getState())) {
                const surchargesOverrides = getVppSurchargesOverrides(store.getState());
                store.dispatch(updateVppSurchargesAttributes(surchargesOverrides));
                store.dispatch(changeVehicleCreators.vehicleChangeSuccess());
            }
            break;
        }
        case VEHICLE_CHANGE_SUCCESS: {
            toast.success('Vehicle Successfully Changed!');
            store.dispatch(navigationActionCreators.navigateToDashboard());
            break;
        }
        case CHANGE_VEHICLE_CLICKED: {
            store.dispatch(rollbackActionCreators.prepareStateCopy(store.getState()));
            store.dispatch(unselectAllVppProducts({ skipPayment: true }));
            const selectedManualAccessories = getSelectedManualAccessories(store.getState());
            store.dispatch(addManualAccessories(selectedManualAccessories, { skipPayment: true }));
            store.dispatch(
                updateManualIncentives({
                    manualIncentives: [],
                    dealerCashTotal: 0,
                    totalRebates: 0,
                    skipPayment: true
                })
            );
            break;
        }
        case OFFER_SAVE_FAILURE: {
            store.dispatch(changeVehicleCreators.vehicleChangeFailure());
            break;
        }
        case offerReduxActions.SWITCH_VEHICLE: {
            store.dispatch(changeVehicleCreators.processSwitchVehicle());
            break;
        }
        default:
            break;
    }
};
