// externals
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import defaults from 'lodash.defaults';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

// utils
import { convertToNumber, isNaNish } from './formatUtils';
import { useDebounce } from './useDebounce';
import { DEBOUNCE_TIMER } from '../constants';

// optional props
type UsePaymentCallOptionsParam = {
    formatValueToString?: (value: any) => string;
    parseValue?: (text: any) => any; // parse text to the value used in the underlying action creator. i.e. number | string, etc
    ignoreTextValue?: (text: string) => boolean;
};

type UsePaymentCallOptions = {
    formatValueToString: (value: any) => string;
    parseValue: (text: string) => any; // parse text to the value used in the underlying action creator. i.e. number | string, etc
    ignoreTextValue: (text: string) => boolean;
};

type PaymentInputEvent = (e: any) => void;

type PaymentCallResult = {
    value: string;
    handleChange: PaymentInputEvent;
    handleBlur: PaymentInputEvent;
    cancelChange: () => void;
};

/**
 * This is a custom hook used for sharing the logic of making payment calls based on textbox input changes
 * @param selector The selector that provides the initial value for the input
 * @param actionCreator The wrapped action creator
 * @param options Optional arguments: toString: (any) => string, toValue: (string) => any
 * @returns A tuple containing the current component state value and a change handler for this value
 */
export const usePaymentCall = (
    selector: (state: StateTree) => any,
    actionCreator: (...args: any[]) => any,
    options: UsePaymentCallOptionsParam = {}
): PaymentCallResult => {
    // null check and set any missing props
    defaults(options, {
        formatValueToString: (v: any) => `${v}`,
        parseValue: convertToNumber,
        ignoreTextValue: isNaNish // assuming numerics; we'll ignore any text that has no numbers.
    });

    // type casting to prevent compiler undefined check warnings.
    const _options = options as UsePaymentCallOptions;

    // destructuring for legibility
    const { formatValueToString, parseValue, ignoreTextValue } = _options;

    // get the initial field value
    const selectorValue = useSelector(selector);

    // converted value to watch/store at the component level.
    const [value, setValue] = React.useState(selectorValue);

    // Store the value in the text box for input control
    const [textValue, setTextValue] = React.useState(formatValueToString(selectorValue));

    // wrap the action creator with dispatch
    const dispatch = useDispatch();

    const dispatchAction = (...args: any[]) => dispatch(actionCreator(...args));

    // ensure the payment call is not mdade on every keystroke
    const [debouncedActionCreator, cancelChange] = useDebounce(dispatchAction, DEBOUNCE_TIMER, [actionCreator]);
    // ensure we kill any executions of payment calls when unmounting the component

    // DOM event delegates for the input
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const formatted = formatValueToString(value);
        setTextValue(formatted);
    };

    // NOTE: pass down store changes to update; for legibility, using long-form function.
    useEffect(() => {
        return () => cancelChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // construct/destruct

    // NOTE: we're watching the text input of the component and updating the converted value. If valid, we're trigging upstream changes in redux
    useEffect(() => {
        const newValue = parseValue(textValue); // brute force strips non-numerics and then convert. ($-- == 0)
        setValue(newValue);
        if (newValue === value) return; // if local state matches the newly converted value, ignore this
        if (!ignoreTextValue(textValue)) {
            debouncedActionCreator(newValue);
        } else {
            cancelChange();
        }
    }, [textValue]);

    useEffect(() => {
        if (selectorValue !== value) {
            setValue(selectorValue); // set to same value; to prevent override dispatch
            setTextValue(formatValueToString(selectorValue)); // will update text without dispatching action
        }
    }, [selectorValue]);

    return { value: textValue, handleChange, handleBlur, cancelChange };
};
