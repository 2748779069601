// externals
import React, { FC, useMemo } from 'react';

// libraries
import { deskingSelectors, offerActionTypes, offerReduxActionCreators, offerSelectors } from '@makemydeal/dr-dash-store';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';

// components
import { NumericInput } from '@interstate/components/NumericInput';

// consts/enums
import { GENERIC_LAST_FIELD_CHANGED_ERROR, RETAIL_PRICE } from '../../constants';

// actions
import { usePaymentCall } from '../../utils/usePaymentCall';
import { useSelector } from 'react-redux';

const RetailPriceField: FC = () => {
    const {
        value: retailPrice,
        handleChange: handleRetailPriceChange,
        handleBlur
    } = usePaymentCall(
        offerSelectors.getRetailPriceOverrideWithFallback,
        offerReduxActionCreators.updateVehicleRetailPriceOverride,
        {
            formatValueToString: formatDollarsAndCents
        }
    );

    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_RETAIL_PRICE_OVERRIDE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    return (
        <NumericInput
            label={`MSRP/${RETAIL_PRICE}`}
            name={kebabCase(RETAIL_PRICE)}
            onChange={handleRetailPriceChange}
            placeholder="26,500.00"
            value={retailPrice}
            error={failedMessage}
            onBlur={handleBlur}
            data-testid={kebabCase(RETAIL_PRICE)}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas={true}
            maxValue={9999999}
        />
    );
};

export default RetailPriceField;
