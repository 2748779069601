import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { formatNumber, kebabCase } from '@makemydeal/dr-common-utils';
import { tradeInActionCreators, tradeInComponentSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { TextInputEventValue } from '@interstate-104/components/TextInput';
import { Typography } from '@interstate-104/components/Typography';
import { InterstateOnChangeEvent } from '@interstate-104/components/InterstateEvents';

// consts
import { ODOMETER } from '../../../constants';

// styling
import { DetailContainer } from '../trade.interstate.style';
import { NumericInput } from '@interstate-104/components/NumericInput';

interface OdometerFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const OdometerField: React.FC<OdometerFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);

    const onOdometerChange = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = event.target.value;
        dispatch(tradeInActionCreators.updateTradeInOdometer(value as any));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                mileage: Number(value)
            })
        );
        setShowError(!value || value === '0');
    };

    const onOdometerBlur = () => {
        const odometer = document.getElementById('odometer') as HTMLInputElement;
        odometer.value = formatNumber(parseInt(odometer.value));
        setShowError(odometer.value === '0');
    };

    useEffect(() => {
        if (!isManualFormOpen && showError) {
            setShowError(false);
        }
    }, [isManualFormOpen, showError]);

    return (
        <>
            {isManualFormOpen ? (
                <NumericInput
                    label={ODOMETER}
                    id="odometer"
                    data-testid="odometer"
                    name={kebabCase(ODOMETER)}
                    onChange={onOdometerChange}
                    onBlur={onOdometerBlur}
                    placeholder="0"
                    value={`${vehicleMileage}`}
                    required
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {ODOMETER}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-odometer-value-label">
                                {formatNumber(vehicleMileage)}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};

export default OdometerField;
