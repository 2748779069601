import styled from 'styled-components';

export const CenteredLoadingIndicator = styled.div.attrs((props: any) => ({
    ['data-testid' as any]: props['data-testid']
}))`
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
