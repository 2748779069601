// externals
import React from 'react';

// components
import Button from '@cx/ui/Button';
import { IconComplete, IconClose } from '@cx/ui/Icons';

export type ActionButtonProps = {
    status: 'ready' | 'sending' | 'success' | 'failure';
    prefix: string;
    successAction: string;
    readyPrefix: string;
    onAction: () => void;
    actionText: string;
    loadingText: string;
    disabled?: boolean;
};

const getButtonStyle = (status: string) => {
    switch (status) {
        case 'sending':
        case 'ready':
            return 'primary';
        case 'success':
            return 'success';
        default:
            return 'danger';
    }
};

const getClassName = (status: string) => {
    switch (status) {
        case 'failure':
        case 'success':
            return 'customSizeBtn';
        default:
            return '';
    }
};

const getRole = (status: string, readyPrefix: string) => {
    switch (status) {
        case 'ready':
            return readyPrefix;
        case 'sending':
            return 'sending-button';
        case 'success':
            return 'success-button';
        default:
            return 'failure-button';
    }
};

const getIcon = (status: string, prefix: string, successAction: string) => {
    if (status === 'success') {
        return <IconComplete htmlId={`${prefix}-success-icon`} data-testid={`${prefix}-${successAction}-success-icon`} />;
    } else if (status === 'failure') {
        return <IconClose htmlId={`${prefix}-failure-icon`} data-testid={`${prefix}-${successAction}-failure-icon`} />;
    }
    return null;
};

const ActionButton = (props: ActionButtonProps) => {
    const { status, prefix, onAction, actionText, loadingText, successAction, readyPrefix, disabled: disabledProp } = props;

    const disabled = status === 'sending' || status === 'success' || status === 'failure' || disabledProp;

    return (
        <Button
            htmlId={`${prefix}-action-button`}
            data-testid={`${prefix}-${getRole(status, readyPrefix)}`}
            onClick={onAction}
            buttonStyle={getButtonStyle(status)}
            icon={getIcon(status, prefix, successAction)}
            disabled={disabled}
            className={getClassName(status)}
        >
            {status === 'sending' ? loadingText : actionText}
        </Button>
    );
};

export default ActionButton;
