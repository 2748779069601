// externals
import { rem } from 'polished';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import * as dashStore from '@makemydeal/dr-dash-store';
import * as sharedStore from '@makemydeal/dr-shared-store';

// components
import { Action } from '@interstate-104/components/Action';
import { Box } from '@interstate-104/components/Box';
import { InterstateCreditBureauSeparator } from './CreditBureau.styles';
import SelfReportedCreditRatingWithScore from './common/SelfReportedCreditRatingWithScore';
import { Typography } from '@interstate-104/components/Typography';

const CreditBureauWithScore = () => {
    const dispatch = useDispatch();

    const dealRefId: string = useSelector(dashStore.offerReduxSelectors.getDealRefId);
    const dealRefIdFS: string = useSelector(dashStore.offerReduxSelectors.getDealRefIdFS);
    const fsDealRefId: string = useSelector(dashStore.offerReduxSelectors.getFsDealRefId);
    const dealIdExists = fsDealRefId.length > 0 || dealRefIdFS.length > 0 || dealRefId.length > 0;

    const fsDealRefIdEnabled: boolean = useSelector(sharedStore.featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const creditBureauEnabled: boolean = useSelector(sharedStore.featureToggleSelectors.isCreditBureauEnabled);
    const isCreditProviderRouteOne: boolean = useSelector(sharedStore.dealerSelectors.isCreditProviderRouteOne);

    const showCreditBureau = dealIdExists && fsDealRefIdEnabled && creditBureauEnabled;

    return showCreditBureau ? (
        <Box display="flex" flexDirection="column" paddingTop={rem(16)}>
            <InterstateCreditBureauSeparator />
            <Box display="flex" justifyContent="space-between">
                <Typography tag="span" variant="h5">
                    Self Reported Credit Score
                </Typography>

                <Action
                    data-testid="credit-bureau-link"
                    size="sm"
                    onClick={() => dispatch(dashStore.navigationActionCreators.navigateToCreditBureau())}
                    sx={{ display: 'flex', justifyContent: 'start' }}
                >
                    {!isCreditProviderRouteOne && 'Credit Bureau Scores'}
                </Action>
            </Box>
            <br />
            <SelfReportedCreditRatingWithScore />
        </Box>
    ) : null;
};

export default CreditBureauWithScore;
