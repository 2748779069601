// externals
import React from 'react';

// libraries
import { SimpleTable, TableCell } from '@interstate-104/components/SimpleTable';

// components
import { TableHead } from './TableHead';
import { TableBody } from '../taxes/Taxes/TableBody';
import { DefaultFallback } from '../common/taxesAndFeesComponents';
// styles
import {
    FINANCE_MIN_WIDTH,
    FINANCE_NAME_MIN_WIDTH,
    LEASE_MIN_WIDTH,
    LEASE_NAME_MIN_WIDTH,
    StyledTableCell
} from './FeesCard.style';

// consts/enums
import { DATA_DENSITY } from '../common/taxesAndFeesConsts';
import { FINANCE_FEETABLECOLUMNS, LEASE_FEETABLECOLUMNS } from './constants';

type FallbackData = {
    cellName: string;
    minWidth: number;
    textAlign?: string;
    justifyContent?: string;
};

export type EmptyCategoryFallbackProps = {
    isLease: boolean;
};

export const EmptyFeeCategoryFallback: React.FC<EmptyCategoryFallbackProps> = ({ isLease }) => {
    const fallbackData: FallbackData[] = [
        { cellName: 'fallback-fee-code', minWidth: isLease ? LEASE_MIN_WIDTH : FINANCE_MIN_WIDTH },
        {
            cellName: 'fallback-fee-name',
            minWidth: isLease ? LEASE_NAME_MIN_WIDTH : FINANCE_NAME_MIN_WIDTH,
            justifyContent: 'center'
        }
    ];
    if (isLease) {
        fallbackData.push({
            cellName: 'fallback-fee-upfront',
            minWidth: LEASE_MIN_WIDTH,
            justifyContent: 'center'
        });
    }
    fallbackData.push({
        cellName: 'fallback-fee-amount',
        minWidth: isLease ? LEASE_MIN_WIDTH : FINANCE_MIN_WIDTH,
        justifyContent: 'flex-end'
    });

    const renderRow = (data: FallbackData[]) =>
        data.map((item, index) => {
            const { cellName, justifyContent, minWidth } = item;
            return (
                <TableCell key={`fallback-cell-${index}`}>
                    <StyledTableCell
                        data-testid={`simple-table-cell-${cellName}`}
                        $minWidth={minWidth}
                        $justifyContent={justifyContent}
                    >
                        <DefaultFallback />
                    </StyledTableCell>
                </TableCell>
            );
        });

    const tableColumns = isLease ? LEASE_FEETABLECOLUMNS : FINANCE_FEETABLECOLUMNS;
    return (
        <SimpleTable data-testid="fallback-table" dataDensity={DATA_DENSITY} background="white">
            <TableHead columns={tableColumns} />
            <TableBody data={[fallbackData]} renderRow={renderRow} />
        </SimpleTable>
    );
};

export default EmptyFeeCategoryFallback;
