import { ITradeInState } from '@makemydeal/dr-activities-common';
import * as tradeActionTypes from '../../actionTypes/tradeActionTypes';

interface ITradeAction {
    type: string;
    payload: any;
    meta?: {
        middleware: {
            payment: boolean;
        };
    };
}

export const tradeFlowComplete = () => {
    return {
        type: tradeActionTypes.TRADE_FLOW_COMPLETED
    };
};

export const updateTradeIn = (payload: ITradeInState) => ({
    type: tradeActionTypes.UPDATE_TRADE_IN,
    payload
});

export const allowanceChanged = (payload: number, makePaymentCall = true) => {
    const action: ITradeAction = {
        type: tradeActionTypes.ALLOWANCE_CHANGED,
        payload
    };
    if (makePaymentCall) {
        action.meta = {
            middleware: {
                payment: true
            }
        };
    }
    return action;
};

export const payoffChanged = (payload: number, makePaymentCall = true) => {
    const action: ITradeAction = {
        type: tradeActionTypes.PAYOFF_CHANGED,
        payload
    };
    if (makePaymentCall) {
        action.meta = {
            middleware: {
                payment: true
            }
        };
    }
    return action;
};

export const acvChanged = (payload: number, makePaymentCall = true) => {
    const action: ITradeAction = {
        type: tradeActionTypes.ACV_CHANGED,
        payload
    };
    if (makePaymentCall) {
        action.meta = {
            middleware: {
                payment: true
            }
        };
    }
    return action;
};
