// externals
import { useSelector } from 'react-redux';
import { TextInput } from '@interstate/components/TextInput';

// libraries
import { formatDate } from './utils';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { paymentSelectors } from '@makemydeal/dr-offer-redux';

// consts
import { FIRST_PAYMENT_DAY } from '../../constants';

const FirstPaymentDayField = () => {
    const firstPaymentDueDate = useSelector(paymentSelectors.getSelectedTermDaysToFirstPayment);
    const formattedDate = formatDate(firstPaymentDueDate);

    /**
     * CX requires an onchange handler prop, but this is a disabled and readOnly field so we can ignore its behavior for coverage
     */
    // istanbul ignore next
    const onChangeNoop = () => true;

    return (
        <TextInput
            readOnly
            value={formattedDate || 'N/A'}
            onChange={onChangeNoop}
            label={FIRST_PAYMENT_DAY}
            name={kebabCase(FIRST_PAYMENT_DAY)}
            htmlId={kebabCase(FIRST_PAYMENT_DAY)}
            data-testid={kebabCase(FIRST_PAYMENT_DAY)}
        />
    );
};

export default FirstPaymentDayField;
