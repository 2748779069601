import { SearchableSelectOptions } from '@cx/ui/SearchableSelect';
import { dateUtils } from '@makemydeal/dr-common-utils';
import numeral from 'numeral';

export const termIdToOptionMapper = ({ id, months }: any): SearchableSelectOptions => ({
    value: id,
    label: `${months} Months`
});

export const formatAnnualMiles = (miles: number): string => `${numeral(miles).format('0,0')} Miles`;

export const parseSelectedTermMonths = (termId: string): string | undefined => {
    if (!termId) {
        return undefined;
    }
    const parts = termId.split('_');
    return parts[2];
};

export const addValueSuffix = (value: string): string => {
    if (value === '') {
        return value;
    }

    if (value === '1') {
        return `${value} Month`;
    }

    return `${value} Months`;
};

export const removeValueSuffix = (value: string): string => {
    return value.replace(' Months', '').replace(' Month', '');
};

export const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    return isoDate && dateUtils.isValidDate(date) ? dateUtils.formatUtcDateTimeToDateOnly(isoDate, 'mmddyyyy') : '';
};
