// externals
import React from 'react';

// libraries
import type { DealHistory, DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// consts
import { GROSS_CAP_COST } from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const GrossCapCost = ({ deal, hasDelta }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const state = useDealRootState(deal);
    const capCostTotal = offerReduxSelectors.getCapCostTotal(state);
    const value = formatDollarsAndCents(capCostTotal);

    return <DealSummaryItem hasDelta={hasDelta} label={GROSS_CAP_COST} value={value} />;
};

export default GrossCapCost;
