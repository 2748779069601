import React from 'react';
import { useSelector } from 'react-redux';
import { vehicleSelectors } from '@makemydeal/dr-dash-store';
import { StyledIconFactory } from './HeaderVehicleImage.style.interstate';
import { configSelectors } from '@makemydeal/dr-dash-store';
import { IConfigObj } from '@makemydeal/dr-common-utils';

const HeaderVehicleImage = () => {
    const [useFallbackImage, setUseFallbackImage] = React.useState(false);
    const handleImgError = () => {
        setUseFallbackImage(true);
    };

    const imageUrl = useSelector(vehicleSelectors.getVehicleImageUrl);
    const year = useSelector(vehicleSelectors.getVehicleYear);
    const make = useSelector(vehicleSelectors.getVehicleMake);
    const model = useSelector(vehicleSelectors.getVehicleModel);
    const exteriorColor = useSelector(vehicleSelectors.getVehicleExteriorColor);
    const staticImages = useSelector(configSelectors.getConfigStaticImages);
    const imageDescription = `Photo of a ${year} ${exteriorColor} ${make} ${model}`;

    if (useFallbackImage) {
        return <StyledIconFactory staticImages={staticImages as IConfigObj} icon="defaultVehicleImage" />;
    }

    return (
        <img
            data-testid="vehicle-image"
            alt={imageDescription}
            className="img-responsive img-rounded"
            src={imageUrl}
            onError={handleImgError}
        />
    );
};
export default HeaderVehicleImage;
