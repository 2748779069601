import React from 'react';
import { IOfferCardHeaderProps } from './types/offerCardHeaderProps';
import { Typography } from '@interstate/components/Typography';
import { getHeaderLabel } from '../utils/getHeaderLabel';

export const OfferCardHeader: React.FC<IOfferCardHeaderProps> = ({
    shopperFirstName,
    shopperLastName,
    createdOnDate,
    createdOnTime,
    offerSource,
    isDefaultOffer,
    isMostRecentDealerOffer,
    isMostRecentShopperOffer,
    showSubHeader = true
}: IOfferCardHeaderProps) => {
    if (!createdOnDate || !createdOnTime) {
        return null;
    }

    if (isDefaultOffer) {
        return (
            <Typography variant="h4" tag="span">
                Default Offer
            </Typography>
        );
    } else {
        // istanbul ignore next
        const sentBy = offerSource?.toLowerCase() === 'dealmaker' ? `You` : `${shopperFirstName}  ${shopperLastName}`;

        return (
            <>
                <Typography variant="h4" data-testid="most-recent">
                    {getHeaderLabel(isMostRecentDealerOffer, isMostRecentShopperOffer, offerSource)}
                </Typography>
                {showSubHeader && (
                    <Typography variant="body-sm" tag="span">
                        {`Sent By: ${sentBy} on ${createdOnDate} at ${createdOnTime}`}
                    </Typography>
                )}
            </>
        );
    }
};

export default OfferCardHeader;
