// externals
import React, { memo } from 'react';

// libraries
import { Box } from '@interstate-104/components/Box';
import { Typography } from '@interstate-104/components/Typography';

// types
import { FeeCategoryItemsProps } from './FeeCategoryItems';

// components
import { FeesFormItems } from './FeesFormItems';

export const FeesFormCategoryItems: React.FC<Omit<FeeCategoryItemsProps, 'offerType'>> = ({
    categoryTitle,
    feeItems,
    dataTestId,
    categoryType
}) => {
    return (
        <Box data-testid={dataTestId} marginTop={3}>
            <Typography variant="h5" sx={{ marginBottom: -1 }}>
                {categoryTitle}
            </Typography>
            <FeesFormItems feeItems={feeItems} categoryType={categoryType} />
        </Box>
    );
};

export default memo(FeesFormCategoryItems);
