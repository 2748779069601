import { SlideOut } from '@interstate-104/components/SlideOut';
import { ComponentType, FC, PropsWithChildren, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { ShowDrawerContentWithOptions, SlideoutDrawerOptions, SlideoutDrawerToggle } from './types';
import { SlideoutDrawerContext } from './Context';
import { BodyContainer } from './styles';
import { SlideoutDrawerFooter } from './SlideoutFooter';
import { SlideoutDrawerHeader } from './SlideoutHeader';
import _defaults from 'lodash.defaults';

export type LocalSlideoutState = {
    open: boolean;
    busy: boolean;
    Child: ComponentType | undefined; // no props intentionally;
    options: SlideoutDrawerOptions;
};

export type SlideoutProviderOptions = Omit<SlideoutDrawerOptions, 'onClose'>;

export type SlideoutProviderProps = PropsWithChildren & Partial<SlideoutProviderOptions>;

/**
 * Establish a slidout provider to manage the slideout side panel and its content. Provide overridden default options
 * for custom header, footer, and options.
 */
export const SlideoutDrawerProvider: FC<SlideoutProviderProps> = ({ children, footer, header, position }) => {
    // keep default options in stone from the provider level. These can be overridden in state every invocation of showContent.
    const defaultOptions = useMemo(
        () =>
            _defaults(
                { header, footer, position },
                { footer: <SlideoutDrawerFooter />, header: '', position: 'right' } // defaults
            ) as SlideoutProviderOptions,
        [header, footer, position]
    );

    const [state, setState] = useState<LocalSlideoutState>({
        open: false,
        busy: false,
        Child: undefined,
        options: defaultOptions
    });

    const Header = useMemo(() => {
        if (typeof state.options.header === 'string') return <SlideoutDrawerHeader text={state.options.header} />;
        return state.options.header;
    }, [state]);

    const handleExternalClose = useCallback(() => state.options.onClose?.(), [state]);

    const toggleBusy = useCallback((value: boolean) => {
        setState((old) => {
            const updated = { ...old, busy: value };
            return updated;
        });
    }, []);

    const toggle: SlideoutDrawerToggle = useCallback((value) => {
        setState((old) => {
            const updated = { ...old };
            if (value != null) updated.open = value;
            updated.open = !old.open;
            if (!updated.open) updated.busy = false; // any time we close the slide out, set busy to false
            return updated;
        });
    }, []);

    const handleHide = () => {
        setState((old) => {
            const updated = { ...old, open: false, busy: false };
            return updated;
        });

        handleExternalClose();
    };

    const showContent: ShowDrawerContentWithOptions = useCallback(
        (Child, options = {}) => {
            setState({
                options: { ...defaultOptions, ...options }, // always defaults, then overrides
                Child,
                open: true,
                busy: false
            });
        },
        [defaultOptions]
    );

    return (
        <SlideoutDrawerContext.Provider
            value={{
                open: state.open,
                busy: state.busy,
                toggle,
                toggleBusy,
                showContent
            }}
        >
            {children}
            {state.Child && (
                <>
                    <SlideOut
                        header={Header}
                        show={state.open}
                        data-testid={state.options.testId || ''}
                        position={state.options.position}
                        panelWidth="auto"
                        sx={{
                            '.MuiPaper-root': {
                                boxShadow: 'unset' // This happens because it's inside a MUI dialog content (because of the sliding drawer); But paper-root is also used for the card paper.
                            },
                            '.MuiPaper-root>div': {
                                // removing padding; we're using gap
                                padding: '0'
                            },
                            '.MuiPaper-root.MuiDrawer-paper': {
                                width: { xs: 1, md: 661 }
                            },
                            '.ids-SlideOut-content': {
                                // content panel to flex so we can add stuff in flex mode.
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: '0 !important' // dropping all padding becuase we already have padding on the container
                            },
                            '.MuiDialogActions-root': {
                                padding: 0,
                                // position: 'absolute', // need this for the footer to be sticky.
                                backgroundColor: '#f8f7fc'
                            },
                            '.MuiDrawer-paper .MuiPaper-root': {
                                position: 'unset'
                            },
                            '.MuiPaper-root.MuiDrawer-paper>.interstate-sliding-drawer-dialog-title-container': {
                                padding: '.5rem' // eliminate padding by default so we can attach a real footer.
                            }
                        }}
                        onHide={handleHide}
                        footer={{ footerComponent: state.options.footer }}
                    >
                        <BodyContainer>
                            <state.Child />
                        </BodyContainer>
                    </SlideOut>
                </>
            )}
        </SlideoutDrawerContext.Provider>
    );
};
