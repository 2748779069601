// externals
import { Reducer } from 'redux';

// libraries
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import type { SelectedOffer } from '@makemydeal/dr-shared-types';

// consts/enums
import { SELECTED_OFFER_SET } from '../actionTypes/selectedOfferActionTypes';
import { BOOTSTRAP_INITIAL_LOAD, BOOTSTRAP_SUCCESS } from '../actionTypes/bootstrapActionTypes';

export type SelectedOfferReducer = Reducer<SelectedOffer>;

export const reducer: SelectedOfferReducer = (state: SelectedOffer = {} as unknown as SelectedOffer, action: any) => {
    switch (action.type) {
        case SELECTED_OFFER_SET: {
            const offer: SelectedOffer = {
                ...state,
                ...action.payload
            };

            return offer;
        }
        case BOOTSTRAP_INITIAL_LOAD: {
            // read the values from SessionStorage
            const ids = offerSessionUtils.getOfferIdentifiers();

            // Load them into state
            const offer: SelectedOffer = { ...ids };
            return offer;
        }
        case BOOTSTRAP_SUCCESS: {
            // get the settings from the initial load
            const { offer } = action.payload;
            if (offer) {
                const {
                    connectionId,
                    dealExchangeDealId: dealXgId,
                    dealExchangeVersionId: dealXgVersion,
                    vehicle: { vin }
                } = offer;

                const selectedOffer: SelectedOffer = {
                    connectionId,
                    dealXgId,
                    dealXgVersion,
                    vin
                };
                return selectedOffer;
            }

            return state;
        }
        default: {
            return state;
        }
    }
};
