// externals
import { combineReducers, Reducer } from 'redux';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import * as rollbackActionCreators from '../actions/rollbackActionCreators';
import * as rollbackActionTypes from '../actionTypes/rollbackActionTypes';

// interfaces/types
import type { ConfigureStoreReducers } from './configureStoreTypes';

// TODO: Define this type correctly
export type ConfigureStoreReducer = {
    name: string;
};

export type ConfigureStoreInitialState = {
    [name: string]: any;
};

/**
 * Performs a similar function to combineReducers but does it such that adding reducers dynamically is supported.
 * @param initialState full state structure (the keys are all that matter)
 * @param reducers object with reducers keyed by name
 * @returns combined reducers
 */
export const combineReducersDynamically = (initialState: ConfigureStoreInitialState, reducers: ConfigureStoreReducers) => {
    const reducerNames = Object.keys(reducers);
    Object.keys(initialState).forEach((item) => {
        if (reducerNames.indexOf(item) === -1) {
            reducers[item] = (state = null) => state;
        }
    });
    return appReducersWithRollback(reducers);
};

export const appReducersWithRollback =
    (reducers: ConfigureStoreReducers): Reducer =>
    (state, action: rollbackActionCreators.AllRollbackActions) => {
        switch (action.type) {
            case rollbackActionTypes.ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE: {
                const originalState = action.payload.original as StateTree;
                delete originalState.router;
                delete originalState.navigation;
                return {
                    ...originalState,
                    router: state.router,
                    navigation: state.navigation,
                    config: state.config,
                    changeVehicle: state.changeVehicle
                };
            }
            default: {
                return combineReducers(reducers)(state, action);
            }
        }
    };
