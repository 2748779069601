// externals
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlideOutProps } from '@interstate-104/components/SlideOut';
import { Spinner as LoadingIndicator } from '@interstate-104/components/Spinner';
import { Button } from '@interstate/components/Button';

// store
import { vehicleProtectionSelectors, vehicleProtectionActionCreators } from '@makemydeal/dr-dash-store';

// styles
import { LoaderContainer } from './EditDealAttributesDrawer.style';
import { StyledSlideOut, StyledSlideOutFooter } from '../vehicleProtection/VehicleProtection.style';

// components
import DealAttributesForm from './dealAttributesForm/DealAttributesForm';

// utils
import { getRequestedDealAttributeInputs } from '../../utils';

// types
import { VppDealAttributeInput } from '../../types';
import { SurchargeOverrides } from '@makemydeal/dr-shared-types';

type EditDealAttributesDrawerProps = {
    show: boolean;
    onClose: () => void;
};

const EditDealAttributesDrawer = ({ show, onClose }: EditDealAttributesDrawerProps) => {
    const [updateDisabled, setUpdateDisabled] = useState(true);
    const [dealAttributeErrors, setDealAttributeErrors] = useState<{ [name: string]: boolean } | undefined>(undefined);
    const isFetchingVppProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);
    const surchargesOverrides = useSelector(vehicleProtectionSelectors.getVppSurchargesOverrides);
    const { usedSurchargesAttributes, requiredSurchargesAttributes } = useSelector(
        vehicleProtectionSelectors.getVppRequestedSurcharges
    );
    const dispatch = useDispatch();
    const [editedDealAttributes, setEditedDealAttributes] = useState<SurchargeOverrides>({});

    // allows Interstate's SlidingDrawer to notice changes in content height and re-render the footer with overflow
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [isFetchingVppProducts]);

    useEffect(() => {
        if (dealAttributeErrors) {
            setUpdateDisabled(Object.values(dealAttributeErrors).includes(true));
        }
    }, [dealAttributeErrors]);

    const handleClose = useCallback(() => {
        setUpdateDisabled(true);
        onClose();
    }, [onClose]);

    const onCancel = () => {
        setEditedDealAttributes({});
        handleClose();
    };

    const onChangeDealAttribute = (dealAttribute: VppDealAttributeInput, withError?: boolean) => {
        setDealAttributeErrors({ ...dealAttributeErrors, [dealAttribute.name]: !!withError });
        setEditedDealAttributes((prevEditedDealAttributes: SurchargeOverrides) => ({
            ...prevEditedDealAttributes,
            [dealAttribute.name]: dealAttribute.value
        }));
    };

    const onReRate = () => {
        dispatch(
            vehicleProtectionActionCreators.updateVppSurchargesAttributes({
                ...surchargesOverrides,
                ...editedDealAttributes
            })
        );
        handleClose();
    };

    let drawerContent: JSX.Element = (
        <LoaderContainer>
            <LoadingIndicator />
        </LoaderContainer>
    );
    let drawerFooter: SlideOutProps['footer'] = undefined;

    if (!isFetchingVppProducts) {
        const visibleVppDealAttributes = getRequestedDealAttributeInputs(
            {
                ...surchargesOverrides,
                ...editedDealAttributes
            },
            usedSurchargesAttributes,
            requiredSurchargesAttributes
        );

        drawerContent = <DealAttributesForm dealAttributeInputs={visibleVppDealAttributes} onChange={onChangeDealAttribute} />;
        drawerFooter = {
            footerComponent: (
                <StyledSlideOutFooter>
                    <Button
                        data-testid="edit-deal-attributes-cancel-btn"
                        buttonStyle="tertiary"
                        size="medium"
                        block={true}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-testid="edit-deal-attributes-update-btn"
                        buttonStyle="primary"
                        size="medium"
                        block={true}
                        disabled={updateDisabled}
                        onClick={onReRate}
                    >
                        Update Attributes
                    </Button>
                </StyledSlideOutFooter>
            )
        };
    }

    return (
        <StyledSlideOut
            data-testid={'edit-deal-attributes'}
            show={show}
            onHide={onCancel}
            position="right"
            header="Edit Deal Attributes"
            panelWidth={392}
            footer={drawerFooter}
        >
            {drawerContent}
        </StyledSlideOut>
    );
};

export default EditDealAttributesDrawer;
