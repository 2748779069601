// libraries
import { GetCashOfferAdditionalSelectors } from '@makemydeal/dr-offer-redux';
import { Selectors as MenuSelectors } from '@makemydeal/dr-activities-menu';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// consts/enums
import { APP_NAME as MENU_APP_NAME } from '../shopperActivities/menu/constants';

// selectors
import * as offerReduxSelectors from './offerRedux';
import { getTotalMvVppSelectionPrice, isVehicleProtectionActivityEnabled } from './vehicleProtectionSelectors';
import { featurePackageSelectors } from './featurePackageSelectors';
import { getTotalMvAccessoriesSelectionPrice } from './accessoriesSelectors';

export const getMvCashOfferAdditionalSelectors = (state: StateTree): GetCashOfferAdditionalSelectors => {
    const isFeatureToggleLoaded = featureToggleSelectors.areFeatureTogglesAvailable(state);
    const useVehicleProtectionActivity = isFeatureToggleLoaded ? isVehicleProtectionActivityEnabled(state) : false;

    const menuSelectors = featurePackageSelectors.get<MenuSelectors>(MENU_APP_NAME);
    const vppSelectionTotalSelector = useVehicleProtectionActivity ? getTotalMvVppSelectionPrice : menuSelectors.getListTotalPrice;

    return {
        getTotalVppSelectionPrice: vppSelectionTotalSelector,
        getTotalAccessoriesSelectionPrice: getTotalMvAccessoriesSelectionPrice
    };
};

export const getMvCashOfferRoundedUp = (state: StateTree, showDecimal?: boolean): number => {
    const mvAdditionalSelectors = getMvCashOfferAdditionalSelectors(state);
    return offerReduxSelectors.getCashOfferRoundedUp(state, mvAdditionalSelectors, showDecimal);
};

export const getMvCashAmountDue = (state: StateTree) => {
    const isUsePSTotalAmountDueForCashDealsEnabled = featureToggleSelectors.isUsePSTotalAmountDueForCashDealsEnabled(state);
    const cashOffer = getMvCashOfferRoundedUp(state, true);
    const totalAmountDueAfterCredits = offerReduxSelectors.getCashTotalAmountDueAfterCredits(state);
    return isUsePSTotalAmountDueForCashDealsEnabled ? totalAmountDueAfterCredits : cashOffer;
};
