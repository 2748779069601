// externals
import { v4 as generateUuid } from 'uuid';
import OktaAuth from '@okta/okta-auth-js';
import { AxiosRequestConfig } from 'axios';

// libraries
import { SESSION_ID_HEADER, TRACE_ID_HEADER } from '@makemydeal/dr-shared-bff-types';
import { toggleUtils, sessionUtils, locHrefUtil, apiConfigHelper } from '@makemydeal/dr-shared-ui-utils';

// utils
import { axiosInstance } from './axiosInstance';

const FETCH_RELATIVE_PATH = '/api';
const HEADER_AUTHORIZATION_PREFIX = 'Bearer';

let oktaAuthInstance: OktaAuth;

export const setOktaAuthForAxiosInterceptor = (oktaAuth: OktaAuth): void => {
    oktaAuthInstance = oktaAuth;
};

export const buildAxiosRequestInterceptor = (appPrefix: string) => {
    const axiosRequestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const url = config.url;
        if (url) {
            // only do this for BFF endpoints
            const locHref = locHrefUtil.getLocHref();
            const bffUrl = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '');

            // only go if it is a relative path /api or if it is bff endpoint
            if (url.startsWith(bffUrl.url) || url.startsWith(FETCH_RELATIVE_PATH)) {
                const token = oktaAuthInstance?.getAccessToken();
                // these are the headers we want to add
                const headers: { [name: string]: string } = {
                    ...toggleUtils.getToggleHeaderFromQueryParamsOrSession(),
                    ...(token && process.env.BYPASS_OKTA !== 'true'
                        ? { Authorization: `${HEADER_AUTHORIZATION_PREFIX} ${token}` }
                        : undefined),
                    [SESSION_ID_HEADER]: sessionUtils.getAppSessionId(),
                    [TRACE_ID_HEADER]: generateUuid()
                };
                if (!config.headers) {
                    config.headers = headers;
                } else {
                    Object.keys(headers).forEach((header) => {
                        if (!config.headers[header]) {
                            config.headers[header] = headers[header];
                        }
                    });
                }
            }
        }
        return config;
    };
    return axiosRequestInterceptor;
};

let currentAxiosRequestInterceptor: any;

export const getCurrentAxiosRequestInterceptor = () => {
    if (!currentAxiosRequestInterceptor) {
        throw new Error('currentAxiosRequestInterceptor is undefined, call setupAuthAxiosIntercept first!');
    }
    return currentAxiosRequestInterceptor;
};

export const setupAuthAxiosIntercept = (appPrefix: string) => {
    if (!currentAxiosRequestInterceptor) {
        currentAxiosRequestInterceptor = buildAxiosRequestInterceptor(appPrefix);
    }
    axiosInstance.interceptors.request.use(currentAxiosRequestInterceptor, (error) => Promise.reject(error));
};
