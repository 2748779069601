import React from 'react';

import { InterstateDealSummarySections } from '@makemydeal/dr-dash-components';
import { List } from '@interstate-104/components/List';

const Lease = () => (
    <List data-testid="lease-deal-summary">
        <InterstateDealSummarySections.InterstateMSRP />
        <InterstateDealSummarySections.InterstateDiscount />
        <InterstateDealSummarySections.InterstateSellingPrice />
        <InterstateDealSummarySections.InterstateNetTrade />
        <InterstateDealSummarySections.InterstateAddsToCapCost />
        <InterstateDealSummarySections.InterstateGrossCapCost />
        <InterstateDealSummarySections.InterstateLeaseTotalTax />
        <InterstateDealSummarySections.InterstateCapCostReduction />
        <InterstateDealSummarySections.InterstateAdjCapCost />
        <InterstateDealSummarySections.InterstateTotalResidual />
        <InterstateDealSummarySections.InterstateTermLength />
        <InterstateDealSummarySections.InterstateMonthlyPayment />
        <InterstateDealSummarySections.InterstateTotalDueAtSigning showLargeValue />
    </List>
);

export default Lease;
