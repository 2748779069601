// externals
import React, { HTMLAttributes, useMemo, useState, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Action } from '@interstate-104/components/Action';
import { Box } from '@interstate-104/components/Box';
import { Card, CardHeader } from '@interstate-104/components/Card';
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LineItem, LoadingSection, LoadingSectionParent } from '@makemydeal/dr-dash-components';
import { CASH, paymentServicesTypes } from '@makemydeal/dr-platform-types';

// styles
import { TypographyNoTextTransform, TypographyRightAligned, FeeCardContainer } from './FeesCard.style';

// selectors
import { paymentSelectors, offerActionCreators, offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { StateTree } from '@makemydeal/dr-dash-types';

// components
import FeesForm from './FeesForm';
import FeeCategoryItems from './FeeCategoryItems';
import { AccordionDetails } from '@interstate-104/components/AccordionGroup';
import TotalSummary from '../common/TotalSummary';
import { CustomFeeItem } from './types';
import { FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { getCustomFeeItemsAdapter } from './utils';
import { GOV_DEALER_TITLE, LENDER_DEALER_GOV_TITLE } from './constants';

export const FeesCard: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const dispatch = useDispatch();
    const isPaymentCalculating = useSelector(paymentSelectors.isCalculatingPayment);
    const isViewAllFeesEnabled = useSelector(featureToggleSelectors.isViewAllFeesEnabled);
    const isEnableLenderFeeEdits = useSelector(featureToggleSelectors.enableLenderFeeEdits);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderFees = useSelector(offerSelectors.getItemizedLenderFees);
    const govFees = useSelector(offerSelectors.getItemizedGovernmentFees);
    const dealerFees = useSelector((state: StateTree) => offerSelectors.getItemizedDealerFees(state));
    const totalFees = useSelector(offerReduxSelectors.getTotalUnifiFees);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [showEditAction, setShowEditAction] = useState(false);
    const feeItems = useMemo(() => {
        const items: LineItem[] = [
            {
                label: 'Total Fees',
                value: formatDollarsAndCents(totalFees)
            }
        ];
        return items;
    }, [totalFees]);

    const lenderFeeItems: CustomFeeItem[] = useMemo(
        () => getCustomFeeItemsAdapter(lenderFees, FeesCategoriesType.LENDER),
        [lenderFees]
    );

    const govFeeItems: CustomFeeItem[] = useMemo(() => getCustomFeeItemsAdapter(govFees, FeesCategoriesType.GOVERNMENT), [govFees]);

    const dealerFeeItems: CustomFeeItem[] = useMemo(
        () => getCustomFeeItemsAdapter(dealerFees, FeesCategoriesType.DEALER),
        [dealerFees]
    );

    const toggleShowEditAction = () => setShowEditAction(!showEditAction);

    const onSave = (feesOverride: paymentServicesTypes.FeeOverride[]) => {
        dispatch(offerActionCreators.updatedFeesOverride(feesOverride));
    };

    const onReset = () => {
        dispatch(offerActionCreators.updatedFeesOverride([]));
    };

    if (!isViewAllFeesEnabled) {
        return null;
    }

    const isCash = offerType === CASH;
    const title = !isEnableLenderFeeEdits || isCash ? GOV_DEALER_TITLE : LENDER_DEALER_GOV_TITLE;

    return (
        <LoadingSectionParent {...props}>
            <LoadingSection loading={isPaymentCalculating} />
            <FeeCardContainer>
                <Card
                    data-testid="fees-card"
                    header={
                        <CardHeader
                            title={<Typography variant="h5">Fees</Typography>}
                            action={
                                <Action data-testid="manage-fees-link" onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                                    <Typography variant="body-sm" color="base.color.blue.700">
                                        {isAccordionOpen ? 'Show Less' : 'Show More'}
                                    </Typography>
                                </Action>
                            }
                        />
                    }
                    content={
                        <Box display="flex">
                            {!isAccordionOpen && (
                                <Grid display="grid" gridTemplateColumns="repeat(2, minmax(0, auto))" gap=".5rem">
                                    {feeItems.map((item: LineItem, i) => (
                                        <React.Fragment key={i}>
                                            <TypographyRightAligned variant="body-sm">{item.value}</TypographyRightAligned>
                                            <Typography variant="body-sm">{item.label}</Typography>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            )}
                            {isAccordionOpen && (
                                <Box width="100%">
                                    <Grid container rowGap={2} spacing={2}>
                                        <Grid xs={12}>
                                            {!isEnableLenderFeeEdits && !isCash && (
                                                <AccordionDetails sx={{ paddingBottom: '40px', fontSize: '1rem' }}>
                                                    <FeeCategoryItems
                                                        categoryTitle="Lender Fees"
                                                        categoryType="Lender"
                                                        feeItems={lenderFeeItems}
                                                        dataTestId="category_lender_fees"
                                                        offerType={offerType}
                                                    />
                                                </AccordionDetails>
                                            )}
                                            <TypographyNoTextTransform variant="h5">
                                                <Box
                                                    data-testid="category_gov_and_dealer_fees"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    padding="0"
                                                    fontSize="1.25rem"
                                                >
                                                    <span>{title}</span>
                                                    {!showEditAction && (
                                                        <Action onClick={toggleShowEditAction} data-testid="edit-fees-link">
                                                            Edit
                                                        </Action>
                                                    )}
                                                </Box>
                                            </TypographyNoTextTransform>
                                            <AccordionDetails>
                                                {!showEditAction && (
                                                    <>
                                                        {isEnableLenderFeeEdits && !isCash && (
                                                            <FeeCategoryItems
                                                                categoryTitle="Lender Fees"
                                                                categoryType={FeesCategoriesType.LENDER}
                                                                dataTestId="category_lender_fees"
                                                                feeItems={lenderFeeItems}
                                                                offerType={offerType}
                                                            />
                                                        )}
                                                        <FeeCategoryItems
                                                            categoryTitle="Government Fees"
                                                            categoryType={FeesCategoriesType.GOVERNMENT}
                                                            dataTestId="category_gov_fees"
                                                            feeItems={govFeeItems}
                                                            offerType={offerType}
                                                        />
                                                        <FeeCategoryItems
                                                            categoryTitle="Dealer Fees"
                                                            categoryType={FeesCategoriesType.DEALER}
                                                            dataTestId="category_dealer_fees"
                                                            feeItems={dealerFeeItems}
                                                            offerType={offerType}
                                                        />
                                                    </>
                                                )}

                                                {showEditAction && (
                                                    <FeesForm
                                                        key={JSON.stringify({ dealerFeeItems, govFeeItems })}
                                                        toggleShowEditAction={toggleShowEditAction}
                                                        offerType={offerType}
                                                        dealerFeesItems={dealerFeeItems}
                                                        governmentFeesItems={govFeeItems}
                                                        lenderFeesItems={lenderFeeItems}
                                                        onReset={onReset}
                                                        onSave={onSave}
                                                        isEnableLenderFeeEdits={isEnableLenderFeeEdits}
                                                    />
                                                )}
                                            </AccordionDetails>
                                            <Box display="flex" justifyContent="flex-end">
                                                <TotalSummary totalType="Fees" total={totalFees} paddingRight={3.7} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                    }
                ></Card>
            </FeeCardContainer>
        </LoadingSectionParent>
    );
};

export default FeesCard;
