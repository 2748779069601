import { Button } from '@interstate/components/Button';
import { useContext } from 'react';
import { FormContext } from '../formManager/formContext';
import { useSelector } from 'react-redux';
import { paymentSelectors } from '@makemydeal/dr-offer-redux';
import { FooterContainer } from './Style';

const DrawerFooterActions = () => {
    const context = useContext(FormContext);
    const { error } = useSelector(paymentSelectors.getPaymentState);
    return (
        <FooterContainer gap={2}>
            <Button
                disabled={error}
                data-testid="btnVehicleInfoDrawerClose"
                id="VehicleInfoDrawerCloseBtn"
                buttonStyle="tertiary"
                size="medium"
                onClick={() => context.handleClick('CANCEL')}
            >
                Cancel
            </Button>
            <Button
                disabled={!context.canApply}
                data-testid="btnVehicleInfoDrawerApply"
                id="VehicleInfoDrawerApplyBtn"
                buttonStyle="primary"
                size="medium"
                onClick={() => context.handleClick('APPLY')}
            >
                Apply Changes
            </Button>
        </FooterContainer>
    );
};

export default DrawerFooterActions;
