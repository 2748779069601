// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const Discount = ({
    deal,
    hasDelta = false,
    showNegativeDiscount = false
}: {
    deal?: DealHistory;
    hasDelta?: boolean;
    showNegativeDiscount?: boolean;
}) => {
    const state = useDealRootState(deal);
    let label = constants.DISCOUNT;
    let discount = offerReduxSelectors.getDiscount(state);
    if (!showNegativeDiscount && discount < 0) {
        discount = -discount;
        label = constants.MARKUP;
    }

    const value = formatDollarsAndCents(discount);

    return <DealSummaryItem label={label} value={value} hasDelta={hasDelta} />;
};

export default Discount;
