// externals
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Action } from '@interstate-104/components/Action';
import { Alert } from '@interstate-104/components/Alert';
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import { creditDecisionSelectors, navigationActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { LEASE } from '@makemydeal/dr-platform-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import AcquisitionFeeAmountField from './common/AcquisitionFeeAmountField.interstate';
import AcquisitionFeeUpFrontField from './common/AcquisitionFeeUpFrontField.interstate';
import AdjustedResidualField from './common/AdjustedResidualField.interstate';
import BaseResidualField from './common/BaseResidualField.interstate';
import BuyRateField from './common/BuyRateField.interstate';
import CashDownField from './common/CashDownField.interstate';
import CreditDecisionEnabled from './common/CreditDecisionEnabled.interstate';
import CreditDecisionLenderNameField from './common/CreditDecisionLenderNameField.interstate';
import CreditDecisionStatusField from './common/CreditDecisionStatusField.interstate';
import ExcessMileageChargeField from './common/ExcessMileageChargeField.interstate';
import LeaseTermField from './common/LeaseTermField.interstate';
import MaxResidualizedMsrpAppliedROField from './common/MaxResidualizedMsrpAppliedROField.interstate';
import MaxResidualizedMsrpROField from './common/MaxResidualizedMsrpROField.interstate';
import MilesPerYearField from './common/MilesPerYearField.interstate';
import PaymentFrequencyField from './common/PaymentFrequencyField.interstate';
import RetailPriceField from './common/RetailPriceField.interstate';
import SecurityDepositField from './common/SecurityDepositField.interstate';
import SellRateField from './common/SellRateField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';
import { TermsTab } from './common/Terms.style';

// styles
import { LeaseHeader } from './Lease.styles';

export type LeaseProps = {
    enableCreditDecisionAlert?: boolean;
};

const Lease = ({ enableCreditDecisionAlert = false }: LeaseProps) => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderDecisions = useSelector((state: StateTree) => {
        return creditDecisionSelectors.getLenderCreditDecisionsByOfferType(state, currentOfferType);
    });
    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const dispatch = useDispatch();

    const creditDecisionAvailable = lenderDecisions.length > 0;

    const navigateToCreditBureau = () => dispatch(navigationActionCreators.navigateToCreditBureau());
    const navigateToCreditDecision = () => dispatch(navigationActionCreators.navigateToCreditDecision());

    if (currentOfferType !== LEASE) return null;

    return (
        <TermsTab data-testid="lease-terms">
            <LeaseHeader>
                <Typography data-testid="lease-title" variant="h5">
                    Payment And Lender Information
                </Typography>
                <Action
                    data-testid="lease-terms-edit-link"
                    sx={{
                        fontSize: '14px'
                    }}
                    onClick={enableCreditBureauAndDecisionPBCs ? navigateToCreditDecision : navigateToCreditBureau}
                >
                    View/Edit
                </Action>
            </LeaseHeader>
            <Grid xs={12}>
                {enableCreditDecisionAlert && creditDecisionAvailable && (
                    <Alert id="infoAlert" role="infoAlert" type="info" data-testid="terms-lender-decision-alert">
                        Lender decisions are ready for this offer.
                    </Alert>
                )}
            </Grid>
            <Grid xs={12}>
                <CreditDecisionEnabled />
            </Grid>
            <Grid spacing={2} container xs rowGap={2} data-testid="main-lease-content" flexWrap="wrap">
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <RetailPriceField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <SellingPriceField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <CashDownField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <CreditDecisionLenderNameField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <CreditDecisionStatusField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <LeaseTermField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <PaymentFrequencyField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <SellRateField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <BuyRateField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <BaseResidualField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <AdjustedResidualField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <MilesPerYearField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                        <AcquisitionFeeAmountField />
                        <AcquisitionFeeUpFrontField />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf={'flex-end'}>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                        <MaxResidualizedMsrpROField />
                        <MaxResidualizedMsrpAppliedROField />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <SecurityDepositField />
                </Grid>
                <Grid xs={6} sm={6} lg={4} md={6} alignSelf="flex-end">
                    <ExcessMileageChargeField />
                </Grid>
            </Grid>
        </TermsTab>
    );
};

export default Lease;
