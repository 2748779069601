// externals
import { FC } from 'react';

// libraries
import { CheckBox } from '@interstate-104/components/CheckBox';

// interfaces/types
import { CheckboxChangeEventHandlerChange, ColumnMapping } from '../manualAccessoriesTypes';

export type ManualAccessoryUpfrontFieldStateProps = {
    index: number;
    upFront: boolean;
    upfrontColumnMapping: ColumnMapping<boolean>;
    deleted: boolean;
};

export type ManualAccessoryUpfrontFieldDispatchProps = {
    handleCheckboxCheck: CheckboxChangeEventHandlerChange;
};

export type ManualAccessoryUpfrontFieldProps = ManualAccessoryUpfrontFieldDispatchProps & ManualAccessoryUpfrontFieldStateProps;

export const ManualAccessoryUpfrontField: FC<ManualAccessoryUpfrontFieldProps> = ({
    deleted,
    index,
    upFront,
    upfrontColumnMapping,
    handleCheckboxCheck
}) => (
    <CheckBox
        label={upfrontColumnMapping.label}
        name={`${upfrontColumnMapping.fieldName}-${index}`}
        data-testid={`${upfrontColumnMapping.fieldName}-${index}`}
        onChange={(e) => {
            handleCheckboxCheck(e, upfrontColumnMapping.fieldName);
        }}
        checked={upFront}
        value={`${upfrontColumnMapping.fieldName}-${index}`}
        disabled={deleted}
    />
);
