// libs
import { MAX_APP_CONTAINER_WIDTH } from '@makemydeal/dr-dash-components';
import styled from 'styled-components';

export const StyledHistoryOfferColumnContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 1rem;
`;

export const DealHistoryTitle = styled.h1`
    &&& {
        text-align: left;
    }
`;

export const StyledDealHistoryFooter = styled.div`
    width: 100%;
    height: 3.125rem;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 -0.25px 0.25px 0 rgba(0, 0, 0, 0.2);

    display: grid;
    align-content: center;
    text-align: right;
`;

export const StyledSliderCarouselContainer = styled.div`
    background: #f4f4f4;
    padding: 1.5rem 0;
`;

export const DealHistoryFooterContainer = styled.div`
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const DealHistoryFooterInterstateButtonContainer = styled.div`
    max-width: ${MAX_APP_CONTAINER_WIDTH};
    margin: 0 auto;
`;
