// externals
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Card, CardContent } from '@interstate-104/components/Card';
import { TabProps, Tabs } from '@interstate-104/components/Tabs';

import { offerActionCreators, offerReduxSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { CASH, FINANCE, LEASE, OfferType } from '@makemydeal/dr-platform-types';

// components
import { LoadingSectionParent } from '../loadingSection';
import Cash from './Cash.interstate';
import CreditBureau from './CreditBureau.interstate';
import Finance from './Finance.interstate';
import Lease from './Lease.interstate';

// styles
import { capitalize } from '@makemydeal/dr-common-utils';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import PaymentLoading from '../common/PaymentLoading';
import PaymentStatus from '../common/PaymentStatus.interstate';

const componentMap = {
    cash: Cash,
    finance: Finance,
    lease: Lease
};

export const Terms = () => {
    const currentOfferType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isNew: boolean = useSelector(vehicleSelectors.isVehicleNew);
    const hasLeasing: boolean = useSelector(dealerSelectors.hasLeasing);
    const isLeaseEnabled: boolean = isNew && hasLeasing;
    const isCashEnabled: boolean = useSelector(dealerSelectors.isCashOfferEnabled);
    const dispatch = useDispatch();
    const updateOfferType = (offerType: OfferType) => dispatch(offerActionCreators.updatedOfferType(offerType));

    const allTabs = [
        {
            offerType: FINANCE as OfferType
        }
    ];
    if (isLeaseEnabled) {
        allTabs.push({
            offerType: LEASE as OfferType
        });
    }
    if (isCashEnabled) {
        allTabs.push({
            offerType: CASH as OfferType
        });
    }
    const onActivate = (index: number) => {
        const offerType = allTabs[index].offerType;
        updateOfferType(offerType as OfferType);
    };
    const activeTabIndex: number = allTabs.findIndex(({ offerType }) => offerType === currentOfferType);

    /**
     * The intent is to always make the view/edit button visible no matter the credit provider. [F192752]
     *
     * The showLink logic is coupled so that if there is a release before
     * [ US1366117: [CD MV] - Removing Credit Bureau Widget & text change for RouteOne dealers is done.] is completed then we can
     * revert to the old experience.
     *
     * It will be removed in US1377516
     */
    const tabs: TabProps[] = allTabs.map(({ offerType }, index) => {
        const Component = componentMap[offerType];
        return {
            label: capitalize(offerType),
            active: activeTabIndex === index,
            component: <Component enableCreditDecisionAlert={true} />,
            'data-testid': offerType,
            id: offerType
        };
    });

    return (
        <LoadingSectionParent>
            <PaymentStatus />
            <PaymentLoading testId="terms-loading-indicator" />
            <Card
                data-testid="terms-card"
                content={
                    <CardContent>
                        <Tabs id="offer-type-tabs" onActivate={onActivate} tabs={tabs} />
                        <CreditBureau />
                    </CardContent>
                }
            />
        </LoadingSectionParent>
    );
};

export default Terms;
