export type DealLifecycle = {
    status?: string;
    soldDate?: string;
    contractSignedDate?: string;
    deliveredDate?: string;
    updateDealLifecycleStatus: UpdateDealLifecycleStatus;
};

export enum UpdateDealLifecycleStatus {
    Ready = 'ready',
    Sending = 'sending',
    Success = 'success',
    Failure = 'failure'
}
