import styled from 'styled-components';

import { Typography } from '@interstate-104/components/Typography';

export const StyledTableCell = styled.div<{ textAlign?: string }>`
    min-width: 3.125rem;
    text-align: ${(props) => props.textAlign || 'left'};
`;

export const StyledSpan = styled.span<{ paddingLeft: number }>`
    padding-left: ${(props) => props.paddingLeft}rem;
`;

export const TypoRightAligned = styled(Typography)`
    text-align: right;
`;
