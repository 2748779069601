/* eslint-disable react/no-multi-comp */
// TODO: use centralized component between here and activities-desking
import React, { ReactNode, useContext } from 'react';

// components
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';

// constants
import { ZERO_DOLLARS } from '../../constants';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';
import { ExpandableArea, ExpandableAreaProvider } from '../../common/ExpandableArea';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { ExpandableAreaContext, ExpandableAreaContextType } from '../../common/ExpandableArea/Context';
import { StyledExpandableAreaSection, StyledListItem } from './DealSummaryItem.style';

export interface IDealSummaryItemProps {
    label: string;
    value: string;
    children?: ReactNode;
    /**
     * @deprecated Unused prop
     */
    showLargeValue?: boolean;
    isEmpty?: boolean;
    /**
     * @deprecated Unused prop
     */
    isExpanded?: boolean;
    hasDelta?: boolean;
}
const LineItemRow = ({ children, pl }: { children: ReactNode; pl: string }) => (
    <Grid container justifyContent="space-between" alignItems="center" paddingY="12px" pr="16px" pl={pl}>
        {children}
    </Grid>
);

export const DealSummaryItem = ({ label, value, children, isEmpty = false, hasDelta = false }: IDealSummaryItemProps) => {
    const dealSummaryItemPrefix = 'deal-summary-item';
    const kebabLabel = kebabCase(label);
    const containerTestId = `${dealSummaryItemPrefix}-${kebabLabel}`;
    const labelId = `${dealSummaryItemPrefix}-label-${kebabLabel}`;
    const valueId = `${dealSummaryItemPrefix}-value-${kebabLabel}`;
    const className = isEmpty ? 'empty-item' : '';

    if (value !== ZERO_DOLLARS && children) {
        return (
            <StyledListItem className={`${className} ${hasDelta ? 'has-delta' : ''}`} hasDelta={hasDelta}>
                <ExpandableAreaProvider>
                    <StyledExpandableAreaSection expandableId="deal-summary-item-accordion">
                        <LineItemRow pl="8px">
                            <ChevronRightIcon className="chevron-icon" />
                            <Typography
                                tag="span"
                                sx={{
                                    flexGrow: 1
                                }}
                                variant="body-sm"
                                color="sem.color.on-surface.muted"
                                data-testid={containerTestId}
                            >
                                {label}
                            </Typography>
                            <Typography tag="span" variant="body-lg" color="sem.color.on-surface.default" data-testid={valueId}>
                                {value}
                            </Typography>
                        </LineItemRow>
                    </StyledExpandableAreaSection>
                    <ExpandableArea data-testid="deal-summary-item-accordion" expandableId="deal-summary-item-accordion">
                        <Grid pb={2} pr={2} pl="26px">
                            {children}
                        </Grid>
                    </ExpandableArea>
                </ExpandableAreaProvider>
            </StyledListItem>
        );
    }

    return (
        <StyledListItem data-testid={containerTestId} hasDelta={hasDelta} className={`${className} ${hasDelta ? 'has-delta' : ''}`}>
            <LineItemRow pl="26px">
                <Typography tag="span" variant="body-sm" color="sem.color.on-surface.muted" data-testid={labelId}>
                    {label}
                </Typography>
                <Typography tag="span" variant="body-lg" color="sem.color.on-surface.default" data-testid={valueId}>
                    {value}
                </Typography>
            </LineItemRow>
        </StyledListItem>
    );
};

export default DealSummaryItem;
