import { RSAA } from 'redux-api-middleware';

import type { StateTree } from '@makemydeal/dr-dash-types';
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import {
    CONFIRM_PUSH_TO_ROUTEONE,
    PUSH_TO_ROUTEONE_FAILURE,
    PUSH_TO_ROUTEONE_REQUEST,
    PUSH_TO_ROUTEONE_SUCCESS
} from '../actionTypes/pushToRouteOneActionTypes';
import { getDealXgId, getDealXgVersion } from '../selectors/offerRedux';

export const confirmPushToRouteOne = () => {
    return {
        type: CONFIRM_PUSH_TO_ROUTEONE
    };
};

export const pushToRouteOneRSAA = (appPrefix: string, state: StateTree) => {
    const dealXgId = getDealXgId(state);
    const dealXgVersion = getDealXgVersion(state);

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/pushToFI');
    const endpoint = bffApiUrlResult.url;

    const body = JSON.stringify({
        dealXgId,
        dealXgVersion
    });

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body,
            types: [PUSH_TO_ROUTEONE_REQUEST, PUSH_TO_ROUTEONE_SUCCESS, PUSH_TO_ROUTEONE_FAILURE]
        }
    };
};
