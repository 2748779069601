// externals
import React from 'react';
import { useSelector } from 'react-redux';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { dealXgDetailsSelectors, reservationSelectors } from '@makemydeal/dr-dash-store';
import { ACTIVITY_NAME_VEHICLE_RESERVATION } from '@makemydeal/dr-dpm-client';

// interfaces/types
import { StatusLabel } from '../DealActivityTypes';

// components
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'reservation';

const ReservationCard = () => {
    const state = useSelector((state: StateTree) => state);
    const completed = useSelector(reservationSelectors.isReservationComplete);
    const cardValue = completed ? StatusLabel.vehicleReserved : '';
    const reservationDpmState = dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_VEHICLE_RESERVATION);

    return <DealActivityCard dpmState={reservationDpmState} name={name} cardValue={cardValue} completed={completed} />;
};

export default ReservationCard;
