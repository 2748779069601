import {
    CLICKED_CONTINUE_IN_DESKING,
    CLICKED_CONTINUE_IN_UNIFI,
    CLICKED_COPY_DEAL_URL,
    CLICKED_SEND_TO_ECOMMERCE
} from '../actionTypes/deskingActionTypes';

export const continueInDesking = () => ({
    type: CLICKED_CONTINUE_IN_DESKING
});

export const sendToEcommerce = () => ({
    type: CLICKED_SEND_TO_ECOMMERCE
});

export const copyDealUrl = () => ({
    type: CLICKED_COPY_DEAL_URL
});

export const continueInUnifi = () => ({
    type: CLICKED_CONTINUE_IN_UNIFI
});
