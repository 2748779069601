// components
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';

// styling
import { StyledAlertWrapper } from './TableGlobalAlerts.style';

// types
import { TableGlobalAlertInputProps } from '../../types';

const TableGlobalAlertWarning = ({ message, name }: TableGlobalAlertInputProps) => {
    return (
        <StyledAlertWrapper data-testid={`${name}-warning-alert`}>
            <Alert role="banner" type="warning">
                <Typography variant="label-md">{message}</Typography>
            </Alert>
        </StyledAlertWrapper>
    );
};

export default TableGlobalAlertWarning;
