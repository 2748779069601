// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { apiConfigHelper, BffApiUrlResult, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { selectedOfferSelectors } from '@makemydeal/dr-shared-store';

// consts/enums
import {
    FETCH_DEAL_HISTORY_REQUEST,
    FETCH_DEAL_HISTORY_SUCCESS,
    FETCH_DEAL_HISTORY_FAILURE,
    DEAL_HISTORY_CHECKED,
    DealHistoryCheckedAction,
    DealHistoryUnCheckedAction,
    DEAL_HISTORY_UNCHECKED,
    DealHistorySelectedUpdate,
    DEAL_HISTORY_SELECTED_UPDATE
} from '../actionTypes/dealHistoryActionTypes';

export const fetchDealHistory = (state: StateTree): RSAACallAction => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult: BffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '');
    const dealXgId = selectedOfferSelectors.getSelectedOfferDealXgId(state);
    const endpoint = `${bffApiUrlResult.url}/offerHistoryDxg/${dealXgId}`;
    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_DEAL_HISTORY_REQUEST, FETCH_DEAL_HISTORY_SUCCESS, FETCH_DEAL_HISTORY_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const dealHistoryChecked = (deal: DealHistory): DealHistoryCheckedAction => ({ type: DEAL_HISTORY_CHECKED, payload: deal });

export const dealHistoryUnchecked = (offerId: string): DealHistoryUnCheckedAction => ({
    type: DEAL_HISTORY_UNCHECKED,
    payload: offerId
});

export const dealHistorySelectedUpdate = (offerId: string): DealHistorySelectedUpdate => ({
    type: DEAL_HISTORY_SELECTED_UPDATE,
    payload: offerId
});
