import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccordionDetails } from '@interstate-104/components/AccordionGroup';
import { ShowWithFeatureToggle } from '@makemydeal/dr-dash-components';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import ItemizedTaxesSummary from './ItemizedTaxesSummary';
import TaxOverride from './TaxOverride';
import { excludeUnamedTaxes } from './helpers';
import TaxesBottomBar from './TaxesBottomBar';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

type TaxDetailsProps = {
    taxOverrideEnabled: boolean;
    setTaxOverrideEnabled: (taxOverride: boolean) => void;
};

const TaxDetails = (props: TaxDetailsProps) => {
    const { taxOverrideEnabled, setTaxOverrideEnabled } = props;

    const convertDecimalTaxRateToPercentInMV = useSelector(featureToggleSelectors.convertDecimalTaxRateToPercentInMV);

    const taxItems = useSelector((state) => {
        if (convertDecimalTaxRateToPercentInMV) {
            return offerReduxSelectors.getTaxItemsWithAdjustedRates(state) as paymentServicesTypes.TaxItems;
        } else {
            return offerReduxSelectors.getTaxItemsWithOverridesWithoutFlat(state) as paymentServicesTypes.TaxItems;
        }
    });

    const allItemizedTaxesWithNames = useMemo(() => excludeUnamedTaxes(taxItems), [taxItems]);

    const [taxOverrideFormEdited, setTaxOverrideFormEdited] = useState(taxOverrideEnabled);

    return (
        <AccordionDetails>
            <ItemizedTaxesSummary data={allItemizedTaxesWithNames} />
            <TaxOverride
                taxOverrideEnabled={taxOverrideEnabled}
                taxOverrideFormEdited={taxOverrideFormEdited}
                setTaxOverrideEnabled={setTaxOverrideEnabled}
                setTaxOverrideFormEdited={setTaxOverrideFormEdited}
            />
            <ShowWithFeatureToggle name="useOverrideTaxRateForPayment">
                <TaxesBottomBar setTaxOverrideFormEdited={setTaxOverrideFormEdited} />
            </ShowWithFeatureToggle>
        </AccordionDetails>
    );
};

export default TaxDetails;
