interface FniCreditDecisionWidget extends Partial<HTMLElement> {
    'deal-ref-id': string;
    env: string;
    'source-partner-id': string;
    'lender-id': string;
    'is-route-one': string;
}

namespace JSX {
    interface IntrinsicElements {
        'fni-credit-decision-widget': FniCreditDecisionWidget;
    }
}
