import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { ExpandableAreaKeyProp } from './types';
import { useExpandableArea } from './useExpandableArea';
import { AuxButton } from '@interstate/components/Button';

export type ExpandableAreaSectionProp = ExpandableAreaKeyProp &
    PropsWithChildren & {
        collapsedDisplay?: ReactNode | string;
        expandedDisplay?: ReactNode | string;
    };

/**
 * A trigger component to toggle collapse with an associated CollapsibleArea. Use collapsibleId to differentiate areas when using multiple. Expanded and collapsed text props are Show/Hide by default.
 * @param props
 */
export const ExpandableAreaSection: FC<ExpandableAreaSectionProp> = ({
    expandableId = 'main',
    children,
    expandedDisplay = 'Hide Details',
    collapsedDisplay = 'Show Details',
    ...additionalButtonProps
}) => {
    const { toggle, isExpanded } = useExpandableArea();
    const expanded = isExpanded(expandableId);
    const handleClick: any = () => {
        toggle(expandableId);
    };

    if (children) {
        return (
            <div onClick={handleClick} {...additionalButtonProps}>
                {children}
            </div>
        );
    }
    return (
        <AuxButton onClick={handleClick} {...additionalButtonProps}>
            {expanded && <>{expandedDisplay}</>}
            {!expanded && <>{collapsedDisplay}</>}
        </AuxButton>
    );
};
