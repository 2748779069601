// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// libraries
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    offerReduxSelectors,
    menuShopperActivity,
    tradeInSelectors,
    navigationActionTypes,
    accessoriesSelectors,
    dealXgDetailsSelectors
} from '@makemydeal/dr-dash-store';
import { ACTIVITY_NAME_ACCESSORIES } from '@makemydeal/dr-dpm-client';
import type { StateTree } from '@makemydeal/dr-dash-types';

// components
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'accessories';

const AccessoriesCard = () => {
    const dispatch = useDispatch();
    const incentivesCompleted = useSelector(offerReduxSelectors.getAppliedIncentivesForNonDealerCash).length > 0;
    const tradeCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const vppCompleted = useSelector(menuShopperActivity.menuSelectors.getListTotalPrice) > 0;
    const accessoriesList = useSelector(accessoriesSelectors.getAccessoriesList);
    const isAccessoriesEnabled = useSelector(featureToggleSelectors.isDCAccessoriesEnabled);
    const isAccessoriesFormOpen = useSelector(accessoriesSelectors.getFormStatus);
    const isAccessoriesCompleted = accessoriesList.length > 0;
    const totalAccessoriesAmount = useSelector(accessoriesSelectors.getAccessoriesTotalAmount);
    const cardValue = formatDollarsAndCents(totalAccessoriesAmount);
    const isCardRecommended =
        !isAccessoriesCompleted && incentivesCompleted && tradeCompleted && vppCompleted && !isAccessoriesFormOpen;

    const accessoriesDpmState = useSelector((state: StateTree) =>
        dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_ACCESSORIES)
    );

    return (
        <DealActivityCard
            dpmState={accessoriesDpmState}
            name={name}
            showCardAnchor={true}
            cardValue={cardValue}
            completed={isAccessoriesCompleted}
            recommended={isCardRecommended}
            action={() => {
                if (isAccessoriesEnabled) {
                    dispatch({ type: navigationActionTypes.DASH_START_ACCESSORIES_ACTIVITY });
                }
            }}
        />
    );
};

export default AccessoriesCard;
