// externals
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { MenuProductsRouting } from '@makemydeal/dr-activities-menu';
import { Modal as ActivitiesCommonModal } from '@makemydeal/dr-activities-common';

// config
import { getConfigMenuFromState } from '../config';

// NOTE: React v18 doesn't provide "children" property by default, so we do this to make it happy without having to convert all
//       activities to React v18.
const Modal = ActivitiesCommonModal as any;

export const mapStateToProps = (state: StateTree, ownProps: RouteComponentProps) => {
    const props = {
        config: {
            ...getConfigMenuFromState(state),
            userContext: {
                isDealer: true,
                isDesking: true
            }
        },
        hostRenderers: {
            renderModal: (
                showModal: boolean,
                onClickOutsideModal: { (): void },
                onModalShown: { (): void } | undefined,
                onModalHidden: { (): void } | undefined,
                children: JSX.Element | false | undefined
            ) => (
                <Modal
                    show={showModal}
                    elementToAttachModalTo=".dash-modal-attachpoint"
                    elementToBlur='[data-testid="dash-modal-blurlayer"]'
                    onClickOutsideModal={onClickOutsideModal}
                    outsideModalExcludeClasses={['dash-app-header']}
                    onModalShown={onModalShown}
                    onModalHidden={onModalHidden}
                >
                    {children}
                </Modal>
            )
        }
    };
    return props;
};

export const MenuContainer = connect(mapStateToProps, {})(MenuProductsRouting);
