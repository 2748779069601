/**
 * PURPOSE: Listens for specific actions and determines when a route change should be performed.
 */

// externals
import { push } from 'connected-react-router';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { NavigationActions } from '@makemydeal/dr-activities-common';
import { DashMiddleware, DashNext, DashStore } from '@makemydeal/dr-shared-store';
import {
    navigationActionTypes,
    RouteValues,
    tradeInActionCreators,
    accessoriesActionCreators,
    manualIncentivesActionCreators
} from '@makemydeal/dr-dash-store';

// config
import { creditBureauConfiguration } from '../activities/credit-bureau';
import { creditDecisionConfiguration } from '../activities/credit-decision';
import { menuConfiguration } from '../activities/menu';
import { allRoutes } from '../activities/programs';
import { vehicleProtectionConfiguration } from '../activities/vehicle-protection';

// utils
import { buildUniqueScrollRequestId } from '@makemydeal/dr-dash-ui-utils';
import { accessoriesCatalogConfiguration } from '../activities/accessories-catalog';

function processAction(action: AnyFSA, store: DashStore) {
    switch (action.type) {
        // TODO: DASH - This needs to be analyzed further... I believe the original intention of this middleware was to navigate
        //              to specific routes instead of a generate route like this.
        case NavigationActions.NAVIGATE_TO: {
            const routeIntent = action.payload;
            // TODO: DASH - restore as needed
            // const state = store.getState();
            // const deskingUser = isDeskingUser(state);
            // const headerPosition = getHeaderPosition(state);
            // const deskingUser = true;
            // if (deskingUser) {
            store.dispatch(push(routeIntent));
            // }
            break;
        }
        case navigationActionTypes.DASH_START_PROGRAMS_ACTIVITY: {
            store.dispatch(push(allRoutes.root));
            break;
        }
        case navigationActionTypes.DASH_START_MENU_ACTIVITY: {
            store.dispatch(push(menuConfiguration.defaultRoute));
            break;
        }
        case navigationActionTypes.DASH_START_TRADE_ACTIVITY: {
            const navOpenedFromTradeIn = buildUniqueScrollRequestId(); // NOTE: Needed to handle case where form is already open but user navigates away and then back to trade
            store.dispatch(tradeInActionCreators.toggleManualTradeForm(navOpenedFromTradeIn));
            break;
        }
        case navigationActionTypes.DASH_START_INCENTIVES_ACTIVITY: {
            const navOpenedFromIncentives = buildUniqueScrollRequestId();
            store.dispatch(manualIncentivesActionCreators.OpenManualIncentiveForm(navOpenedFromIncentives));
            break;
        }
        case navigationActionTypes.DASH_START_CREDIT_BUREAU: {
            store.dispatch(push(creditBureauConfiguration.defaultRoute));
            break;
        }
        case navigationActionTypes.DASH_START_CREDIT_DECISION: {
            store.dispatch(push(creditDecisionConfiguration.defaultRoute));
            break;
        }
        case navigationActionTypes.DASH_START_VEHICLE_PROTECTION: {
            store.dispatch(push(vehicleProtectionConfiguration.defaultRoute));
            break;
        }
        case navigationActionTypes.DASH_START_ACCESSORIES_CATALOG: {
            store.dispatch(push(accessoriesCatalogConfiguration.defaultRoute));
            break;
        }
        case navigationActionTypes.DASH_START_ACCESSORIES_ACTIVITY: {
            store.dispatch(
                accessoriesActionCreators.openForm({
                    scrollIntoView: true
                })
            );
            break;
        }

        default: {
            break;
        }
    }
}

export const navigationMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);

    if (action && action.type) {
        processAction(action, store);
    }
};
