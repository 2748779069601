// There's a circular ref involving a test file that needs to be resolved before this rule can be enabled:
// See https://ghe.coxautoinc.com/DigitalRetailing/dr-dash/issues/2613 for description of this issue.
/* eslint-disable @nx/enforce-module-boundaries */

// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { FSA } from '@makemydeal/dr-platform-shared';
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { UniqueScrollRequestId } from '@makemydeal/dr-dash-ui-utils';

// consts/enums
import {
    INCLUDE_TRADE_CHANGED,
    MANUAL_ENTRY_CANCEL_TRADE_IN,
    MANUAL_ENTRY_SAVE_TRADE_IN,
    TRADE_IN_UPDATE_PAYOFF_DUE_DATE,
    TRADE_IN_UPDATE_LIENHOLDER,
    TRADE_IN_UPDATE_PER_DIEM,
    TRADE_IN_ODOMETER,
    TRADE_IN_UPDATE_VIN,
    TRADE_IN_VIN_REQUEST,
    TRADE_IN_VIN_SUCCESS,
    TRADE_IN_VIN_FAILURE,
    TRADE_IN_UPDATE_YEAR,
    TRADE_IN_UPDATE_MAKE,
    TRADE_IN_UPDATE_MODEL,
    TRADE_DETAILS_MANUAL_ENTRY_CANCEL,
    TRADE_DETAILS_MANUAL_ENTRY_SAVE,
    TRADE_IN_UPDATE_BODY_STYLE,
    TRADE_IN_UPDATE_DOORS,
    TRADE_IN_UPDATE_INTERIOR_COLOR,
    TRADE_IN_UPDATE_IS_BUYER_REGISTERED_OWNER,
    TRADE_IN_UPDATE_IS_SELLING_VEHICLE_REGISTERED_IN_SAME_STATE,
    TRADE_IN_UPDATE_TRIM,
    TRADE_IN_UPDATE_VEHICLE_COLOR,
    TRADE_IN_UPDATE_CONDITION,
    TRADE_IN_UPDATE_ENGINE,
    TRADE_IN_UPDATE_TRANSMISSION,
    TRADE_IN_UPDATE_DRIVE_LINE,
    TRADE_IN_UPDATE_OWNERSHIP_TYPE,
    TRADE_IN_TOGGLE_MANUAL_FORM,
    FetchVinDecodeResponse,
    TRADE_IN_MULTIPLE_VIN_SELECTED,
    TRADE_IN_TOGGLE_SAVING,
    TRADE_IN_UPDATE_LIENHOLDER_FULL,
    TRADE_IN_UPDATE_MAKE_ID,
    TRADE_IN_UPDATE_MODEL_ID
} from '../actionTypes/tradeActionTypes';
import { ITradeIn } from '@makemydeal/dr-platform-types';

export type TradeInCMDActions = SelectedOfferSetAction;

export type UpdateTradeInPayoffDueDate = FSA<typeof TRADE_IN_UPDATE_PAYOFF_DUE_DATE, { goodThroughDate: string }>;

export type SelectedOfferSetAction = FSA<typeof TRADE_IN_UPDATE_LIENHOLDER, { lienHolder: string }>;

export type UpdateTradeInPerDiem = FSA<typeof TRADE_IN_UPDATE_PER_DIEM, { perDiemInterest: number }>;

export type UpdateTradeInOdometer = FSA<typeof TRADE_IN_ODOMETER, { odometer: string }>;

export type UpdateTradeInVIN = FSA<typeof TRADE_IN_UPDATE_VIN, { vin: string }>;
export type UpdateTradeInYear = FSA<typeof TRADE_IN_UPDATE_YEAR, { year: string }>;
export type UpdateTradeInMake = FSA<typeof TRADE_IN_UPDATE_MAKE, { name: string; id: string }>;
export type UpdateTradeInMakeId = FSA<typeof TRADE_IN_UPDATE_MAKE_ID, number>;
export type UpdateTradeInModelId = FSA<typeof TRADE_IN_UPDATE_MODEL_ID, number>;
export type UpdateTradeInModel = FSA<typeof TRADE_IN_UPDATE_MODEL, { name: string; id: string }>;
export type UpdateTradeInTrim = FSA<typeof TRADE_IN_UPDATE_TRIM, { trim: string; trimId: string }>;
export type UpdateTradeInExteriorColor = FSA<typeof TRADE_IN_UPDATE_VEHICLE_COLOR, { color: string }>;
export type UpdateTradeInCondition = FSA<typeof TRADE_IN_UPDATE_CONDITION, { condition: string }>;
export type UpdateTradeInBodyStyle = FSA<typeof TRADE_IN_UPDATE_BODY_STYLE, { bodyStyle: string }>;
export type UpdateTradeInDoors = FSA<typeof TRADE_IN_UPDATE_DOORS, { doors: string }>;
export type UpdateTradeInInteriorColor = FSA<typeof TRADE_IN_UPDATE_INTERIOR_COLOR, { interiorColor: string }>;
export type UpdateTradeInIsBuyerRegisteredOwner = FSA<
    typeof TRADE_IN_UPDATE_IS_BUYER_REGISTERED_OWNER,
    { isBuyerRegisteredOwner: boolean }
>;
export type UpdateTradeInIsSellingVehicleRegisteredInSameState = FSA<
    typeof TRADE_IN_UPDATE_IS_SELLING_VEHICLE_REGISTERED_IN_SAME_STATE,
    { isSellingVehicleRegisteredInSameState: boolean }
>;
export type UpdateTradeInEngine = FSA<typeof TRADE_IN_UPDATE_ENGINE, { engine: string }>;
export type UpdateTradeInTransmission = FSA<typeof TRADE_IN_UPDATE_TRANSMISSION, { transmission: string }>;
export type UpdateTradeInDriveLine = FSA<typeof TRADE_IN_UPDATE_DRIVE_LINE, { driveLine: string }>;
export type UpdateTradeInOwnershipType = FSA<typeof TRADE_IN_UPDATE_OWNERSHIP_TYPE, { ownershipType: string }>;
export type UpdateMultipleVinSelected = FSA<typeof TRADE_IN_MULTIPLE_VIN_SELECTED, FetchVinDecodeResponse[0]>;

export type UpdateTradeInLienholderFull = FSA<typeof TRADE_IN_UPDATE_LIENHOLDER_FULL, LienholderUpdate>;

interface ITradeAction {
    type: string;
    payload: any;
    meta?: {
        middleware: {
            payment: boolean;
        };
    };
}

type LienholderUpdate = Pick<
    ITradeIn,
    'lienHolder' | 'lienHolderAccountNumber' | 'lienHolderAddress' | 'perDiemInterest' | 'goodThroughDate'
>;

/**
 * Sets the lienholder to be the values passed
 * @param lienholder Value to be set
 * @returns action
 */
export const updateTradeInLienholder = (lienHolder: string): SelectedOfferSetAction => {
    return {
        type: TRADE_IN_UPDATE_LIENHOLDER,
        payload: {
            lienHolder
        }
    };
};

export const updateTradeInLienholderFull = (lienHolder: LienholderUpdate): UpdateTradeInLienholderFull => {
    return {
        type: TRADE_IN_UPDATE_LIENHOLDER_FULL,
        payload: {
            ...lienHolder
        }
    };
};

export const updateTradeInPayoffDueDate = (goodThroughDate: string): UpdateTradeInPayoffDueDate => {
    return {
        type: TRADE_IN_UPDATE_PAYOFF_DUE_DATE,
        payload: {
            goodThroughDate
        }
    };
};

export const updateTradeInPerDiem = (perDiemInterest: number): UpdateTradeInPerDiem => {
    return {
        type: TRADE_IN_UPDATE_PER_DIEM,
        payload: {
            perDiemInterest
        }
    };
};

export const updateTradeInOdometer = (odometer: string): UpdateTradeInOdometer => {
    return {
        type: TRADE_IN_ODOMETER,
        payload: {
            odometer
        }
    };
};

export const updateTradeInVin = (vin: string): UpdateTradeInVIN => {
    return {
        type: TRADE_IN_UPDATE_VIN,
        payload: {
            vin
        }
    };
};

export const updateTradeInYear = (year: string = ''): UpdateTradeInYear => {
    return {
        type: TRADE_IN_UPDATE_YEAR,
        payload: {
            year
        }
    };
};

export const updateTradeInMake = ({ name = '', id = '' }: { name: string; id: string }): UpdateTradeInMake => {
    return {
        type: TRADE_IN_UPDATE_MAKE,
        payload: {
            name,
            id
        }
    };
};

export const updateTradeInMakeId = (id: number): UpdateTradeInMakeId => {
    return {
        type: TRADE_IN_UPDATE_MAKE_ID,
        payload: id
    };
};

export const updateTradeInModelId = (id: number): UpdateTradeInModelId => {
    return {
        type: TRADE_IN_UPDATE_MODEL_ID,
        payload: id
    };
};

export const updateTradeInModel = ({ name = '', id = '' }: { name: string; id: string }): UpdateTradeInModel => {
    return {
        type: TRADE_IN_UPDATE_MODEL,
        payload: {
            name,
            id
        }
    };
};

export const updateTradeInTrim = ({ name = '', id = '' }: { name: string; id: string }): UpdateTradeInTrim => {
    return {
        type: TRADE_IN_UPDATE_TRIM,
        payload: {
            trim: name,
            trimId: id.toString()
        }
    };
};

export const updateTradeInExteriorColor = ({ exteriorColor = '' }: { exteriorColor: string }): UpdateTradeInExteriorColor => {
    return {
        type: TRADE_IN_UPDATE_VEHICLE_COLOR,
        payload: { color: exteriorColor }
    };
};

export const updateTradeInCondition = ({ condition = '' }: { condition: string }): UpdateTradeInCondition => {
    return {
        type: TRADE_IN_UPDATE_CONDITION,
        payload: { condition }
    };
};

export const updateTradeInDoors = (doors: string): UpdateTradeInDoors => {
    return {
        type: TRADE_IN_UPDATE_DOORS,
        payload: {
            doors
        }
    };
};

export const updateTradeInBodyStyle = (bodyStyle: string): UpdateTradeInBodyStyle => {
    return {
        type: TRADE_IN_UPDATE_BODY_STYLE,
        payload: {
            bodyStyle
        }
    };
};

export const updateTradeInEngine = (engine: string): UpdateTradeInEngine => {
    return {
        type: TRADE_IN_UPDATE_ENGINE,
        payload: {
            engine
        }
    };
};

export const updateTradeInTransmission = (transmission: string): UpdateTradeInTransmission => {
    return {
        type: TRADE_IN_UPDATE_TRANSMISSION,
        payload: {
            transmission
        }
    };
};

export const updateTradeInDriveLine = (driveLine: string): UpdateTradeInDriveLine => {
    return {
        type: TRADE_IN_UPDATE_DRIVE_LINE,
        payload: {
            driveLine
        }
    };
};

export const updateTradeInInteriorColor = (interiorColor: string): UpdateTradeInInteriorColor => {
    return {
        type: TRADE_IN_UPDATE_INTERIOR_COLOR,
        payload: {
            interiorColor
        }
    };
};

export const updateTradeInOwnershipType = (ownershipType: string): UpdateTradeInOwnershipType => {
    return {
        type: TRADE_IN_UPDATE_OWNERSHIP_TYPE,
        payload: {
            ownershipType
        }
    };
};

export const vinDecodeRSAA = (appPrefix: string, vin: string) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/trade');
    const endpoint = bffApiUrlResult.url + `/vin/decode/${vin}`;

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [TRADE_IN_VIN_REQUEST, TRADE_IN_VIN_SUCCESS, TRADE_IN_VIN_FAILURE]
        }
    };
};

export const tradeDetailsManualEntrySave = () => {
    return {
        type: TRADE_DETAILS_MANUAL_ENTRY_SAVE
    };
};

export const tradeDetailsManualEntrySaveTradeIn = (tradeIn: any) => {
    return {
        type: MANUAL_ENTRY_SAVE_TRADE_IN,
        payload: tradeIn
    };
};

export const tradeDetailsManualEntryCancel = () => {
    return {
        type: TRADE_DETAILS_MANUAL_ENTRY_CANCEL
    };
};

export const manualEntryCancelTradeIn = (rollbackTradeIn: any) => {
    return {
        type: MANUAL_ENTRY_CANCEL_TRADE_IN,
        payload: rollbackTradeIn
    };
};

export type ToggleManualTradeFormAction = FSA<typeof TRADE_IN_TOGGLE_MANUAL_FORM, UniqueScrollRequestId>;

export const toggleManualTradeForm = (toggleStateOrUniqueRequestId?: UniqueScrollRequestId): ToggleManualTradeFormAction => ({
    type: TRADE_IN_TOGGLE_MANUAL_FORM,
    payload: toggleStateOrUniqueRequestId
});

export const selectMultipleVinOption = (option: FetchVinDecodeResponse[0]): UpdateMultipleVinSelected => {
    return {
        type: TRADE_IN_MULTIPLE_VIN_SELECTED,
        payload: option
    };
};

export const toggleSavingTrade = (isSaving: boolean) => ({ type: TRADE_IN_TOGGLE_SAVING, payload: { isSaving } });

export const includeTradeChanged = (payload: boolean, makePaymentCall = true) => {
    const action: ITradeAction = {
        type: INCLUDE_TRADE_CHANGED,
        payload
    };
    if (makePaymentCall) {
        action.meta = {
            middleware: {
                payment: true
            }
        };
    }
    return action;
};

export const updateTradeInIsBuyerRegisteredOwner = (value: boolean): UpdateTradeInIsBuyerRegisteredOwner => {
    return {
        type: TRADE_IN_UPDATE_IS_BUYER_REGISTERED_OWNER,
        payload: {
            isBuyerRegisteredOwner: value
        }
    };
};

export const updateTradeInIsSellingVehicleRegisteredInSameState = (
    value: boolean
): UpdateTradeInIsSellingVehicleRegisteredInSameState => {
    return {
        type: TRADE_IN_UPDATE_IS_SELLING_VEHICLE_REGISTERED_IN_SAME_STATE,
        payload: {
            isSellingVehicleRegisteredInSameState: value
        }
    };
};
