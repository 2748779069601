// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

// selectors
import * as connectionSelectors from '../selectors/connectionSelectors';
import * as offerReduxSelectors from '../selectors/offerRedux';
import * as vehicleProtectionSelectors from '../selectors/vehicleProtectionSelectors';
import * as vehicleSelectors from '../selectors/vehicleSelectors';
import * as compositeSelectors from '../selectors/compositeSelectors';
import { getAccessoriesAndMenuItems } from '../shopperActivities/menu/menuSelectors';
import { getSelectedDealProducts } from '../selectors/vehicleProtectionSelectors';

// types
import { SaveOfferMenuProductsPayload } from '@makemydeal/dr-dash-bff-types';
import { LEASE, OfferType } from '@makemydeal/dr-platform-types';
import { DealProduct } from '@makemydeal/dr-shared-types';

export const createCleanDealProductFn =
    (offerType: OfferType) =>
    (dealProduct: DealProduct): DealProduct => {
        const isLeaseDeal = offerType === LEASE;

        return {
            ...dealProduct,
            isProductPriceCapitalized: isLeaseDeal ? dealProduct.isProductPriceCapitalized : undefined,
            productResidualType: isLeaseDeal ? dealProduct.productResidualType : undefined,
            productResidualValue: isLeaseDeal ? dealProduct.productResidualValue : undefined
        };
    };

export const createMenuPayload = (state: StateTree): SaveOfferMenuProductsPayload => {
    const useVehicleProtectionActivity = vehicleProtectionSelectors.isVehicleProtectionActivityEnabled(state);

    const connectionId = connectionSelectors.getConnectionId(state);
    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    const vin = vehicleSelectors.getVehicleVin(state);
    const menuProducts = getAccessoriesAndMenuItems(state);
    const menuPayload = {
        connectionId,
        vehicle: {
            vin
        },
        offerType,
        menuProducts
    };

    if (useVehicleProtectionActivity) {
        const toOfferTypeSpecificDealProduct = createCleanDealProductFn(offerType);

        return {
            ...menuPayload,
            menuProducts: getSelectedDealProducts(state).map(toOfferTypeSpecificDealProduct),
            surchargesOverrides: vehicleProtectionSelectors.getVppSurchargesOverrides(state),
            unselectedProducts: vehicleProtectionSelectors.getUnselectedDealProducts(state).map(toOfferTypeSpecificDealProduct)
        };
    }

    return menuPayload;
};
