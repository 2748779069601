import { Button } from '@interstate/components/Button';
import { TrashIcon } from '@interstate/components/Icons';
import { ArrowUturnLeftIcon as IconHistory } from '@interstate/components/Icons/ArrowUturnLeftIcon';
import { Tooltip } from '@interstate/components/Tooltip';
import { FlexCol } from '@makemydeal/dr-dash-components';
import { manualIncentivesActionCreators, manualIncentivesSelectors } from '@makemydeal/dr-dash-store';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const DeleteField: FC<{ index: number }> = ({ index }) => {
    const dispatch = useDispatch();
    const deleted = useSelector(manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, 'deleted'));

    const handleIncentiveDeleteOrUndo = () => {
        dispatch(manualIncentivesActionCreators.removeOneManualIncentive(index));
    };

    return (
        <FlexCol justifyContent="start" alignItems="start" gap="4px">
            {/* // matching MUI height */}
            <label className="hidden-label">gap</label>
            <Button
                htmlId={`manual-rebate-trash-icon-${index}`}
                buttonStyle="secondary"
                size="medium"
                sx={{ minWidth: '32px' }} // because interstate sucks
                onClick={handleIncentiveDeleteOrUndo}
            >
                {deleted && (
                    <Tooltip
                        htmlId="tooltipLink"
                        data-testid="tooltip-deleted"
                        toolTipContent="Item was deleted. You can still undo this before updating the Offer."
                        position="bottom"
                        size="small"
                    >
                        <IconHistory />
                    </Tooltip>
                )}
                {!deleted && <TrashIcon data-testid={`manual-rebate-delete-${index}`} className="manual-rebate-delete-icon" />}
            </Button>
        </FlexCol>
    );
};
