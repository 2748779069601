// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// externals
import { Middleware, createStore } from 'redux';
import { connectRouter } from 'connected-react-router';

// utils
import historyInstance from './historyInstance';
import { combineReducersDynamically } from './configureStoreUtils';
import { reducerRegistry } from './reducerRegistry';
import * as configureStoreCommon from './configureStoreCommon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreloadedState {}

export function configureStore(
    preloadedState: IPreloadedState,

    initialMiddleware: Middleware<any, any, any>[],

    initialReducers: any
) {
    reducerRegistry.register('router', connectRouter(historyInstance));
    reducerRegistry.registerMultiple(initialReducers);
    const allReducers = combineReducersDynamically(preloadedState, reducerRegistry.getReducers());

    const store = createStore(allReducers, preloadedState);
    configureStoreCommon.setStore(store);
    configureStoreCommon.addRemainingMiddleware(initialMiddleware);
    reducerRegistry.setChangeListener((reducers) => {
        store.replaceReducer(combineReducersDynamically(preloadedState, reducers));
    });

    return store;
}
