import React from 'react';
import { useSelector } from 'react-redux';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';

import { MAX_RESIDUALIZED_MSRP } from '../../constants';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { NumericInput } from '@interstate/components/NumericInput';

const MaxResidualizedMsrpROField = () => {
    const maxResidualizedMsrp = useSelector(offerReduxSelectors.getMaxResidualizedMsrp);

    // istanbul ignore next
    return (
        <NumericInput
            label={MAX_RESIDUALIZED_MSRP}
            name={kebabCase(MAX_RESIDUALIZED_MSRP)}
            placeholder="0.00"
            disabled={true}
            value={formatDollarsAndCents(maxResidualizedMsrp)}
            onChange={() => {}}
            data-testid={kebabCase(MAX_RESIDUALIZED_MSRP)}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas={true}
        />
    );
};

export default MaxResidualizedMsrpROField;
