// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, DealState } from '@makemydeal/dr-dash-types';
import { offerSelectors } from '@makemydeal/dr-dash-store';

import * as constants from '../../constants';
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

const CashDown = ({ deal, hasDelta }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const state = useDealRootState(deal);

    const downPayment = offerSelectors.getDownPayment(state);

    return <DealSummaryItem label={constants.CASH_DOWN} value={formatDollarsAndCents(downPayment)} hasDelta={hasDelta} />;
};

export default CashDown;
