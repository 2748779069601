/**
 * This file exports selectors statically for other libs to consume without worrying about shopper activity configuration
 * Under the hood this is all that the menu activity does with its `init` process
 *
 * It's also safe to keep separate instances of the class because it's just a plain class
 */

import { Selectors } from '@makemydeal/dr-activities-menu';
import { additionalSelectors } from './additionalSelectors';

const menuSelectors: Selectors = new Selectors('vehicleProtection', additionalSelectors);

export const getMenuSelectors = (): Selectors => menuSelectors;

export const {
    getListTotalPrice,
    getVehicleProtectionList,
    getVehicleProductsMonthlyPaymentPrice: getMonthlyPayment,
    isCompleted,
    getSelectedProtectionList,
    getAccessoriesAndMenuItems
} = menuSelectors;
