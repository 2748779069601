import { CardContent, CardHeader, Card as InterstateCard } from '@interstate-104/components/Card';
import { formatDollars } from '@makemydeal/dr-common-utils';
import { LoadingSectionParent } from '@makemydeal/dr-dash-components';
import { menuShopperActivity, navigationActionTypes, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CASH } from '@makemydeal/dr-platform-types';
import { useDispatch, useSelector } from 'react-redux';
import { VpCardEditLink } from './VehicleProtectionSummary.styles';

/**
 * @deprecated
 * This is the Desking card component used for the old Menu integration with DealerTrack.
 * It should be removed around Q4 2024 when the new VPP integration goes GA.
 * For the new VPP integration, please look VehicleProtectionPreview component.
 */
export const VehicleProtectionSummary = () => {
    const dispatch = useDispatch();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isFinanceOrLease = !(currentOfferType === CASH);

    // TODO: the correct selectors will need to be used once the owning team for VPP hooks up this component with live menu data
    const totalCost = useSelector(menuShopperActivity.menuSelectors.getListTotalPrice);
    const monthlyCost = useSelector(menuShopperActivity.menuSelectors.getMonthlyPayment);

    return (
        <LoadingSectionParent>
            <InterstateCard
                data-testid="vpp-summary-card"
                header={
                    <CardHeader
                        title="Vehicle Protection"
                        action={
                            <VpCardEditLink
                                data-testid="vpp-summary-edit-link"
                                onClick={() => {
                                    dispatch({ type: navigationActionTypes.DASH_START_MENU_ACTIVITY });
                                }}
                            >
                                View/Edit
                            </VpCardEditLink>
                        }
                    />
                }
                content={
                    <CardContent>
                        <div>
                            <span>{formatDollars(totalCost)}</span> Total Cost
                        </div>
                        {isFinanceOrLease && (
                            <div>
                                <span>{formatDollars(monthlyCost)}</span> Monthly Payment
                            </div>
                        )}
                    </CardContent>
                }
            />
        </LoadingSectionParent>
    );
};

export default VehicleProtectionSummary;
